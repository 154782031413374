import React from 'react'
// import historyAccessor from "history-accessor"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { getAuthUserId, getAuthUserEmail, getAuth } from 'store/auth/authReducer';
import { connect } from 'react-redux'
import { AuthInfoModel } from 'store/auth/auth-state'
import HubspotChatService from 'api/hubspot-form/hubspot-chat.service';

class LiveChatController {
    private static countHide = 0;
    private static classNameSelector = '#hubspot-messages-iframe-container'

    public static hide = (): Promise<any> => {
        const hideWhenAppear = (resolve: (value?: any) => void) => {
            let count = 0
            let x = setInterval(() => {
                let chatComponent: HTMLElement = document.querySelector(LiveChatController.classNameSelector)
                count = count + 1
                if (chatComponent !== null) {
                    chatComponent.style.transform = 'translate(100%, 0%)';
                    clearInterval(x)
                    resolve()
                } else if (count > 30) { // If there is no component in progect during (20ms * 500ms = 10) seconds, break and resolve
                    clearInterval(x)
                    resolve()
                }
                LiveChatController.countHide++;
            }, 500)
        }
        return new Promise(resolve => hideWhenAppear(resolve))
    }
    public static show = () => {
        let chatComponent: HTMLElement = document.querySelector(LiveChatController.classNameSelector)

        if (LiveChatController.countHide > 0) {
            LiveChatController.countHide = 0;
        }

        if (chatComponent !== null && LiveChatController.countHide === 0) {
            chatComponent.style.transform = 'translate(0%, 0%)';
        }
    }

    public static deleteFromDOM = () => {
        document.querySelector(LiveChatController.classNameSelector).remove()
    }

    public static isVisible = () => {
        const chatComponent = document.querySelector(LiveChatController.classNameSelector)
        if (!chatComponent) return false

        const transform = window.getComputedStyle(document.querySelector(LiveChatController.classNameSelector)).transform
        var matrix = new WebKitCSSMatrix(transform);
        const value = matrix.m41

        if (value > 0) {
            return false
        } else {
            return true
        }
    }

    public static openConversation = () => {
        const status = (window as any).HubSpotConversations.widget.status()
        if (status.loaded && !status.pending) {
            (window as any).HubSpotConversations.widget.open();
        }

    }
}

type HsAuth = {
    email: string,
    token: string,
    createdAt: Date
}

interface StateProps {
    userId: number;
    userEmail: string;
    auth: AuthInfoModel;
}

const LiveChatCoordinatorComp = ({ userEmail, auth }: StateProps) => {
    const location = useLocation();
    const setVisibility = () => {

        // If chat is not declared yet
        if (!(window as any).HubSpotConversations) return

        // Handle routing rules
        const isAdminRoute = location.pathname.startsWith('/admin');
        if (isAdminRoute) {
            (window as any).HubSpotConversations.widget.remove();
        }
    }

    const refreshWidget = async (userEmail: string, firstName: string, lastName: string) => {
        try {
            const token = await HubspotChatService.getChatToken(firstName, lastName, userEmail)
            if (token != null) {
                const hsAuth: HsAuth = { email: userEmail, token: token, createdAt: new Date() };
                localStorage.setItem('hsAuth', JSON.stringify(hsAuth));
                (window as any).hsConversationsSettings = { identificationEmail: userEmail, identificationToken: token };
                (window as any).HubSpotConversations.resetAndReloadWidget();
            }
        } catch (error) {
            return;
        }
    }

    const setAuth = async () => {
        const hsAuth = localStorage.getItem('hsAuth');
        if (userEmail != null && userEmail != "" && hsAuth == null) {
            await refreshWidget(userEmail, auth.userFirstName, auth.userLastName);
        }
        if (userEmail != null && userEmail != "" && hsAuth != null) {
            const parsedHsAuth: HsAuth = JSON.parse(hsAuth);

            parsedHsAuth.createdAt = new Date(parsedHsAuth.createdAt);
            const now = new Date();
            const diffInMilliseconds = now.getTime() - parsedHsAuth.createdAt.getTime();
            const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

            if (userEmail != null && userEmail != "" && diffInHours > 11) {
                await refreshWidget(userEmail, auth.userFirstName, auth.userLastName);
            }
            if (userEmail != null && userEmail != "" && parsedHsAuth.email != userEmail) {
                await refreshWidget(userEmail, auth.userFirstName, auth.userLastName);
            }
        }
    }

    useEffect(() => {
        setVisibility();
        setAuth();
    }, [location.pathname, (window as any).HubSpotConversations])

    return (<div></div>)
}

const mapStateToProps = (state) => ({
    userId: getAuthUserId(state),
    userEmail: getAuthUserEmail(state),
    auth: getAuth(state)
})
const mapDispatchToProps = {};

export const LiveChatCoordinator = connect(mapStateToProps, mapDispatchToProps)(LiveChatCoordinatorComp)

export default LiveChatController