import { StateController } from 'utils/action-declaration';
import MiscEventService from 'api/virtual-summit/misc-event.service'
import { AppState } from 'root.reducer';
import { EventAgendaDayModel } from 'api/virtual-summit/models/event-agenda';
import { ISTANBUL_SUMMIT_NOVEMBER_2024_ID } from 'app/events/components/authorized-event-page/events-map/constants';

class AgencyEventState {
    isLoading: boolean;
    isUserRegistered: boolean;
    isPendingRegistration: boolean;
    agendaDays: EventAgendaDayModel[];
}

const defaultState: AgencyEventState = {
    isLoading: false,
    isUserRegistered: false,
    isPendingRegistration: false,
    agendaDays: [],
}

const stateController = new StateController<AgencyEventState>(
    'AGENCY_EVENT',
    defaultState
)

class Actions {
    public static getRegistrationState() {
        return async dispatch => {

            dispatch(stateController.setState({ isLoading: true }))

            try {
                const data = await MiscEventService.loadAgencyRegistrationState()
                dispatch(stateController.setState({ isUserRegistered: data.isUserRegistered, isPendingRegistration: data.pendingAgencyRequest }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static registerAgency() {
        return (dispatch, getState: () => AppState) => {
            const agencyId = getState().auth.agencyId
            // await MiscEventService
        }
    }

    public static getEventAgenda(eventId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await MiscEventService.loadEventAgenda(eventId)
                dispatch(stateController.setState({
                     agendaDays: data
                }))
            } catch (e) {
                console.error(e)
            }
        }
    }
}

class Selectors {
    public static isBannerVisibleForSearch = (state: AppState) => {
        const { eventId } = state.auth;
        let subState = state.events.agencyEvent;
        return !subState.isLoading && !subState.isUserRegistered && !subState.isPendingRegistration && eventId === ISTANBUL_SUMMIT_NOVEMBER_2024_ID;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyEventState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};