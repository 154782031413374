/* PLAYER SEARCH */
export const PLAYERSEARCH_PLAYER_SELECTED = 'app/playerSearch/PLAYER_SELECTED';
export const PLAYERSEARCH_FILTER_CHANGED = 'app/playerSearch/FILTER_CHANGED';
export const PLAYERSEARCH_V2_FILTER_CHANGED = 'app/playerSearchV2/FILTER_CHANGED';
export const REVERT_FILTER_CHANGED = 'app/playerSearch/REVERT_FILTER_CHANGED';
export const PLAYERSEARCH_FILTER_STRUCTURE_FETCH = asyncActionType(
    'app/playerSearch/FILTER_STRUCTURE_FETCH'
);
export const PLAYERSEARCH_FILTER_COLLAPSE_TOGGLE = 'app/playerSearch/FILTER_COLLAPSE_TOGGLE';
export const PLAYERSEARCH_FILTER_CLEAR = 'app/mySquad/PLAYERSEARCH_FILTER_CLEAR';
export const PLAYERSEARCH_FILTER_CLEAR_ALL = 'app/mySquad/PLAYERSEARCH_FILTER_CLEAR_ALL';
export const PLAYERSEARCH_SET_TAB_KEY = 'app/playerSearch/SET_TAB_KEY ';
export const PLAYERSEARCH_SET_SIMILARITY_PLAYER = 'app/playerSearch/SET_SIMILARITY_PLAYER';
export const PLAYERSEARCH_LOAD_SIMILAR_PLAYERS = asyncActionType(
    'app/playerSearch/LOAD_SIMILAR_PLAYERS'
);
export const PLAYERSEARCH_SET_FILTER_OPEN = 'app/playerSearch/SET_FILTER_OPEN';
export const PLAYERSEARCH_SET_SORT = 'app/playerSearch/PLAYERSEARCH_SET_SORT';

export const PLAYERSEARCH_SCREEN_DISPOSE = 'app/playerSearch/SCREEN_DISPOSE';

/* SHORT LIST */
export const SHORTLIST_PLAYER_SELECTED = 'app/shortList/PLAYER_SELECTED';
export const SHORTLIST_FILTER_CHANGED = 'app/shortList/FILTER_CHANGED';
export const SHORTLIST_FILTER_DATA_FETCH = asyncActionType(
    'app/shortList/SHORTLIST_FILTER_DATA_FETCH'
);
export const SHORTLIST_FILTER_COLLAPSE_TOGGLE = 'app/shortList/SHORTLIST_FILTER_COLLAPSE_TOGGLE';
export const SHORTLIST_FILTER_CLEAR = 'app/shortList/FILTER_CLEAR';

export const SHORTLIST_FETCH = asyncActionType('app/shortList/SHORTLIST_FETCH');
export const SHORT_LIST_SCREEN_DISPOSE = 'app/shortList/SHORT_LIST_SCREEN_DISPOSE';

/* EXPIRED ADS */
export const EXPIRED_ADS_FETCH = asyncActionType('app/expiredAds/FETCH');
export const EXPIRED_ADS_UPDATE = asyncActionType('app/expiredAds/UPDATE');
export const EXPIRED_ADS_REMOVE = asyncActionType('app/expiredAds/REMOVE');

/* PROFILE */
export const PLAYER_PROFILE_FETCH = asyncActionType('app/playerProfile/FETCH');

/* CROSSCUTTING CONCERNS */
export const PAGINATION_TURN_PAGE = 'app/crosscutting/pagination/TURN_PAGE';
export const PAGINATION_RESET = 'app/crosscutting/pagination/RESET';

/* MODALS */
export const MODAL_OPEN = 'app/modals/OPEN';
export const MODAL_REFRESH_CONTENT = 'app/modals/REFRESH_CONTENT';
export const MODAL_CLOSE = 'app/modals/CLOSE';

/* PLAYERS */
export const PLAYERS_FETCH = asyncActionType('app/players/FETCH', true);
export const PLAYERS_DECLARE_INTEREST = 'app/players/DECLARE_INTEREST';
export const PLAYERS_DECLARE_INTEREST_SAVE = asyncActionType('app/players/DECLARE_INTEREST_SAVE');

export const PLAYERS_INTEREST = 'app/players/PLAYER_INTEREST';
export const PLAYERS_INTEREST_SAVE = asyncActionType('app/players/INTEREST_SAVE');
export const SET_PLAYERS = 'app/players/SET_PLAYERS';

/* PERFORMANCE */
export const PERFORMANCE_FETCH = asyncActionType('app/performance/PERFORMANCE_FETCH');
export const SELECTED_SEASON = 'SELECTED_SEASON';

/* PERFORMANCE */
export const FULL_STAT_FETCH = asyncActionType('app/fullstat/FULL_STAT_FETCH');

/* COMPARISON */
export const PLAYERS_COMPARISON_FETCH = asyncActionType('app/comparison/PLAYERS_COMPARISON_FETCH');
export const PLAYERS_COMPARISON_CLEAN = 'PLAYERS_COMPARISON_CLEAN';
export const SELECTED_PLAYERS_COMPARISON = 'SELECTED_PLAYERS_COMPARISON';

/* MESSAGES */
export const MESSAGES_CHANNELS_FETCH = asyncActionType('MESSAGES_CHANNELS_FETCH');

export const CHECK_NEW_MESSAGE_LIMIT = asyncActionType('CHECK_NEW_MESSAGE_LIMIT');
export const MESSAGES_CHANNELMESSAGES_FETCH = asyncActionType('MESSAGES_CHANNELMESSAGES_FETCH');
export const MESSAGES_MARK_CHANNEL_READ = asyncActionType('MESSAGES_MARK_CHANNEL_READ');
export const MESSAGES_SUGGESTED_SQUADS_FETCH = asyncActionType('MESSAGES_SUGGESTED_SQUADS_FETCH');
export const MESSAGES_FETCH = 'MESSAGES_FETCH';
export const MESSAGES_SEND = asyncActionType('MESSAGES_SEND');
export const MESSAGES_SEND_NEW = asyncActionType('MESSAGES_SEND_NEW');
export const MESSAGES_APPEND_MESSAGE_TO_CHANNEL = 'MESSAGES_APPEND_MESSAGE_TO_CHANNEL';
export const CREATE_NEW_MESSAGE_IS_LIMITED = 'CREATE_NEW_MESSAGE_IS_LIMITED';
export const CREATE_NEW_MESSAGE_IS_LIMITED_POPUP = 'CREATE_NEW_MESSAGE_IS_LIMITED_POPUP';

export const MESSAGES_CLEAR_ACTIVE_CHANNEL = 'MESSAGES_CLEAR_ACTIVE_CHANNEL';
export const MESSAGES_CHANNEL_DELETE = asyncActionType('MESSAGES_CHANNEL_DELETE');
export const MESSAGES_SEND_PUSH_NOTIFICATION_USER = asyncActionType(
    'MESSAGES_SEND_PUSH_NOTIFICATION_USER'
);
export const MESSAGES_IS_SENDING = 'MESSAGES_IS_SENDING';
export const MESSAGE_SET_STATE_PROP = 'MESSAGE_SET_STATE_PROP';
export const MESSAGES_MARK_MESSAGES_AS_READ = 'MESSAGES_MARK_MESSAGES_AS_READ';
export const MESSAGES_MARK_CHANNEL_AS_READONLY = 'MESSAGES_MARK_CHANNEL_AS_READONLY';
export const MESSAGES_DISPOSE = 'MESSAGES_DISPOSE';

/* USER ACTIVITY */
export const USER_ACTIVITY_INSERT = asyncActionType('app/useractivity/USER_ACTIVITY_INSERT');

/* POSITIONS */
export const POSITIONS_FETCH = asyncActionType('app/positions/POSITIONS_FETCH');

export const INVITE_TO_MATCH_ID = 'INVITE_TO_MATCH_ID';

/* FRIENDLY MATCHES */

export const MY_FRIENDLY_MATCHES_FILTER_CHANGE = 'MY_FRIENDLY_MATCHES_FILTER_CHANGE';
export const MY_FRIENDLY_MATCHES_DISPOSE = 'MY_FRIENDLY_MATCHES_DISPOSE';

export const MY_FRIENDLY_MATCHES_FETCH = asyncActionType('app/myFriendlyMatches/FETCH');
export const MY_FRIENDLY_MATCHES_UPDATE = asyncActionType('app/myFriendlyMatches/UPDATE');
export const MY_FRIENDLY_MATCHES_REMOVE = asyncActionType('app/myFriendlyMatches/REMOVE');
export const MY_FRIENDLY_REFRESH_ALL = asyncActionType('app/refreshAllMyFriendlyMatches/SAVE');
export const INVITE_SQUADS_TO_FRIENDLY_MATCH = asyncActionType(
    'app/friendlyMatchesInvitations/SAVE'
);
export const GET_SQUADS_FOR_INVITATION_ON_FRIENDLY_MATCH = asyncActionType(
    'app/friendlyMatchInvitationsGetSquads/FETCH'
);
export const MY_FRIENDLY_MATCHES_AREAS_FETCH = asyncActionType('app/myFriendlyMatchesAreas/FETCH');
export const GET_AREA_BY_SQUAD_ID = asyncActionType('app/friendlyMatchesMyArea/FETCH');

export const FRIENDLY_MATCH_LOAD_AVAILABILITY = asyncActionType(
    'app/friendlyMatchesMyArea/LOAD_AVAILABILITY'
);

export const FRIENDLY_MATCH_REMOVE = asyncActionType('app/myFriendlyMatches/REMOVE');
export const FRIENDLY_MATCH_UPDATE = asyncActionType('app/myFriendlyMatches/UPDATE');
export const FRIENDLY_MATCH_SAVE = asyncActionType('app/myFriendlyMatches/SAVE');
export const AVAILABLE_FRIENDLY_MATCHES_FETCH = asyncActionType(
    'app/availableFriendlyMatches/FETCH'
);
export const FRIENDLY_MATCHES_INVITATIONS_FETCH = asyncActionType(
    'app/friendlyMatchesInvitations/FETCH'
);
export const FRIENDLY_MATCH_DECLARE_INTEREST = asyncActionType(
    'app/availableFriendlyMatch/DECLAREINTEREST'
);
export const FRIENDLY_MATCH_DECLARE_INTEREST_BY_MESSAGE = asyncActionType(
    'app/availableFriendlyMatch/DECLAREINTERESTMESSAGE'
);
export const FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS = asyncActionType(
    'app/friendlyMatches/SETUNAVAILABILITY'
);
export const MATCHED_ADS_SUGGESTION_FETCH = asyncActionType('app/matchedAdsSuggestion/FETCH');

/* CLUB ADS */
export const CLUB_ADS_FETCH = asyncActionType('app/clubAds/FETCH');
export const CLUB_ADS_MY_PITCHES_FETCH = asyncActionType('app/clubAds/MY_PITCHES_FETCH');

export const CLUB_AD_PITCH_PLAYER = asyncActionType('app/clubAds/CLUB_AD_PITCH_PLAYER');
export const CLUB_AD_MAKE_AVAILABLE_REQUEST = asyncActionType(
    'app/clubAds/CLUB_AD_MAKE_AVAILABLE_REQUEST'
);
export const CLUB_ADS_SELECT_CLUB_AD = 'app/clubAds/SELECT_CLUB_AD';
export const CLUB_ADS_MAKE_AVAILABLE = 'app/clubAds/MAKE_AVAILABLE';
export const CLUB_ADS_CLEAR_SELECTED_CLUB_AD = 'app/clubAds/CLEAR_SELECTED_CLUB_AD';
export const CLUB_ADS_SET_FILTER = 'app/clubAds/FILTER_SET';
export const MY_PITCHES_SET_FILTER = 'app/clubAds/PITCHES_FILTER_SET';
export const CLUB_ADS_SET_SHORT_LIST = 'app/clubAds/SET_SHORT_LIST';
export const CLUB_ADS_SET_PLUS_PITCH_TAB = 'app/clubAds/CLUB_ADS_SET_PLUS_PITCH_TAB';

export const CLUB_ADS_SCREEN_DISPOSE = 'app/clubAds/SCREEN_DISPOSE';
export const CLUB_ADS_FILTER_CHANGED = 'app/clubAds/FILTER_CHANGED';
export const CLUB_ADS_FILTER_SECTION_CLEAR = 'app/clubAds/FILTER_SECTION_CLEAR';
export const CLUB_ADS_FILTER_KEYWORD_RESET = 'app/clubAds/FILTER_KEYWORD_RESET';
export const CLUB_ADS_FILTER_COLLAPSE_TOGGLE = 'app/clubAds/FILTER_COLLAPSE_TOGGLE';
export const CLUB_ADS_FILTER_CRITERIA_CLEAR = 'app/clubAds/FILTER_CRITERIA_CLEAR';
export const CLUB_ADS_FILTER_STRUCTURE_FETCH = asyncActionType(
    'app/clubAds/FILTER_STRUCTURE_FETCH'
);
export const CLUB_ADS_MY_PITCHES_FILTER_STRUCTURE_FETCH = asyncActionType(
    'app/clubAds/FILTER_MY_PITCHES_STRUCTURE_FETCH'
);
export const CLUB_ADS_CLEAR = 'app/clubAds/CLEAR';
export const CLUB_ADS_CLEAR_MY_PITCHES = 'app/clubAds/CLEAR_MY_PITCHES';

/* CLUB ADD SEARCH */

/* CLUB ADD SEARCH */
export const CLUBADD_SEARCH_FILTER_COLLAPSE_TOGGLE = 'app/clubSearch/FILTER_COLLAPSE_TOGGLE';
export const CLUBADD_SEARCH_FILTER_CLEAR = 'app/clubSearch/FILTER_CLEAR';

export const GET_AREA_PHONE_CODES = asyncActionType('app/phoneCodes/GET_AREA_PHONE_CODES');

/* NOTIFICATIONS */
export const NOTIFICATION_CREATE = 'app/notifications/CREATE';
export const NOTIFICATION_REMOVE = 'app/notifications/REMOVE';

/* HELPERS */
export function asyncActionType(type) {
    return {
        SUCCESS: `${type}--SUCCESS`,
        ERROR: `${type}--ERROR`,
        REQUEST: `${type}--REQUEST`,
        AUTH: `${type}--AUTH`,
        NOOP: `${type}--NOOP`, // empty action
    };
}

/* AUTH */
export const AUTH_LOGIN = asyncActionType('app/auth/LOGIN');
export const AUTH_REFRESH = asyncActionType('app/auth/REFRESH');
export const AUTH_META = asyncActionType('app/auth/META');
export const REFRESH_SET_AGENCY = asyncActionType('app/auth/REFRESH_SET_AGENCY');
export const AUTH_CHECK_EMAIL = asyncActionType('app/auth/CHECK_EMAIL');
export const AUTH_GENERATE_CHECK_EMAIL_TOKEN = asyncActionType(
    'app/auth/GENERATE_CHECK_EMAIL_TOKEN'
);

export const AUTH_CHANGE_STEP = 'app/auth/CHANGE_STEP';
export const AUTH_ISBLOCKED_CHANGE_STEP = 'app/auth/ISBLOCKED_CHANGE_STEP';
export const AUTH_ISBLOCKED_LOADING = 'app/auth/ISBLOCKED_LOADING';
export const AUTH_LOGIN_SCREEN_CLEANUP = 'app/auth/SCREEN_CLEANUP';

export const AUTH_RESTORE = 'app/auth/RESTORE';
export const AUTH_LOGOUT = asyncActionType('app/auth/LOGOUT');
export const UNBLOCK_ACCOUNT = asyncActionType('app/auth/UNBLOCK_ACCOUNT');
export const AUTH_LOGOUT_GLOBAL_DISPOSE = 'app/auth/LOGOUT_GLOBAL_DISPOSE';
export const AUTH_SET_LANGUAGE = 'app/auth/SET_LANGUAGE';
export const AUTH_SET_ERRORS = 'app/auth/SET_ERRORS';

export const AUTH_RESET_LOGIN_ERROR = 'app/auth/AUTH_RESET_LOGIN_ERROR';

export const AUTH_SET_UP_AGENCY_FEATURE = 'app/auth/AUTH_SET_UP_AGENCY_FEATURE';
export const AUTH_LOGIN_SCREEN_DISPOSE = 'app/auth/AUTH_LOGIN_SCREEN_DISPOSE';
export const AUTH_HIDE_TERMS_AND_CONDITIONS = 'app/auth/AUTH_HIDE_TERMS_AND_CONDITIONS';
export const SWITCH_ACCOUNT_PROCESSING = 'app/auth/SWITCH_ACCOUNT_PROCESSING';

/* ACCOUNT CONFIRMATION */

export const CHECK_CONFIRM_ACCOUNT_TOKEN = asyncActionType(
    'app/confirmAccount/CHECK_CONFIRM_ACCOUNT_TOKEN'
);

export const CONFIRM_ACCOUNT = asyncActionType('app/confirmAccount/CONFIRM_ACCOUNT');

export const USER_CLEAR_CONFIRM_REQUEST_SESSION =
    'app/confirmAccount/USER_CLEAR_CONFIRM_REQUEST_SESSION';
export const VALIDATE_CONFIRM_PASSWORD = 'app/confirmAccount/VALIDATE_CONFIRM_PASSWORD';
/* MISC */
export const ROUTE_CHANGED = 'app/route/CHANGED';

/* DEMO REQUEST */
export const DEMO_REQUEST_POSITIONS_GET = asyncActionType(
    'app/demorequest/DEMO_REQUEST_POSITIONS_GET'
);

export const DEMO_REQUEST_POST = asyncActionType('app/demorequest/DEMO_REQUEST_POST');

/* USER REGISTRATION */
export const USER_REGISTRATION_POSITIONS_GET = asyncActionType(
    'app/userregistration/USER_REGISTRATION_POSITIONS_GET'
);

export const EXTERNAL_USER_REGISTRATION_POST = asyncActionType(
    'app/externaluserregistration/USER_REGISTRATION_POST'
);

export const EXTERNAL_USER_REGISTRATION_POSITIONS_GET = asyncActionType(
    'app/externaluserregistration/EXTERNAL_USER_REGISTRATION_POSITIONS_GET'
);

/* USER RESET PASSWORD */
export const USER_RESET_PASSWORD_POST = asyncActionType(
    'app/usersetnewpassword/USER_RESET_PASSWORD_POST'
);
export const USER_RESET_PASSWORD_RESET_ERROR =
    'app/usersetnewpassword/USER_RESET_PASSWORD_RESET_ERROR';

export const USER_SET_NEW_PASSWORD_POST = asyncActionType(
    'app/usersetnewpassword/USER_SET_NEW_PASSWORD_POST'
);

export const USER_RETURN_TO_SET_PASSWORD = 'app/usersetnewpassword/USER_SET_NEW_PASSWORD_POST';

export const USER_CLEAR_SET_PASSWORD_REQUEST_SESSION =
    'app/usersetnewpassword/USER_CLEAR_SET_PASSWORD_REQUEST_SESSION';

export const SET_PASSWORD_ERROR = 'app/usersetnewpassword/SET_PASSWORD_ERROR';

/* USER UPDATE PASSWORD */
export const USER_UPDATE_PASSWORD_POST = asyncActionType(
    'app/userupdatenewpassword/USER_UPDATE_PASSWORD_POST'
);

export const USER_RETURN_TO_UPDATE_PASSWORD =
    'app/userupdatenewpassword/USER_SET_NEW_PASSWORD_POST';

export const USER_SET_UPDATE_PASSWORD_POST = asyncActionType(
    'app/userupdatenewpassword/USER_UPDATE_NEW_PASSWORD_POST'
);

/* EDIT PROFILE */
export const PROFILE_PERSONAL = asyncActionType('app/editprofile/PROFILE_PERSONAL');

export const PROFILE_PERSONAL_FETCHED = asyncActionType('app/editprofile/PROFILE_PERSONAL_FETCHED');

export const UPDATE_PROFILE = asyncActionType('app/editprofile/UPDATE_PROFILE');

export const UPDATE_PASSWORD = asyncActionType('app/editprofile/UPDATE_PASSWORD');

export const VALIDATE_PASSWORD = asyncActionType('app/editprofile/VALIDATE_PASSWORD');

export const CLEAR_PASSWORD_REQUEST = 'app/editprofile/CLEAR_PASSWORD_REQUEST';
export const VALIDATE_EDIT_PASSWORD = 'app/editprofile/VALIDATE_EDIT_PASSWORD';
export const GET_THE_PROFILE_USERS = asyncActionType('app/editprofile/GET_THE_PROFILE_USERS');

export const UPDATE_THE_PROFILE_USERS = asyncActionType('app/editprofile/UPDATE_THE_PROFILE_USERS');

export const DELETE_THE_PROFILE_USERS = asyncActionType('app/editprofile/DELETE_THE_PROFILE_USERS');

export const ADD_THE_PROFILE_USERS = asyncActionType('app/editprofile/ADD_THE_PROFILE_USERS');

/* COMPETITION_LIST */
export const COMPETITION_LIST_EDIT = 'app/competitionList/EDIT';
export const COMPETITION_LIST_CREATE = asyncActionType('app/competitionList/CREATE');
export const COMPETITION_LIST_UPDATE = asyncActionType('app/competitionList/UPDATE');
export const COMPETITION_LIST_DELETE = asyncActionType('app/competitionList/DELETE');

/* COMPETITIONS */
export const COMPETITION_FETCH = asyncActionType('app/competitions/FETCH');

/* EVENTS */
export const GET_EVENT_DATA = asyncActionType('app/event/GET_EVENT_DATA');
export const GET_USER_SUMMITS_DATA = asyncActionType('app/event/GET_USER_SUMMITS_DATA');
export const GET_DEAL_DAY_REGISTRATION_STATUS = asyncActionType(
    'app/event/GET_DEAL_DAY_REGISTRATION_STATUS'
);
export const SET_DEAL_DAY_IS_REGISTRED_STATUS = asyncActionType(
    'app/event/SET_DEAL_DAY_IS_REGISTRED_STATUS'
);
export const GET_EVENT_MENU_ICON_NUMBER = asyncActionType('app/event/GET_EVENT_MENU_ICON_NUMBER');

/* PLAYER ADS */
export const GENERAL_POSITIONS_WITHOUT_PLAYER_ADS_FETCH = asyncActionType(
    'app/playerAds/GENERAL_POSITIONS_WITHOUT_PLAYER_ADS'
);
export const PLAYER_ADS_FETCH = asyncActionType('app/playerAds/FETCH');
export const PLAYER_ADS_RECOMMENDATION_FETCH = asyncActionType(
    'app/playerAds/RECOMMENDATION_FETCH'
);

export const PLAYER_ADS_ACTIVITIES_COMPARISON_FETCH = asyncActionType(
    'app/playerAds/ACTIVITIES_COMPARISON'
);

export const PLAYER_ADS_SAVE = asyncActionType('app/playerAds/SAVE');
export const PLAYER_AD_UPDATE_EVENT = asyncActionType('app/playerAds/UPDATE_EVENT');

// FEEDBACK COLLECTOR

export const FETCH_SHOULD_COLLECT_FEEDBACK = asyncActionType(
    'app/feedback-collector/FETCH_SHOULD_COLLECT_FEEDBACK'
);
export const SEND_FEEDBACK = asyncActionType('app/feedback-collector/SEND_FEEDBACK');

// SUBSCRIBTION PROMOTION BANNER

export const LOAD_SUBSCRIPTION_PROMOTION_STATE = asyncActionType(
    'app/subscription-cta/LOAD_SUBSCRIPTION_PROMOTION_STATE'
);
export const SEND_SUBSCRIPTION_PROMOTION_REACTION = asyncActionType(
    'app/subscription-cta/SEND_SUBSCRIPTION_PROMOTION_REACTION'
);
export const SUBSCRIPTION_PROMOTION_THNX_BANNER =
    'app/subscription-cta/SUBSCRIPTION_PROMOTION_THNX_BANNER';
export const DISPOSE_SUBSCRIPTION_PROMOTION_RULES =
    'app/subscription-cta/DISPOSE_SUBSCRIPTION_PROMOTION_RULES';

export const USER_PERMISSIONS_TYPES_FETCH = asyncActionType('app/userPermissions/TYPES_FETCH');

export const USER_PERMISSIONS_MESSAGE_TYPES_FETCH = asyncActionType(
    'app/userPermissions/MESSAGE_TYPES_FETCH'
);

export const USER_PERMISSIONS_USERS_FETCH = asyncActionType('app/userPermissions/USERS_FETCH');

export const USER_PERMISSIONS_ALLOW_MESSAGE_TYPE = asyncActionType(
    'app/userPermissions/CLUB_PERMISSIONS_ALLOW_MESSAGE_TYPE'
);

export const USER_PERMISSIONS_DISALLOW_MESSAGE_TYPE = asyncActionType(
    'app/userPermissions/CLUB_PERMISSIONS_DISALLOW_MESSAGE_TYPE'
);

export const ADMIN_MESSAGING_GET_CHANNELS = asyncActionType(
    'app/adminMessaging/ADMIN_MESSAGING_GET_CHANNELS'
);

export const ADMIN_MESSAGING_GET_CHANNEL_MESSAGES = asyncActionType(
    'app/adminMessaging/ADMIN_MESSAGING_GET_CHANNEL_MESSAGES'
);

export const ADMIN_AGENCIES_UPLOAD_IMAGE = asyncActionType(
    'app/adminAgencies/ADMIN_AGENCIES_UPLOAD_IMAGE'
);

export const ADMIN_AGENCIES_DELETE_IMAGE = asyncActionType(
    'app/adminAgencies/ADMIN_AGENCIES_DELETE_IMAGE'
);

export const ADMIN_AGENCIES_UPLOAD_BG_IMAGE = asyncActionType(
    'app/adminAgencies/ADMIN_AGENCIES_UPLOAD_BG_IMAGE'
);

export const ADMIN_AGENCIES_DELETE_BG_IMAGE = asyncActionType(
    'app/adminAgencies/ADMIN_AGENCIES_DELETE_BG_IMAGE'
);

export const ADMIN_AGENCIES_DEACTIVATE = asyncActionType(
    'app/adminAgencies/ADMIN_AGENCIES_DEACTIVATE'
);

export const ADMIN_SQUADS_REFRESH = asyncActionType('app/adminSquads/ADMIN_SQUADS_REFRESH');

export const ADMIN_SQUADS_SET_FRIENDLY_ONLY = asyncActionType(
    'app/adminSquads/ADMIN_SQUADS_SET_FRIENDLY_ONLY'
);

export const ADMIN_SQUADS_SET_TOKEN_IMPERSONATE = asyncActionType(
    'app/adminSquads/ADMIN_SQUADS_SET_TOKEN_IMPERSONATE'
);

export const ADMIN_SQUADS_RESET_FRIENDLY_ONLY = asyncActionType(
    'app/adminSquads/ADMIN_SQUADS_RESET_FRIENDLY_ONLY'
);

export const ADMIN_SQUADS_ASSIGN_SUPERVISOR = asyncActionType(
    'app/adminSquads/ADMIN_SQUADS_ASSIGN_SUPERVISOR'
);

export const ADMIN_PLAYER_PROPOSALS_FETCH = asyncActionType('app/adminPlayerProposals/FETCH');

export const ADMIN_FRIENDLY_PROPOSALS_FETCH = asyncActionType('app/adminFriendlyProposals/FETCH');

export const ADMIN_FRIENDLY_PROPOSALS_SEND_EMAIL = asyncActionType(
    'app/adminFriendlyProposals/SEND_EMAIL'
);

export const ADMIN_COMPETITION_PROPOSALS_FETCH = asyncActionType(
    'app/adminPlayerProposals/ADMIN_COMPETITION_PROPOSALS_FETCH'
);

export const ADMIN_PLAYER_PROPOSALS_SEND_EMAIL = asyncActionType(
    'app/adminPlayerProposals/SEND_EMAIL'
);

export const ADMIN_NEWSLETTER_SEND_EMAIL = asyncActionType('app/adminNewsletter/SEND_EMAIL');

export const ADMIN_BLOCKED_RECEIVERS_FETCH = asyncActionType('app/adminBlockedReceivers');

export const ADMIN_BLOCKED_RECEIVERS_BLOCK = asyncActionType('app/adminBlockedReceiversBlock');

export const ADMIN_BLOCKED_RECEIVERS_UNBLOCK = asyncActionType('app/adminBlockedReceiversUnblock');

// Login
export const LOGIN_CHECK = asyncActionType('app/login/LOGIN_CHECK');

//UserGeneralInfo
export const USER_PREFERENCE_FETCH = asyncActionType('app/userPreference/FETCH');

export const AGENCY_LANDING_MAKE_AS_VIEWED = 'app/userPreference/AGENCY_LANDING_MAKE_AS_VIEWED';
export const USER_PREFERENCE_DEFAULT = 'app/userPreference/AGENCY_LANDING_MAKE_AS_VIEWED';
export const AGENCY_FORCE_NOTIFICATION_LAYOUT_FALSE =
    'app/userPreference/AGENCY_FORCE_NOTIFICATION_LAYOUT_FALSE';
export const AGENCY_FORCE_TRANSPARENCY_LAYOUT_TRUE =
    'app/userPreference/AGENCY_FORCE_TRANSPARENCY_LAYOUT_TRUE';
export const AGENCY_FORCE_TRANSPARENCY_LAYOUT_FALSE =
    'app/userPreference/AGENCY_FORCE_TRANSPARENCY_LAYOUT_FALSE';
export const AGENCY_FORCE_REONBOARDING_POPUP_FALSE =
    'app/userPreference/AGENCY_FORCE_REONBOARDING_POPUP_FALSE';
export const PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_FALSE =
    'app/userPreference/PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_FALSE';
export const PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_TRUE =
    'app/userPreference/PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_TRUE';
export const PLAYER_FORCE_PREFERENCES_SCREEN_LAYOUT_FALSE =
    'app/userPreference/PLAYER_FORCE_PREFERENCES_SCREEN_LAYOUT_FALSE';
export const PLAYER_SHOW_EXPLORE_PERFORMANCE_BANNER_FALSE =
    'app/userPreference/PLAYER_SHOW_EXPLORE_PERFORMANCE_BANNER_FALSE';
export const PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS =
    'app/userPreference/PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS';
export const PLAYER_UPDATE_IS_AGENT_FINDER_AVAILABLE =
    'app/userPreference/PLAYER_UPDATE_IS_AGENT_FINDER_AVAILABLE';
export const PLAYER_FORCE_PLAYER_REMOVED_SCREEN_LAYOUT_FALSE =
    'app/userPreference/PLAYER_FORCE_PLAYER_REMOVED_SCREEN_LAYOUT_FALSE';
export const CLUB_FORCE_REONBOARDING_POPUP_FALSE =
    'app/userPreference/CLUB_FORCE_REONBOARDING_POPUP_FALSE';
export const CLUB_FORCE_REONBOARDING_POPUP_TRUE =
    'app/userPreference/CLUB_FORCE_REONBOARDING_POPUP_TRUE';
export const CLUB_FORCE_MANUAL_REONBOARDING_POPUP_FALSE =
    'app/userPreference/CLUB_FORCE_MANUAL_REONBOARDING_POPUP_FALSE';
export const CLUB_FORCE_MANUAL_REONBOARDING_POPUP_TRUE =
    'app/userPreference/CLUB_FORCE_MANUAL_REONBOARDING_POPUP_TRUE';
export const PERMISSIONS_PERSONAL_ACCOUNT_UPDATE =
    'app/userPreference/PERMISSIONS_PERSONAL_ACCOUNT_UPDATE';
export const COACH_IS_AGENCY_CONFIRMATION_NEEDED_FALSE =
    'app/userPreference/COACH_IS_AGENCY_CONFIRMATION_NEEDED_FALSE';
export const USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE =
    'app/userPreference/USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE';

export const SUPER_PITCH_FETCH_COMPETITONS_WITH_SQUAD = asyncActionType(
    'app/superPitchCreate/FETCH_COMPETITIONS_WITH_SQUAD'
);

export const SUPER_PITCH_FETCH_SQUAD_PLAYERS = asyncActionType(
    'app/superPitchCreate/FETCH_SQUAD_PLAYERS'
);

export const GET_RECOMMENDED_SQUADS_BY_PLAYER_ID_AND_PRICE = asyncActionType(
    'app/superPitchCreate/GET_RECOMMENDED_SQUADS_BY_PLAYER_ID_AND_PRICE'
);

export const SUPER_PITCH_CREATE_SUPER_PITCH = asyncActionType(
    'app/superPitchCreate/CREATE_SUPER_PITCH'
);

// LiveData
export const FETCH_UNREAD_MESSAGES_COUNT = asyncActionType(
    'app/liveData/FERCH_UNREAD_MESSAGES_COUNT'
);
export const AGENT_DEACTIVATE_FETCH = asyncActionType('app/agentDeactivate/FETCH');
export const AGENT_FEATURE_DEACTIVATION_PROCESSING_STATE =
    'AGENT_FEATURE_DEACTIVATION_PROCESSING_STATE';
export const AGENT_FEATURE_DEACTIVATION_DONE = 'AGENT_FEATURE_DEACTIVATION_DONE';

export const AUTH_DEACTIVATE_AGENCY_FEATURE = 'app/auth/AUTH_DEACTIVATE_AGENCY_FEATURE';

export const MESSAGES_SEND_LITE = asyncActionType('MESSAGES_SEND_LITE');

export const PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE = asyncActionType(
    'app/players/DECLARE_INTEREST_TO_AGENT_SAVE'
);

export const USER_REQUESTS_AGENT_ACTIVATE = asyncActionType('app/userRequests/ACTIVATE_AGENT');

export const USER_REQUESTS_AGENT_DELETE = asyncActionType('app/userRequests/DELETE_AGENT');

export const USER_REQUESTS_UPDATE_AGENCY = asyncActionType('app/userRequests/EDIT_AGENCY');

export const AGENCY_PERMISSIONS_WITH_SUBSCRIPTIONS_FETCH = asyncActionType(
    'app/agencyPermission/GET_AGENCY'
);
