export const VIRTUAL_SUMMIT_10TH_NOVEMBER_2021_ID = 27;
export const AMERICAS_SUMMIT_14_15TH_FEBRUARY_2022_ID = 28;
export const MADRID_SUMMIT_21ST_22ND_MARCH_2022_ID = 29;
export const MADRID_SUMMIT_DEAL_DAY_16TH_JUNE_2022_ID = 30;
export const VIRTUAL_DEAL_DAY_AUGUST_2022_ID = 31;
export const BERLIN_SUMMIT_13TH_16TH_NOVEMBER_2022_ID = 32;
export const VIRTUAL_DEAL_DAY_JANUARY_2023_ID = 33;
export const LONDON_SUMMIT_MARCH_2023_ID = 34;
export const SAO_PAOLO_SUMMIT_JUNE_2023_ID = 35;
export const VIRTUAL_DEAL_DAY_AUGUST_2023_ID = 36;
export const LISBON_SUMMIT_NOVEMBER_2023_ID = 37;
export const VIRTUAL_DEAL_DAY_JANUARY_2024_ID = 38;
export const ROME_SUMMIT_MARCH_2024_ID = 39;
export const CANCUN_SUMMIT_JUNE_2024_ID = 40;
export const MADRID_SUMMIT_AUGUST_2024_ID = 41;
export const ISTANBUL_SUMMIT_NOVEMBER_2024_ID = 42;