import {StateController} from 'utils/action-declaration';
import {AppState} from 'root.reducer';
import historyAccessor from 'history-accessor';
import OnboardingService from 'api/agency/agent/onboarding/onboarding.service'
import AgencyPlayerService from 'api/agency/player/shared/agency-player-service'
import CommonService from 'api/common/common.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import {PageType} from 'constants/enums';
import {AgentPlayerInvitationItemModel, PreconnectedPlayerModel} from 'api/agency/agent/onboarding/onboarding-models';
import {agencyPaths} from 'routes/paths';
import {
    Actions as VerificationController
} from 'pages/agency/authorized-agent/player-verification/verification.controller'
import {CreatePlayerRequest, PlayerModalForm} from "api/agency/player/shared/shared-models";
import { NationalitiesRequest } from 'api/common/models';
import {
    Actions as SuggestionActions
} from "pages/agency/authorized-agent/public-profile/edit-public-profile/track-record/squad-suggestion/squad-suggestion.controller"
import { GeneralPositionEnum } from 'api/core/general-position.model';
import { FootTypeEnum } from 'api/core/foot-type';

export enum AddPlayersStepEnum {
    AddPlayers = 1,
    ChoosePremiumPlayers = 2,
    PremiumPlayersBenefits = 3
}

export enum PlayerPosition {
    AttackingMidfielder = 1,
    CentralMidfielder = 2,
    CentreBack = 3,
    DefensiveMidfielder = 4,
    Forward = 5,
    Goalkeeper = 6,
    LeftBack = 7,
    RightBack = 8,
    Winger = 9,
    CentreBackLeftFooted = 10,
}

class State {
    step: AddPlayersStepEnum;
    isLoading: boolean;
    pendingItems: Array<PreconnectedPlayerModel>;
    processing: boolean;
    amountOfAvailablePlayers: number;
    premiumBenefitsModalOpen: boolean;
    isCreatePlayerModalOpen: boolean;
    createPlayerModalForm: PlayerModalForm;
    nationalityName: string;
    secondNationalityName: string;
    isCalendarOpen: boolean;
    isThankYouModalOpen: boolean;
    nationalitiesList: NationalitiesRequest[];
}

const defaultPlayerModalForm = {
    source: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    height: null,
    nationality: null,
    secondNationality: null,
    isFreeAgent: false,
    foot: null,
    contractExpiry: null,
    club: '',
    squadId: null,
    primaryPosition: null,
    secondaryPosition: null,
}
const defaultState: State = {
    step: AddPlayersStepEnum.AddPlayers,
    isLoading: false,
    pendingItems: [],
    processing: false,
    amountOfAvailablePlayers: 0,
    premiumBenefitsModalOpen: false,
    isCreatePlayerModalOpen: false,
    createPlayerModalForm: defaultPlayerModalForm,
    nationalityName: '',
    secondNationalityName: '',
    isCalendarOpen: false,
    isThankYouModalOpen: false,
    nationalitiesList: [],
}

const stateController = new StateController<State>(
    "AGENCY/ADD_PLAYERS",
    defaultState
)

class Actions {

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                defaultState
            ));
        }

    }

    public static completeAdding(): any {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let pendingItems = state.agency.agent.addPlayers.pendingItems;

            try {
                dispatch(stateController.setState(({ processing: true })));
                
                let playerItems: AgentPlayerInvitationItemModel[] = pendingItems.map((item) => ({ playerId: item.id, isRegular: item.isRegular }))
                
                await OnboardingService.addPlayers(playerItems);
                
                const premiumPlayers = pendingItems.filter(x => !x.isRegular);
                for (let index = 0; index < premiumPlayers.length; index++) {
                    await dispatch(userActivityInsert({
                        Message: `Converted to Premium`,
                        PageName: 'Agency Home [Add Players]',
                        PlayerId: premiumPlayers[index].id,
                        ClubId: premiumPlayers[index].parentSquadId,
                        PageType: PageType.AgencyHome
                    }));
                }

                dispatch(stateController.setState({ processing: false }));
                historyAccessor.push(agencyPaths.playerVerification);

            } catch(e) {
                console.error(e)
                dispatch(stateController.setState({ processing: false }))
                dispatch({ type: '--ERROR' })
            }
        }

    }

    public static goToStep(step: AddPlayersStepEnum): any {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                step
            }));
        }
    }

    public static openPremiumBenefitsStep(): any {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                step: AddPlayersStepEnum.PremiumPlayersBenefits
            }));

            dispatch(userActivityInsert({
                Message: `Opened Premium Benefits Info`,
                PageName: 'Agency Home [Add Players]',
                PageType: PageType.AgencyHome
            }));
        }
    }

    public static closePremiumBenefitsStep(): any {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                step: AddPlayersStepEnum.ChoosePremiumPlayers
            }));

            dispatch(userActivityInsert({
                Message: `Closed Premium Benefits Info`,
                PageName: 'Agency Home [Add Players]',
                PageType: PageType.AgencyHome
            }));
        }
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(({ isLoading: true })));
            await dispatch(this.loadSubscriptionStats());
            dispatch(stateController.setState({ isLoading: false }));
        }
    }

    public static addPlayer(item: PreconnectedPlayerModel) {
        return (dispatch, getState: () => AppState) => {
            let subState = (getState() as AppState).agency.agent;
            item.isRegular = true;
            let newItems = [...subState.addPlayers.pendingItems, item];
            let sortedItems = newItems.sort((a, b) => a.shortName.localeCompare(b.shortName));

            dispatch(userActivityInsert({
                Message: `Added Player`,
                PageName: 'Agency Home [Add Players]',
                PlayerId: item.id,
                ClubId: item.parentSquadId,
                PageType: PageType.AgencyHome
            }));

            dispatch(stateController.setState({ pendingItems: sortedItems }));
        }
    }

    public static goToAddPlayer() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(agencyPaths.addPlayers)
            dispatch(userActivityInsert({
                PageName: 'Agency Players',
                Message: 'Add Players',
                PageType: PageType.AgencyPlayers
            }));
        }
    }

    public static removePlayer(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = (getState() as AppState).agency.agent;
            const newItems = [...subState.addPlayers.pendingItems.filter(x => x.id !== playerId)];
            const playerRemoved = subState.addPlayers.pendingItems.find(x => x.id == playerId);

            dispatch(userActivityInsert({
                Message: `Removed Player`,
                PageName: 'Agency Home [Add Players]',
                PlayerId: playerRemoved.id,
                ClubId: playerRemoved.parentSquadId,
                PageType: PageType.AgencyHome
            }));

            dispatch(stateController.setState({ pendingItems: newItems }));
        }
    }

    public static getPlayersByKeyword = (keyword) => {
        return async (dispatch, getState: () => AppState) => {
            return  await AgencyPlayerService.getAllByKeyword(keyword);
        }
    }
    public static initCreatePlayer = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
               const data = await CommonService.getNationalitiesList();
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    nationalitiesList: data,
                })));
            } catch (e) {
                console.error(e);
            }
        }
    }
    
    public static onClubSelected = (club) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    club: club ? club.name : null,
                    squadId: club ? club.id : null,
                }
            })));
        }
    }
    public static onNationalitySelected = (nationality) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                nationalityName: nationality.name,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    nationality: nationality.id,
                }
            })));
        }
    }
    public static onSecondNationalitySelected = (nationality) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                secondNationalityName: nationality.name,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    secondNationality: nationality.id,
                }
            })));
        }
    }
    
    public static isFreeAgent = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    isFreeAgent: !prevState.createPlayerModalForm.isFreeAgent,
                }
            })));
            
            if(getState().agency.agent.addPlayers.createPlayerModalForm.isFreeAgent) {
                dispatch(Actions.setExpireDate(null));
                dispatch(Actions.onClubSelected(null));
                dispatch(SuggestionActions.clearKeyword());
                dispatch(Actions.sendUserActivity('Player Information Selected'));
            }
        }
    }
    public static selectFootFilter = (foot: FootTypeEnum | null) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Player Information Selected'));
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    foot: foot,
                }
            })));
        }
    }
    public static selectPrimaryPosition = (position) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Player Information Selected'));
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    primaryPosition: position,
                }
            })));
        }
    }
    public static selectSecondaryPosition = (position) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Player Information Selected'));
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    secondaryPosition: position,
                }
            })));
        }
    }
    public static setSource = (source) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    source: source,
                }
            })));
        }
    }
    public static setFirstName = (name) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    firstName: name,
                }
            })));
        }
    }
    public static setLastName = (name) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    lastName: name,
                }
            })));
        }
    }
    public static setHeight= (height) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                createPlayerModalForm: {
                    ...prevState.createPlayerModalForm,
                    height: height,
                }
            })));
        }
    }

    public static loadSubscriptionStats = () => {
        return async (dispatch, getState: () => AppState) => {
            const data = await OnboardingService.getSubscriptionStats();
            const amountOfAvailablePlayers = data.totalSlots - data.usedSlots;
            dispatch(stateController.setState(({ amountOfAvailablePlayers })));
        }
    }

    public static closed() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Closed`,
                PageName: `Agency Home [Add Players]`,
                PageType: PageType.AgencyHome
            }));
            if(getState().agency.agent.verifyPlayers.isRedirectToPitchPage) {
                historyAccessor.push(agencyPaths.pitchPage);
                dispatch(VerificationController.redirectToPitchPage());
            } else {
                historyAccessor.push('/')
            }
            
        }
    }

    public static onPremiumChange(playerId: number, isPremium: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const pendingItems = state.agency.agent.addPlayers.pendingItems;
            const player = pendingItems.find(x => x.id === playerId);

            player.isRegular = !isPremium;

            dispatch(stateController.setState(({ pendingItems: [...pendingItems] })));
        }
    }

    public static openPremiumBenefitsModal(customPageName?: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(({ premiumBenefitsModalOpen: true })));

            dispatch(userActivityInsert({
                Message: `Opened Priority Benefits`,
                PageName: customPageName ? customPageName : `Agency Players`,
                PageType: PageType.AgencyPlayers
            }));
        }
    }
    
    public static toggleCreatePlayerModal() {
        return async (dispatch, getState: () => AppState) => {
            const isOpen = getState().agency.agent.addPlayers.isCreatePlayerModalOpen;
            if(!isOpen) {
                dispatch(Actions.sendUserActivity('Contact Us Button Clicked'));
            }
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isCreatePlayerModalOpen: !isOpen,
            })));
        }
    }
    
    public static openPriorityPlayerCreditsInfoModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(({ premiumBenefitsModalOpen: true })));

            dispatch(userActivityInsert({
                Message: `Priority Player Credits Info Clicked`,
                PageName: `Agency Players [Priority Player Credits]`,
                PageType: PageType.AgencyPlayers
            }));

            dispatch(userActivityInsert({
                Message: `Viewed Benefits for Priority Players`,
                PageName: `Agency Players [Priority Player Credits]`,
                PageType: PageType.AgencyPlayers
            }));
        }
    }

    public static closePremiumBenefitsModal(isPriorityPlayerCreditsInfoModal?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(({ premiumBenefitsModalOpen: false })));

            if (isPriorityPlayerCreditsInfoModal) {
                dispatch(userActivityInsert({
                    Message: `Clicked Close`,
                    PageName: `Agency Players [Priority Player Credits]`,
                    PageType: PageType.AgencyPlayers
                }));
            } else {
                dispatch(userActivityInsert({
                    Message: `Closed Priority Benefits`,
                    PageName: `Agency Players`,
                    PageType: PageType.AgencyPlayers
                }));
            }
        }
    }
    
    public static setExpireDate(date: string | Date | null) {
        return async (dispatch, getState: () => AppState) => {
                dispatch(stateController.setState(prevState =>({
                    ...prevState,
                    createPlayerModalForm: {
                        ...prevState.createPlayerModalForm,
                        contractExpiry: date,
                    }
                })));
            }
        }
        public static setBirthDate(date: string | Date | null) {
            return async (dispatch, getState: () => AppState) => {
                dispatch(stateController.setState(prevState =>({
                    ...prevState,
                    createPlayerModalForm: {
                        ...prevState.createPlayerModalForm,
                        dateOfBirth: date,
                    }
                })));
            }
        }
        public static clearForm() {
            return async (dispatch, getState: () => AppState) => {
                dispatch(stateController.setState(prevState =>({
                    ...prevState,
                    createPlayerModalForm: {
                        ...defaultPlayerModalForm,
                    }
                })));
            }
        }
        public static onSubmitPlayerForm() {
            return async (dispatch, getState: () => AppState) => {
                try {
                    const data = getState().agency.agent.addPlayers.createPlayerModalForm;
                    const getData = () => {
                        let primaryPosition = data.primaryPosition as unknown as GeneralPositionEnum;
                        let secondaryPosition = data.secondaryPosition as unknown as GeneralPositionEnum;
                        if (data.primaryPosition === PlayerPosition.CentreBackLeftFooted) {
                            primaryPosition = GeneralPositionEnum.CentreBack
                        }
                        if(data.secondaryPosition === PlayerPosition.CentreBackLeftFooted) {
                            secondaryPosition = GeneralPositionEnum.CentreBack
                        }

                        const newData: CreatePlayerRequest = {
                            source: data.source,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            dateOfBirth: data.dateOfBirth,
                            height: data.height,
                            nationality: data.nationality,
                            secondNationality: data.secondNationality,
                            isFreeAgent: data.isFreeAgent,
                            foot: data.foot,
                            contractExpiry: data.contractExpiry,
                            club: data.club,
                            squadId: data.squadId,
                            primaryPosition: primaryPosition,
                            secondaryPosition: secondaryPosition
                        };

                        return newData;
                    }
                    await AgencyPlayerService.createTicketAddPlayer(getData());
                    dispatch(Actions.clearForm());
                    dispatch(Actions.toggleCreatePlayerModal());
                    dispatch(Actions.toggleThankYouModal());
                    dispatch(Actions.sendUserActivity('Submit Button Clicked'));
                } catch (e) {
                    console.error(e);
                }
            }
        }
        public static toggleThankYouModal() {
            return async (dispatch, getState: () => AppState) => {
                try {
                    const isThankYouModalOpen = getState().agency.agent.addPlayers.isThankYouModalOpen;
    
                    dispatch(stateController.setState(prevState =>({
                        ...prevState,
                        isThankYouModalOpen: !isThankYouModalOpen,
                    })));
                } catch (e) {
                    console.error(e);
                }
            }
        }

        public static sendUserActivity(message) {
            return async (dispatch, getState: () => AppState) => {
                dispatch(userActivityInsert({
                    PageName: 'Agency Players [Add Players]',
                    Message: message,
                    PageType: PageType.AgencyPlayers,
                }))
            }
        }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};