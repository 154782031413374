import {StateController} from 'utils/action-declaration';
import {AppState} from 'root.reducer';
import {CancelTokenSource} from 'axios';
import {getCancelTokenSource} from 'axios-config';
import {getPageSize} from 'ts-components/pagination/helpers/paging-store';
import {
    HeadCoachExperienceEnum,
    SearchCoachRequest,
    SearchRequest,
    SortByKeyEnum
} from 'api/search-v3/model/search-request';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import insertUserActivity from 'app/user-activity/actions/user-activity.actions';
import {PageType} from 'constants/enums';
import {
    GridToggleState,
    PaginationClickState,
    SearchMode
} from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import {Actions as AutoSuggestActions} from 'pages/PlayerSearch-v3/agency/redux/autosuggest.controller';
import {
    Actions as FilterActions,
    Selectors as FilterSelectors
} from 'pages/PlayerSearch-v3/agency/redux/filter.controller';
import {SearchResult} from 'api/search-v3/model/search-result';
import {SearchPlayerItemForAgency} from 'api/search-v3/model/search-player-item-for-agency';
import {CoachStatus, DisplayCoachStatus, SearchCoachItemForClub} from 'api/search-v3/model/search-coach-item-for-club';
import {SearchPlayerPositionEnum} from 'api/search-v3/model/player-positions';
import AgenciesSearchService from 'api/search-v3/search.agenciesside.service';
import {ShortlistService} from 'api/shortlist/shortlist-service';
import AgencyAgentOnBoardingService from 'api/agency/agent/onboarding/onboarding.service';
import {DisplayStatus, PlayerStatus,} from 'api/search/model/search-player-item-for-agency';
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import historyAccessor from 'history-accessor';
import {agencyPaths} from 'routes/paths';
import CoachOnboardingService from 'api/agency/agent/onboarding/coaches/coach-onboarding.service';

type SearchResultAgency = SearchResult<SearchPlayerItemForAgency>;
type SearchCoachResultClub = SearchResult<SearchCoachItemForClub>;

class GridState {
    result: SearchResultAgency | SearchCoachResultClub;
    resultLoading: boolean;
    selectedItemId: number;
    gridState: GridToggleState;
    pageSize: number;
    processingItems: number[];
    similarityPlayerId: number;
    paginationState: number;
    isVideoModalOpen: boolean;
    playerVideoModal: PlayerVideoModalState;
}
export type PlayerVideoModalState = {
    playerVideos: string[]
    playerId: number
    isModalOpen: boolean
    playerName: string
}

const defaultState: GridState = {
    result: null,
    gridState: GridToggleState.None,
    resultLoading: false,
    selectedItemId: null,
    pageSize: getPageSize('search', 10),
    processingItems: [],
    similarityPlayerId: null,
    paginationState: PaginationClickState.Number,
    isVideoModalOpen: false,

    playerVideoModal: {
        playerVideos: [],
        playerId: null,
        isModalOpen: false,
        playerName: '',
    }
}

const stateController = new StateController<GridState>(
    'AGENCY_SEARCH/GRID',
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static sendUserActivity(message) {
        return (dispatch, getState: () => AppState) => {
            // dispatch(userActivityInsert({
            //     PageName: 'Search [Highlight]',
            //     Message: message,
            //     PageType: PageType.Search,
            // }))
        }
    }

    public static resetFilter() {
        return (dispatch, getState: () => AppState) => {
            // dispatch(Actions.clearPositionFilter());
            // dispatch(Actions.clearPlayerAttributesFilter());
            // dispatch(Actions.clearShowOnlyShortListedPlayersFilter());
            // dispatch(GridActions.resetPage());
            // dispatch(Actions.initSorting());
        }
    }

    public static toggleGridState(gridState: GridToggleState, definedSorting: SortByKeyEnum = null) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                gridState: gridState,
            }));
            dispatch(FilterActions.initSorting(definedSorting));
        }
    }

    public static moveToSearchResultsByGridToggleState(grid: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            dispatch(AutoSuggestActions.saveLatestSearchKeyword());
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(Actions.toggleGridState(grid));
            dispatch(FilterActions.toggleQuickSearchMode( false ));

            if(grid === GridToggleState.PlayersSearch) {
                dispatch(AutoSuggestActions.sendUserActivity('See All Players'));
            } else if (grid === GridToggleState.HeadCoachesSearch) {
                dispatch(AutoSuggestActions.sendUserActivity('See All Coaches'));
            }
        }
    }

    public static quickSearchAll(grid: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            dispatch(AutoSuggestActions.saveLatestSearchKeyword());
            // dispatch(FilterActions.resetFilter());
            dispatch(Actions.toggleGridState(grid));

            if(grid === GridToggleState.PlayersSearch) {
                dispatch(Actions.searchPlayers());

                dispatch(AutoSuggestActions.sendUserActivity('See All Players'));
            } else if (grid === GridToggleState.HeadCoachesSearch) {
                dispatch(Actions.searchCoaches());

                dispatch(AutoSuggestActions.sendUserActivity('See All Coaches'));
            }

        }
    }

    public static onSearchByPosition(positionId: SearchPlayerPositionEnum, itemName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setPositionFilterValue(positionId, itemName));
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(Actions.toggleGridState(GridToggleState.PlayersSearch));
        }
    }

    public static goToShortList() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(FilterActions.setShowOnlyShortListedPlayers(true))
            dispatch(Actions.toggleGridState(GridToggleState.ShortList));

            dispatch(userActivityInsert({
                PageName: 'Search [Highlight]',
                Message: 'Selected: Shortlist',
                PageType: PageType.Search,
            }));
        }
    }

    public static goToLookingForAgents() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(Actions.toggleGridState(GridToggleState.LookingForAgent));
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(FilterActions.setLookingForAgentPlayers(true))
           //dispatch(Actions.toggleGridState(GridToggleState.PlayersSearch));

            dispatch(userActivityInsert({
                PageName: 'Search [Highlight]',
                Message: 'Selected: Explore',
                PageType: PageType.Search,
            }));
        }
    }

    public static refresh(isUserActivityTracked?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const gridState = Selectors.getGridState(getState());
            if (gridState === GridToggleState.PlayersSearch || gridState === GridToggleState.ShortList || gridState === GridToggleState.LookingForAgent) {
                dispatch(Actions.searchPlayers(isUserActivityTracked));
            } else if (gridState === GridToggleState.HeadCoachesSearch) {
                dispatch(Actions.searchCoaches(isUserActivityTracked));
            }
        }
    }

    public static searchPlayers(isUserActivityTracked?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let tabKey = Selectors.getGridState(getState());
                let searchMode = FilterSelectors.getRoot(getState()).searchMode;
                // let isAnyFilterActive = FilterSelectors.isFiltersInActiveState(getState());
                // if (tabKey == GridToggleState.Search && searchMode == SearchMode.Default && !isAnyFilterActive) {
                if (tabKey == GridToggleState.Search && searchMode == SearchMode.Default) {
                    return;
                }

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();

                const state = getState();

                dispatch(stateController.setState({ resultLoading: true, result: null }));

                let requestPayload: SearchRequest = Selectors.getPlayerRequest(state);
                let result: SearchResultAgency = null;
                result = await AgenciesSearchService.agenciesSearchPlayers(requestPayload, Actions.token.token);

                dispatch(stateController.setState({ result: result }));

                if (isUserActivityTracked) {
                    dispatch(AutoSuggestActions.gridSendUserActivity());
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ resultLoading: false }));
            }
        }
    }

    public static searchCoaches(isUserActivityTracked?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let tabKey = Selectors.getGridState(getState());
                let searchMode = FilterSelectors.getRoot(getState()).searchMode;
                // let isAnyFilterActive = FilterSelectors.isFiltersInActiveState(getState());
                if (tabKey == GridToggleState.Search && searchMode == SearchMode.Default)
                    return;

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();

                const state = getState();

                dispatch(stateController.setState({ resultLoading: true, result: null }));

                let requestPayload: SearchCoachRequest = Selectors.getCoachRequest(state);
                let result: SearchCoachResultClub = null;
                // result = await ClubSideSearchService.searchCoaches(requestPayload, Actions.token.token);
                result = await AgenciesSearchService.agenciesSearchCoaches(requestPayload, Actions.token.token);

                dispatch(stateController.setState({ result: result }));

                if (isUserActivityTracked) {
                    dispatch(AutoSuggestActions.gridSendUserActivity());
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ resultLoading: false }));
            }
        }
    }

    public static onFindOutMoreClick(isSearchBar?: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search Bar',
                Message: 'Clicked Find Out More',
                PageType: PageType.Search,
            }));
        }
    }

    public static onDiscoverPlayersByAttributes() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(Actions.toggleGridState(GridToggleState.PlayersSearch));

            dispatch(userActivityInsert({
                PageName: 'Search [Highlight]',
                Message: 'Selected: Popular',
                PageType: PageType.Search,
            }));
        }
    }

    // Pagination START

    public static setPage = (page: number) => {
        return (dispatch, getState: () => AppState) => {
            const paginationState = Selectors.getRoot(getState()).paginationState;

            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: {
                        ...draftState.result,
                        currentPage: page
                    },
                }
            }))

            dispatch(Actions.refresh());

            let message = '';
            switch (paginationState) {
                case PaginationClickState.Number:
                    message = `Moved to Page: ${page}`;
                    break;
                case PaginationClickState.Left:
                    message = `Moved to Previous Page: ${page}`;
                    break;
                case PaginationClickState.Right:
                    message = `Moved to Next Page: ${page}`;
                    break;
            }

            dispatch(userActivityInsert({
                PageName: 'Search [Pagination]',
                Message: message,
                PageType: PageType.Search
            }))
        }
    }

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: page },
                    pageSize,
                }
            }))
            dispatch(Actions.refresh())

            dispatch(userActivityInsert({
                PageName: 'Search',
                Message: `Selected ${pageSize} rows`,
                PageType: PageType.Search
            }))
        }
    }

    public static resetPage = () => {
        return (dispatch) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: 1 },
                }
            }))
        }
    }

    public static paginationSetState(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ paginationState: value }));
        }
    }

    // Pagination END


    // player search card START

    public static onShortListClick(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let subState = Selectors.getRoot(getState());

            let players = Selectors.getPlayers(getState());
            let currentPlayer = players.find(x => x.id === playerId);
            const { parentSquad, isFreeAgent, agencyPlayerDisplayInfo } = currentPlayer;
            const agencyId = agencyPlayerDisplayInfo?.representedBy?.id;

            dispatch(stateController.setState({ processingItems: [...subState.processingItems, playerId] }));
            if (currentPlayer.isShortListed) {
                await ShortlistService.agencyRemoveFromShortlist(playerId);
                currentPlayer = { ...currentPlayer, isShortListed: false };

                dispatch(insertUserActivity({
                    Message: 'Removed from Shortlist',
                    PageName: 'Search',
                    PlayerId: playerId,
                    ClubId: parentSquad ? parentSquad.id : null,
                    AgencyId: isFreeAgent ? agencyId : null,
                }));
            } else {
                await ShortlistService.agencyAddToShortlist(playerId);
                currentPlayer = { ...currentPlayer, isShortListed: true };
                dispatch(insertUserActivity({
                    Message: 'Added to Shortlist',
                    PageName: 'Search',
                    PlayerId: playerId,
                    ClubId: parentSquad ? parentSquad.id : null,
                    AgencyId: isFreeAgent ? agencyId : null,
                }));
            }

            // let newResult: SearchResultAgency = {
            let newResult = {
                ...subState.result,
                items: subState.result.items.map((item) => {
                    if (item !== null && item.id == playerId) {
                        return { ...currentPlayer  }
                    }
                    return item;
                })
            }

            dispatch(stateController.setState({
                result: newResult,
                processingItems: [...subState.processingItems.filter(x => x != playerId)]
            }));
            if (Selectors.isInShortList(getState())) {
                dispatch(Actions.refresh())
            }
        }
    }

    public static claimRepresentation(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = Selectors.getRoot(state);

            let players = Selectors.getPlayers(state);
            let currentPlayer = players.find(x => x.id === playerId);
            const { parentSquad, isFreeAgent, agencyPlayerDisplayInfo } = currentPlayer;
            const agencyId = agencyPlayerDisplayInfo?.representedBy?.id;

            dispatch(stateController.setState({ processingItems: [...subState.processingItems, playerId] }));
            await AgencyAgentOnBoardingService.addPlayers([{ playerId, isRegular: true }]);

            dispatch(insertUserActivity({
                Message: 'Claim Representation',
                PageName: 'Search',
                PlayerId: playerId,
                ClubId: parentSquad ? parentSquad.id : null,
                AgencyId: isFreeAgent ? agencyId : null,
            }));

            currentPlayer = {
                ...currentPlayer,
                agencyPlayerDisplayInfo: {
                    ...currentPlayer.agencyPlayerDisplayInfo,
                    displayStatus: DisplayStatus.ButtonGetPlayerVerified, status: PlayerStatus.ClaimedNotVerified
                }
            };

            // let newResult: SearchResultAgency = {
            let newResult = {
                ...subState.result,
                items: subState.result.items.map((item) => {
                    if (item !== null && item.id == playerId) {
                        return { ...currentPlayer  }
                    }
                    return item;
                })
            }

            dispatch(stateController.setState({
                result: newResult,
                processingItems: [...subState.processingItems.filter(x => x != playerId)]
            }));
        }
    }

    public static getPlayerVerified(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = Selectors.getRoot(state);

            let players = Selectors.getPlayers(state);
            let currentPlayer = players.find(x => x.id === playerId);
            const { parentSquad, isFreeAgent, agencyPlayerDisplayInfo } = currentPlayer;
            const agencyId = agencyPlayerDisplayInfo?.representedBy?.id;


            dispatch(insertUserActivity({
                Message: 'Verify Player',
                PageName: 'Search',
                PlayerId: playerId,
                ClubId: parentSquad ? parentSquad.id : null,
                AgencyId: isFreeAgent ? agencyId : null,
            }));

            let url = window.origin + `/player-verification?player=${playerId}`;
            window.open(url, '_blank').focus();
        }
    }

    public static onViewProfileClick(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            let url = window.origin + `/profile/${playerId}`;
            window.open(url, '_blank').focus();

            let subState = Selectors.getRoot(getState());
            let players = Selectors.getPlayers(getState());
            let player = players.find(x => x.id === playerId);

            dispatch(insertUserActivity({
                Message: 'Opened Player Profile',
                PageName: 'Search',
                PlayerId: playerId,
                ClubId: player.parentSquad ? player.parentSquad.id : null,
                AgencyId: !player.parentSquad && player.agencyPlayerDisplayInfo.representedBy ? player.agencyPlayerDisplayInfo.representedBy.id : null
            }));
        }
    }

    public static contactPlayer(playerId: number, parentSquadId: number, isProfilePage?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let subState = Selectors.getRoot(getState());
            let players = Selectors.getPlayers(getState());
            let currentPlayer = players.find(x => x.id === playerId);
            dispatch(stateController.setState({ processingItems: [...subState.processingItems, playerId] }))
            var chanelId = await AgencyFinderService.sendRepresentationRequestToPlayer(playerId);

            currentPlayer = {
                ...currentPlayer,
                agencyPlayerDisplayInfo: {
                    ...currentPlayer.agencyPlayerDisplayInfo,
                    displayStatus: DisplayStatus.ButtonContactPlayerMessageSent,
                    contactPlayerMessageChannel: chanelId
                }
            };

            // let newResult: SearchResultAgency = {
            let newResult = {
                ...subState.result,
                items: subState.result.items.map((item) => {
                    if (item !== null && item.id == playerId) {
                        return { ...currentPlayer }
                    }
                    return item;
                })
            }
            // dispatch(insertUserActivity({
            //     Message: 'Sent Representation Message',
            //     PageName: isProfilePage ? 'Player Profile' : 'Search',
            //     PlayerId: playerId,
            //     ClubId: parentSquadId,
            // }));

            dispatch(stateController.setState({
                result: newResult,
                processingItems: [...subState.processingItems.filter(x => x != playerId)]
            }));
        }
    }

    public static goToMessageChannel(channelId: string, isProfilePage: boolean, playerId: number, parentSquadId: number, isCustomUserActivity?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            if (!isCustomUserActivity) {
                dispatch(insertUserActivity({
                    Message: 'Opened Representation Message',
                    PageName: isProfilePage ? 'Player Profile' : 'Search',
                    PlayerId: playerId,
                    ClubId: parentSquadId,
                }));
            }
            historyAccessor.push(`${agencyPaths.messaging}?channel=${channelId}`)
        }
    }

    public static openPlayerVideoModal(entity: SearchPlayerItemForAgency) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerVideoModal: {
                    playerId: entity.id,
                    isModalOpen: true,
                    playerName: entity.shortName,
                    playerVideos: entity.playerVideos,
                }
            })));
        }
    }

    public static closePlayerVideoModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerVideoModal: defaultState.playerVideoModal
            })))
        }
    }

    public static getPlayer = (state: AppState, id: number) => {
        return state.agencySearch.grid.result.items
            .filter(x => x != null)
            .map(x => x as SearchPlayerItemForAgency)
            .find(x => x.id == id);
    }

    public static onPositionRoleTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const player = Actions.getPlayer(getState(), playerId);
            const gridState = Selectors.getGridState(getState());
            const agencyId = !player.parentSquad && player?.agencyPlayerDisplayInfo?.representedBy ? player?.agencyPlayerDisplayInfo?.representedBy.id : null;
        }
    }

    public static onRatingTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const player = Actions.getPlayer(getState(), playerId);
            const agencyId = !player?.parentSquad && player?.agencyPlayerDisplayInfo?.representedBy ? player?.agencyPlayerDisplayInfo?.representedBy.id : null;

            dispatch(insertUserActivity({
                Message: 'Opened Player Rating Info',
                PageName: 'Search',
                PlayerId: playerId,
                ClubId: player?.parentSquad ? player?.parentSquad?.id : null,
                AgencyId: agencyId ? agencyId : null,
            }));
        }
    }

    public static updatePlayer(id: number, newItem: SearchPlayerItemForAgency) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const players = Selectors.getPlayers(getState()).map(item => item.id === id ? { ...newItem } : item)

            dispatch(stateController.setState({
                result: {
                    ...subState.result,
                    items: players,
                }
            }))
        }
    }

    // player search card END

    private static getCoach = (state: AppState, id: number) => {
        return state.agencySearch.grid.result.items
            .filter(x => x != null)
            .map(x => x as SearchCoachItemForClub)
            .find(x => x.staffId == id);
    }

    public static onTooltipHover = (coachId: number, isRating) => {
        return async (dispatch, getState: () => AppState) => {
            const coach = Actions.getCoach(getState(), coachId);
            const { isFreeAgent, squad, agencyCoachDisplayInfo } = coach;
            const agencyId = agencyCoachDisplayInfo?.representedBy?.id;

            if (isRating) {
                dispatch(insertUserActivity({
                    Message: 'Opened Coach Rating Info',
                    PageName: 'Search',
                    CoachId: coachId,
                    ClubId: squad ? squad.id : null,
                    AgencyId: isFreeAgent ? agencyId : null,
                }));
            }
        }
    }

    public static claimCoachRepresentation(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = Selectors.getRoot(state);

            let coaches = Selectors.getCoaches(state);
            let currentCoach = coaches.find(x => x.staffId === staffId);
            const { isFreeAgent, squad, agencyCoachDisplayInfo } = currentCoach;
            const agencyId = agencyCoachDisplayInfo?.representedBy?.id;

            dispatch(stateController.setState({ processingItems: [...subState.processingItems, staffId] }));
            await CoachOnboardingService.addCoaches([{ staffId, isRegular: true }]);

            dispatch(insertUserActivity({
                Message: 'Claim Representation',
                PageName: 'Search',
                CoachId: staffId,
                ClubId: squad ? squad.id : null,
                AgencyId: isFreeAgent ? agencyId : null,
            }));

            currentCoach = {
                ...currentCoach,
                agencyCoachDisplayInfo: {
                    ...currentCoach.agencyCoachDisplayInfo,
                    displayStatus: DisplayCoachStatus.ButtonGetCoachVerified,
                    status: CoachStatus.ClaimedNotVerified
                }
            };

            let newResult = {
                ...subState.result,
                items: subState.result.items.map((item) => {
                    if (item.staffId == staffId) {
                        return { ...currentCoach }
                    }
                    return item;
                })
            }

            dispatch(stateController.setState({
                result: newResult,
                processingItems: [...subState.processingItems.filter(x => x != staffId)]
            }));
        }
    }

    public static getCoachVerified(staffId: number) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = Selectors.getRoot(state);

            let coaches = Selectors.getCoaches(state);
            let currentCoach = coaches.find(x => x.staffId === staffId);
            const { isFreeAgent, squad, agencyCoachDisplayInfo } = currentCoach as SearchCoachItemForClub;
            const agencyId = agencyCoachDisplayInfo?.representedBy?.id;

            dispatch(insertUserActivity({
                Message: 'Verify Head Coach',
                PageName: 'Search',
                CoachId: staffId,
                ClubId: squad ? squad.id : null,
                AgencyId: isFreeAgent ? agencyId : null,
            }));

            let url = window.origin + `/coach-verification?coach=${staffId}`;
            window.open(url, '_blank').focus();
        }
    }

    public static onOpenCoachProfile(staffId: number) {
        return (dispatch, getState: () => AppState) => {
            const coach = Actions.getCoach(getState(), staffId);
            const { isFreeAgent, squad, agencyCoachDisplayInfo } = coach;
            const agencyId = agencyCoachDisplayInfo?.representedBy?.id;

            dispatch(userActivityInsert({
                PageName: 'Search',
                Message: 'Opened Coach Profile',
                PageType: PageType.Search,
                CoachId: staffId,
                ClubId: squad ? squad.id : null,
                AgencyId: isFreeAgent ? agencyId : null,
            }));

            window.open('/coach-profile/' + staffId, '_blank');
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): GridState => state.agencySearch.grid;
    public static getCoaches = (state: AppState) => Selectors.getRoot(state).result.items.map(x => x as SearchCoachItemForClub);
    public static getPlayers = (state: AppState) => Selectors.getRoot(state).result.items.map(x => x as SearchPlayerItemForAgency);
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).resultLoading || Selectors.getRoot(state).result == null;
    public static getGridState = (state: AppState) => Selectors.getRoot(state).gridState;
    public static getCurrentPage = (state: AppState) => (Selectors.getRoot(state).result || {}).currentPage || 1;
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static getTotalRowCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalResultCount;
    public static getTotalPageCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalPageCount;
    public static isInShortList = (state: AppState) => Selectors.getGridState(state) == GridToggleState.ShortList;
    public static getProcessingItems = (state: AppState) => Selectors.getRoot(state).processingItems;

    public static getPlayerRequest = (state: AppState) => {
        const subState = state.agencySearch;
        const structure = subState.filter.structure;
        const pageNumber = Selectors.getCurrentPage(state);

        // const getRange = (criteria, structure) => {
        //     if (!criteria) return null;
        //     if (criteria.max === null && criteria.min === null) return null;
        //
        //     const value = { ...criteria };
        //     if (value.max === structure.max) { value.max = null; }
        //     if (value.min === structure.min) { value.min = null; }
        //
        //     return value;
        // }

        const contractExpiry = subState.filter.playerAttributesFilter.filterData.lessThanTwelveMonth
            ? 12
            : subState.filter.playerAttributesFilter.filterData.lessThanSixMonth
                ? 6
                : subState.filter.playerAttributesFilter.filterData.contractExpiryMonthesMax;

        const getLeaguesList = () => {
            let currentList = [];
            if (subState.filter.playerAttributesFilter.filterData.isCurrentLeague &&
                !subState.filter.playerAttributesFilter.filterData.isCurrentAllLeagues) {
                currentList.push(subState.filter.currentLeague.id);
            }
            if (subState.filter.playerAttributesFilter.filterData.isCurrentAllLeagues) {
                currentList.push(...subState.filter.currentCountry.competitions.map(i => i.id));
            }
            const uniqueList = new Set([...currentList,
                ...subState.filter.playerAttributesFilter.filterData.leaguesList.map(i => i),
                ...subState.filter.playerAttributesFilter.filterData.currentLeagueList.filter(item => item.checked).map(item => item.competitionIds).flat()]);

            return Array.from(uniqueList);
        }

        const getPreviousLeaguesList = () => {
            let previousList = [];
            if (subState.filter.playerAttributesFilter.filterData.isPreviousLeague &&
                !subState.filter.playerAttributesFilter.filterData.isPreviousAllLeagues) {
                previousList.push(subState.filter.currentLeague.id);
            }
            if (subState.filter.playerAttributesFilter.filterData.isPreviousAllLeagues) {
                previousList.push(...subState.filter.currentCountry.competitions.map(i => i.id));
            }
            const uniqueList = new Set([
                ...previousList,
                ...subState.filter.playerAttributesFilter.filterData.previousLeaguesList.map(i => i),
                ...subState.filter.playerAttributesFilter.filterData.previousLeagueList.filter(item => item.checked).map(item => item.competitionIds).flat()]);

            return Array.from(uniqueList)
        }


        const getNationalityList = () => {
            let currentList = [];
            if (subState.filter.playerAttributesFilter.filterData.isCurrentNationality) {
                currentList.push(subState.filter.currentCountry.id);
            }

            return [
                ...currentList,
                ...subState.filter.playerAttributesFilter.filterData.nationalityList.map(i => i)
            ];
        }

        const getRating = () => {
            var ratingFilter = subState.filter.playerAttributesFilter.filterData.rating;
            if (ratingFilter.min == structure.rating.min && ratingFilter.max == structure.rating.max)
                return null;
            else
                return {
                    min: ratingFilter.min !== null ? Number(ratingFilter.min) : ratingFilter.min,
                    max: ratingFilter.max !== null ? Number(ratingFilter.max) : ratingFilter.max,
                };
        }

        const getMarketValue = () => {
            var marketValue = subState.filter.xtvFilter.filterData.marketValue;
            if (marketValue.min == structure.marketValue.min && marketValue.max == structure.marketValue.max)
                return null;
            else
                return {
                    min: marketValue.min,
                    max: marketValue.max
                };
        }

        let req: SearchRequest = {
            playerFilter: {
                age: {
                    min: subState.filter.playerAttributesFilter.filterData.age.min,
                    max: subState.filter.playerAttributesFilter.filterData.age.max
                },
                gbePass: subState.filter.playerAttributesFilter.filterData.gbePass,
                minHeight: subState.filter.playerAttributesFilter.filterData.isAboveSixFt ? 182 : null,
                rating: getRating(),
                contractExpiryMonthesMax: contractExpiry,
                leaguesList: getLeaguesList(),
                previousLeaguesList: getPreviousLeaguesList(),
                nationalityList: getNationalityList(),
                position: subState.filter.positionFilter,
                showOnlyFreeAgentPlayers: subState.filter.playerAttributesFilter.filterData.showOnlyFreeAgentPlayers,
                showOnlyAvailablePlayers: false,
                showOnlyPlayersWithSuspendedContract: false,
                showOnlyPlayersLookingForAgent: subState.filter.showOnlyPlayersLookingForAgent,
                showOnlyPlayersOnReleaseList: false,
                transferFee: null,
                anuallGrossSalaryYearly: null,
                loanFee: null,
                marketValue: getMarketValue(),
                minutesPlayed: structure.minutesPlayed,
            },
            shortListOnly: subState.filter.showOnlyShortListedPlayers,
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
        };

        return req;
    }

    public static getCoachRequest = (state: AppState) => {
        const subState = state.agencySearch;
        const coachAttributesFilterState = subState.filter.coachAttributesFilter;
        const structure = subState.filter.structure;
        const pageNumber = Selectors.getCurrentPage(state);

        const getLeaguesList = () => {
            let currentList = [];
            if (coachAttributesFilterState.filterData.isCurrentLeague &&
                !coachAttributesFilterState.filterData.isCurrentAllLeagues) {
                currentList.push(subState.filter.currentLeague.id);
            }
            if (coachAttributesFilterState.filterData.isCurrentAllLeagues) {
                currentList.push(...subState.filter.currentCountry.competitions.map(i => i.id));
            }


            const leaguesList = [
                ...currentList,
                ...coachAttributesFilterState.filterData.leaguesList.map(i => i),
                ...coachAttributesFilterState.filterData.leagueExperienceList.filter(item => item.checked).map(item => item.competitionIds).flat(),
            ]

            return leaguesList.length > 0 ? leaguesList : null;
        };

        const getNationalityList = () => {
            let currentList = [];
            if (coachAttributesFilterState.filterData.isCurrentNationality) {
                currentList.push(subState.filter.currentCountry.id);
            }
            const nationalityList = [
                ...currentList,
                ...coachAttributesFilterState.filterData.nationalityList.map(i => i)
            ];

            return nationalityList.length > 0 ? nationalityList : null;
        }

        const getMinExperience = () => {
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverTenYears)) {
                return 120; // 10 * 12
            }
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverEightYears)) {
                return 96; // 8 * 12
            }
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverFiveYears)) {
                return 60; // 5 * 12
            }
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverTwoYears)) {
                return 24; // 2*12
            }

            return null;
        }

        const getMaxAge = () => {
            if (coachAttributesFilterState.filterData.isUnder60) {
                return 60;
            }
            if (coachAttributesFilterState.filterData.isUnder50) {
                return 50;
            }
            if (coachAttributesFilterState.filterData.isUnder40) {
                return 40;
            }
            if (coachAttributesFilterState.filterData.isUnder35) {
                return 35;
            }

            return null;
        }

        const getRange = (criteria, structure) => {
            if (!criteria) {
                return null;
            }
            if (criteria.max === null && criteria.min === null) {
                return null;
            }
            if (JSON.stringify(criteria) === JSON.stringify(structure)) {
                return null;
            }

            return {
                min: criteria.min ? Number(criteria.min) : structure.min,
                max: criteria.max ? Number(criteria.max) : structure.max,
            };
        }

        let req: SearchCoachRequest = {
            searchCoachFilter: {
                minExperienceInMonths: getMinExperience(),
                hasPlayingCareer: coachAttributesFilterState.filterData.headCoachExperienceList.find(x=>x == HeadCoachExperienceEnum.HasPlayingCareer) != null,
                leaguesList: getLeaguesList(),
                achievementList: null,
                achievementAgencyList:
                    coachAttributesFilterState.filterData.achievementList.length > 0
                        ? coachAttributesFilterState.filterData.achievementList.map(x => x.achievementType)
                        : null,
                rating: getRange(coachAttributesFilterState.filterData.rating, structure.coachRating),
                trustInYouth: getRange(coachAttributesFilterState.filterData.trustInYouth, structure.trustInYouth),
                playingStyleList:
                    coachAttributesFilterState.filterData.playingStyleList.length > 0
                        ? coachAttributesFilterState.filterData.playingStyleList
                        : null,
                formationList:
                    coachAttributesFilterState.filterData.formationList.length > 0
                        ? coachAttributesFilterState.filterData.formationList
                        : null,
                nationalityList: getNationalityList(),
                maxAge: getMaxAge(),
                licenseTypeList:
                    coachAttributesFilterState.filterData.licenseTypeList.length > 0
                        ? coachAttributesFilterState.filterData.licenseTypeList
                        : null,
                showOnlyFreeAgentCoaches: subState.filter.showOnlyFreeAgentCoaches,
                isHighSuitability: coachAttributesFilterState.filterData.isHighSuitability
            },
            coachesSet: null,
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
        }

        return req;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    GridState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors,
};