import { StateController } from 'utils/action-declaration';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'
import {
    PlayerVerificationExtended,
    PlayerIdealClubsModel,
} from 'api/agency/agent/landing/agency-dashboard';
import { AppState } from 'root.reducer';
import { isDropboxLink, isSupportedVideoUrl, isVimeoVideoExists, isYoutubeVideoExists } from 'utils/video-links'
import { CompetitionWithActiveSquadsModel, SetStrategyRequest } from 'api/agency/agent/landing/strategy-modal-models';
import AgencyPlayerService from 'api/agency/agent/player/agent-player.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import moment from 'moment';
import { getAuth, isTransparencyPermitted } from 'store/auth/authReducer';
import { PageType } from 'constants/enums';
import { Actions as PitchAccessCriteriaModalActions } from 'pages/agency/authorized-agent/pitch-page/tabs/pitch-tab/components/pitch-access-criteria-modal/pitch-access-criteria-modal.controller';
import { Actions as RestrictedPremiumCreditsModalActions } from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-player-credits-modal/restricted-player-credits.controller';
import { RestrictedPlayerCreditsModalType } from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-player-credits-modal/restricted-player-credits.controller';
import { Actions as SharedModalActions, ModalState } from 'components/modals/shared-modal-controller';
import { Actions as RequestCreditsModalActions } from 'pages/agency/common/request-credits-modal/request-credits-modal.controller';
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import AgencyPlayerSwapService from 'api/agency/agent/landing/agency-player-swap.service';

class DashBoardState {
    isLoading: boolean
    removedPlayersList: Array<number>;
    players: Array<PlayerVerificationExtended>;
    playerVideoModalData: VideoModalData;
    promoTextModel: PromoTextModal;
    upgradePlayerModal: UpgradePlayerModalData;
    strategyModalData: StrategyModalData;
    clubsModal: StrategyClubsModalData;
    contractExpiryModal: ContractExpiryModal;
    profileStrength: number;
    priorityCreditsRemaining: number;
    totalPrioritySlots: number;
    playerCredits: number;
    priorityPlayerCreditSwaps: number;
    isAnnualCreditSwapWindowActive: boolean;
    nextSwapWindowMessage: string;
    isUnlimitedCreditSwaps: boolean;
    isUnlimitedRegularSlots: boolean;
    annualCreditSwapWindowDaysLeft: number;
    showAnnualCreditSwapBanner: boolean;
    priorityPlayersCount: number;
    totalPlayersCount: number;
    pitchAccessCriteriaArgs: PitchAccessCriteriaArgs;
    playerInvitationSent: Array<PlayerVerificationExtended>;
    creditSwapsInfoModal: CreditSwapsInfoModal;
    requestCreditsModal: RequestCreditsModalState;
    downgradePlayerModal: DowngradePlayerModalState;
    // To add a test mode for credit swap, use dateTest
    // dateTest: string;
}

class DowngradePlayerModalState {
    processing: boolean;
    isDowngraded: boolean;
    isDowngradePlayerModalOpen: boolean;
    playerId: number | null;
    playerName: string;
}

class RequestCreditsModalState {
    isRequestLoading: boolean;
    isRequestCreditsModalOpen: boolean;
    isMorePriorityPlayerCredits: boolean;
    isMorePlayerCredits: boolean;
    isRequestSent: boolean;
}

class ContractExpiryModal {
    playerId: number;
    contractExpiry: Date;
    plusYearsCount: number;
    processing: boolean;
}

class PromoTextModal {
    playerId: number;
    promoText: string;
    processing: boolean;
}

class UpgradePlayerModalData {
    isModalOpen: boolean
    playerId: number;
    playerName: string;
    processing: boolean;
    shareToggle: boolean;
}

export class StrategyModalData {
    playerId: number;
    title: string;
    playerStatus: PlayerStatus;
    transferCheckboxData: TransferCheckboxData;
    loanCheckboxData: LoanCheckboxData;
    isClubsModalOpen: boolean;
    isLoading: boolean;
    selectedClubIds: Array<number>;
    leaguesCount: number;
    transferError: string;
    grossError: string;
    loanError: string;
    idealClubs: PlayerIdealClubsModel[] | null;
    playerIdealClubsLastUpdated: string;
}

export class StrategyClubsModalData {
    squads: Array<CompetitionWithActiveSquadsModel>;
    recommended: CompetitionWithActiveSquadsModel;
    selectedIds: Array<number>;
    recommendedView: CompetitionWithActiveSquadsModel;
    squadsView: Array<CompetitionWithActiveSquadsModel>;
    selectedSquadsView: Array<CompetitionWithActiveSquadsModel>;
    searchString: string;
    isLoading: boolean;
}

export class CreditSwapsInfoModal {
    isCreditSwapsInfoModalOpen: boolean;
}
export class TransferCheckboxData {
    transferFee: FromTo;
    grossSalary: FromTo;
}

export class LoanCheckboxData {
    budget: FromTo;
}

export class FromTo {
    from: number;
    to: number;
}

export enum PlayerStatus {
    Remain = 1,
    Transfer = 2
}

export class VideoModalData {
    playerId: number;
    modalTitle: string;
    videos: Array<string>;
    url: string;
}

export class PitchAccessCriteriaArgs {
    adIds: number[];
    playerIds: number[];
}

const defaultState: DashBoardState = {
    players: [],
    isLoading: false,
    playerVideoModalData: null,
    promoTextModel: null,
    strategyModalData: null,
    clubsModal: null,
    contractExpiryModal: null,
    removedPlayersList: [],
    profileStrength: null,
    priorityCreditsRemaining: null,
    totalPrioritySlots: null,
    playerCredits: null,
    priorityPlayerCreditSwaps: null,
    isAnnualCreditSwapWindowActive: false,
    nextSwapWindowMessage: '',
    isUnlimitedCreditSwaps: false,
    isUnlimitedRegularSlots: false,
    annualCreditSwapWindowDaysLeft: null,
    showAnnualCreditSwapBanner: null,
    priorityPlayersCount: null,
    totalPlayersCount: null,
    pitchAccessCriteriaArgs: null,
    playerInvitationSent: [],
    creditSwapsInfoModal: { isCreditSwapsInfoModalOpen: false, },
    requestCreditsModal: {
        isRequestLoading: false,
        isRequestCreditsModalOpen: false,
        isMorePriorityPlayerCredits: true,
        isMorePlayerCredits: true,
        isRequestSent: false,
    },
    downgradePlayerModal: {
        processing: false,
        isDowngraded: false,
        isDowngradePlayerModalOpen: false,
        playerId: null,
        playerName: '',
    },
    upgradePlayerModal: {
        isModalOpen: false,
        playerId: null,
        playerName: '',
        processing: false,
        shareToggle: false,
    },
    // To add a test mode for credit swap, use dateTest
    // dateTest: new Date().toISOString(),
}

const stateController = new StateController<DashBoardState>(
    "AGENCY/LANDING_PAGE/PLAYERS",
    defaultState
);

class StrategyModalActions {
    public static openStrategyModal(data: PlayerVerificationExtended, pitchAccessCriteriaArgs: PitchAccessCriteriaArgs) {
        return async (dispatch) => {

            let request: StrategyModalData;
            if (data.strategy && (data.strategy.plan.isPlanningLoan || data.strategy.plan.isPlanningTransfer)) {
                const strategyPlan = data.strategy.plan;
                request = {
                    playerId: data.playerId,
                    title: `STRATEGY FOR NEXT WINDOW - ${data.playerName}`,
                    playerStatus: PlayerStatus.Transfer,
                    loanCheckboxData: strategyPlan.isPlanningLoan ?
                        {
                            budget: {
                                from: strategyPlan.loanBudgetFrom,
                                to: strategyPlan.loanBudgetTo
                            }
                        }
                        : null,
                    transferCheckboxData: strategyPlan.isPlanningTransfer ?
                        {
                            transferFee: {
                                from: strategyPlan.transferFeeFrom,
                                to: strategyPlan.transferFeeTo
                            },
                            grossSalary: {
                                from: strategyPlan.grossSalaryFrom,
                                to: strategyPlan.grossSalaryTo
                            }
                        } :
                        null,
                    isClubsModalOpen: false,
                    isLoading: false,
                    selectedClubIds: strategyPlan.strategySquadSet,
                    leaguesCount: data.strategy.leaguesCount,
                    grossError: null,
                    loanError: null,
                    transferError: null,
                    idealClubs: data.playerIdealClubs,
                    playerIdealClubsLastUpdated: data.playerIdealClubsLastUpdated,
                }
            } else {
                request = {
                    playerId: data.playerId,
                    title: `STRATEGY FOR NEXT WINDOW - ${data.playerName}`,
                    playerStatus: PlayerStatus.Remain,
                    loanCheckboxData: null,
                    transferCheckboxData: null,
                    isClubsModalOpen: false,
                    isLoading: false,
                    selectedClubIds: [],
                    leaguesCount: 0,
                    grossError: null,
                    loanError: null,
                    transferError: null,
                    playerIdealClubsLastUpdated: data.playerIdealClubsLastUpdated,
                    idealClubs: data.playerIdealClubs,
                }
            }

            dispatch(stateController.setState({
                strategyModalData: request,
                pitchAccessCriteriaArgs: pitchAccessCriteriaArgs
            }))

            dispatch(StrategyModalActions.trackActivity('Opened'))
        }
    }
    public static closeStrategyModal() {
        return async (dispatch) => {
            dispatch(stateController.setState({ strategyModalData: null }))
        }
    }

    public static cancelStrategyModal() {
        return (dispatch) => {
            dispatch(StrategyModalActions.trackActivity('Cancelled'))

            dispatch(StrategyModalActions.closeStrategyModal());
        }
    }
    public static idealClubsUA() {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            let player = state.players.find(x => x.playerId == state.strategyModalData.playerId);
            
            dispatch(userActivityInsert({
                PageName: 'Agency Players [Strategy]',
                Message: 'Clicked Ideal Clubs Hint Icon',
                PageType: PageType.AgencyPlayers,
                PlayerId: player.playerId,
                ClubId: player.squadId,
            }))
        }
    }

    public static openStrategyClubsModal() {
        return async (dispatch) => {
            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, isClubsModalOpen: true } })))

            dispatch(StrategyModalActions.trackActivity('Opened leagues'))
        }
    }

    public static loadSquadsStrategyClubsModal() {
        return async (dispatch, getState: () => AppState) => {
            let state = Selectors.getRoot(getState());

            if (!((state.clubsModal || {}).squads)) {
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, isLoading: true } }));
                const data = await AgencyHomePageService.activeSquadsFetch(state.strategyModalData.playerId);

                const selectedIds = state.strategyModalData.selectedClubIds;
                const view = data.competitionWithActiveSquads.map(x => { x.isCollapsed = true; return x; })
                const recommended: CompetitionWithActiveSquadsModel = data.recommenders.length > 0 ? {
                    id: -1, areaId: -1, areaName: "areaName", nameWithArea: "Recommended", divisionLevel: -1, isCollapsed: true, name: "name",
                    squads: data.recommenders
                } : null
                await dispatch(stateController.setState({
                    clubsModal: {
                        isLoading: true,
                        squads: data.competitionWithActiveSquads,
                        selectedIds: selectedIds,
                        squadsView: view,
                        searchString: "",
                        selectedSquadsView: [],
                        recommended: recommended,
                        recommendedView: recommended
                    }
                }));
                await dispatch(this.selectSquads([], false));
            }
        }
    }

    public static toggleCollapseSelected(competitionId: number, isCollapsed: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            const tree = [...state.clubsModal.selectedSquadsView];
            const find = tree.find(x => x.id == competitionId);
            if (find) {
                find.isCollapsed = isCollapsed
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, selectedSquadsView: tree } }));
            }
        }
    }

    public static toggleCollapseUnselected(competitionId: number, isCollapsed: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            const tree = [...state.clubsModal.squadsView];
            const find = tree.find(x => x.id == competitionId);
            if (find) {
                find.isCollapsed = isCollapsed
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, squadsView: tree } }));
            }
        }
    }

    public static toggleCollapseRecommended(isCollapsed: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            const find = { ...state.clubsModal.recommendedView };
            if (find) {
                find.isCollapsed = isCollapsed
                dispatch(stateController.setState({ ...state, clubsModal: { ...state.clubsModal, recommendedView: find } }));
            }
        }
    }

    public static saveSelectedSquads() {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            const uniqueLeagues = [];
            const uniqueClubNames = [];
            const uniqueLeaguesRecommendedNames = [];
            state.clubsModal.squads.map(x => {
                x.squads.map(y => {
                    if (state.clubsModal.selectedIds.some(i => i == y.id)) {
                        let find = uniqueLeagues.find(k => k == x.id);
                        if (!find) {
                            uniqueLeagues.push(x.id);
                        }

                        if (state.clubsModal.recommended && state.clubsModal.recommended.squads.some(i => i.id == y.id)) {
                            uniqueLeaguesRecommendedNames.push(y.name)
                        } else {
                            uniqueClubNames.push(y.name)
                        }
                    }
                });
            });

            let trackSTr = ''

            if (uniqueClubNames[0]) {
                trackSTr = `Recipients: ${uniqueClubNames.join(',')}`
            }

            if (uniqueLeaguesRecommendedNames[0]) {
                trackSTr = `${trackSTr ? ', ' : ''} Recipients: ${uniqueLeaguesRecommendedNames.join(',')}`
            }

            if (trackSTr) {

                dispatch(StrategyModalActions.trackActivity(`Selected Leagues: ${trackSTr}`))
            }

            dispatch(stateController.setState({
                ...state, clubsModal: null, strategyModalData: {
                    ...state.strategyModalData, selectedClubIds: state.clubsModal.selectedIds, isClubsModalOpen: false, leaguesCount: uniqueLeagues.length
                }
            }));
        }
    }

    public static setSearchString(search: string) {
        return async (dispatch) => {
            dispatch(stateController.setState((state) => ({ ...state, clubsModal: { ...state.clubsModal, searchString: search } })));
        }
    }

    public static removeAll() {
        return async (dispatch) => {
            dispatch(stateController.setState((state) => ({
                ...state, clubsModal: {
                    ...state.clubsModal,
                    squadsView: state.clubsModal.squads, selectedSquadsView: [], selectedIds: [], recommendedView: state.clubsModal.recommended
                }
            })));

            dispatch(StrategyModalActions.trackActivity('Cleared Leagues'))
        }
    }

    public static selectSquads(squadIds: Array<number>, isForRemove: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            let selectedIds;
            if (isForRemove) {
                selectedIds = state.clubsModal.selectedIds.filter(x => !squadIds.some(y => y == x));
            }
            else {
                selectedIds = [...state.clubsModal.selectedIds, ...squadIds];
            }
            const newSquadView: CompetitionWithActiveSquadsModel[] = [];
            const newSelectedSquadView: CompetitionWithActiveSquadsModel[] = [];

            const x = state.clubsModal.recommended;
            let newRecommendedView: CompetitionWithActiveSquadsModel;
            if (x) {
                newRecommendedView = {
                    areaId: x.areaId, areaName: x.areaName, divisionLevel: x.divisionLevel, id: x.id,
                    name: x.name, nameWithArea: x.nameWithArea, squads: [], isCollapsed: false
                }

                state.clubsModal.recommended.squads.map(y => {
                    if (!selectedIds.some(k => k == y.id)) {
                        newRecommendedView.squads.push(y);
                    }
                });
            }
            else {
                newRecommendedView = null;
            }
            state.clubsModal.squads.map(x => {
                x.squads.map(y => {
                    if (selectedIds.some(k => k == y.id)) {
                        let find = newSelectedSquadView.find(i => i.id == x.id);
                        if (!find) {
                            newSelectedSquadView.push({
                                areaId: x.areaId, areaName: x.areaName, divisionLevel: x.divisionLevel, id: x.id,
                                name: x.name, nameWithArea: x.nameWithArea, squads: [y], isCollapsed: false
                            });
                        }
                        else {
                            find.squads.push(y);
                        }
                    }
                });
            });
            state.clubsModal.squads.map(x => {
                x.squads.map(y => {
                    if (!selectedIds.some(k => k == y.id)) {
                        let find = newSquadView.find(i => i.id == x.id);
                        let findLeague = state.clubsModal.squadsView.find(i => i.id == x.id);
                        if (!find) {
                            newSquadView.push({
                                areaId: x.areaId, areaName: x.areaName, divisionLevel: x.divisionLevel, id: x.id,
                                name: x.name, nameWithArea: x.nameWithArea, squads: [y], isCollapsed: findLeague ? findLeague.isCollapsed : x.isCollapsed
                            });
                        }
                        else {
                            find.squads.push(y);
                        }
                    }
                });
            });

            dispatch(stateController.setState({
                ...state, clubsModal: {
                    ...state.clubsModal,
                    squadsView: newSquadView, selectedSquadsView: newSelectedSquadView, selectedIds: selectedIds, recommendedView: newRecommendedView, isLoading: false
                }
            }));
        }
    }


    public static closeStrategyClubsModal() {
        return async (dispatch) => {
            dispatch(StrategyModalActions.trackActivity('Cancelled Leagues'))

            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, isClubsModalOpen: false }, clubsModal: null })))
        }
    }

    public static changePlayerStatus(status: PlayerStatus) {
        return async (dispatch) => {
            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, playerStatus: status } })))
        }
    }

    public static changeTransferCheckboxData(data: TransferCheckboxData) {
        return async (dispatch) => {
            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, transferCheckboxData: data } })))
        }
    }

    public static changeLoanCheckboxData(data: LoanCheckboxData) {
        return async (dispatch) => {
            dispatch(stateController.setState((state) => ({ ...state, strategyModalData: { ...state.strategyModalData, loanCheckboxData: data } })))
        }
    }

    public static saveChangesStrategyModal() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());

            const errorText = "Value 'to' should be greater then 'from'";
            const errorText2 = "Value 'to' should not be empty";
            const transferError = subState.strategyModalData.transferCheckboxData &&
                subState.strategyModalData.transferCheckboxData.transferFee &&
                subState.strategyModalData.transferCheckboxData.transferFee.from &&
                subState.strategyModalData.transferCheckboxData.transferFee.to &&
                subState.strategyModalData.transferCheckboxData.transferFee.from > subState.strategyModalData.transferCheckboxData.transferFee.to;
            const transferError2 = subState.strategyModalData.transferCheckboxData &&
                subState.strategyModalData.transferCheckboxData.transferFee &&
                subState.strategyModalData.transferCheckboxData.transferFee.from &&
                !subState.strategyModalData.transferCheckboxData.transferFee.to;
            const grossError = subState.strategyModalData.transferCheckboxData &&
                subState.strategyModalData.transferCheckboxData.grossSalary &&
                subState.strategyModalData.transferCheckboxData.grossSalary.from &&
                subState.strategyModalData.transferCheckboxData.grossSalary.to &&
                subState.strategyModalData.transferCheckboxData.grossSalary.from > subState.strategyModalData.transferCheckboxData.grossSalary.to;
            const grossError2 = subState.strategyModalData.transferCheckboxData &&
                subState.strategyModalData.transferCheckboxData.grossSalary &&
                subState.strategyModalData.transferCheckboxData.grossSalary.from &&
                !subState.strategyModalData.transferCheckboxData.grossSalary.to
            const loanError = subState.strategyModalData.loanCheckboxData &&
                subState.strategyModalData.loanCheckboxData.budget &&
                subState.strategyModalData.loanCheckboxData.budget.from &&
                subState.strategyModalData.loanCheckboxData.budget.to &&
                subState.strategyModalData.loanCheckboxData.budget.from > subState.strategyModalData.loanCheckboxData.budget.to;
            const loanError2 = subState.strategyModalData.loanCheckboxData &&
                subState.strategyModalData.loanCheckboxData.budget &&
                subState.strategyModalData.loanCheckboxData.budget.from &&
                !subState.strategyModalData.loanCheckboxData.budget.to

            if (transferError || grossError || loanError || transferError2 || grossError2 || loanError2) {
                dispatch(stateController.setState({
                    ...subState, strategyModalData: {
                        ...subState.strategyModalData,
                        loanError: loanError ? errorText : loanError2 ? errorText2 : null,
                        grossError: grossError ? errorText : grossError2 ? errorText2 : null,
                        transferError: transferError ? errorText : transferError2 ? errorText2 : null,
                    }
                }))
            }
            else {
                dispatch(stateController.setState({ ...subState, strategyModalData: { ...subState.strategyModalData, isLoading: true } }))
                const request: SetStrategyRequest = {
                    isAnyPlan: subState.strategyModalData.playerStatus == PlayerStatus.Transfer,
                    plan: {
                        isPlanningTransfer: subState.strategyModalData.transferCheckboxData != null,
                        isPlanningLoan: subState.strategyModalData.loanCheckboxData != null,
                        playerId: subState.strategyModalData.playerId,
                        strategySquadSet: subState.strategyModalData.selectedClubIds,
                        grossSalaryFrom: ((subState.strategyModalData.transferCheckboxData || {}).grossSalary || {}).from,
                        grossSalaryTo: ((subState.strategyModalData.transferCheckboxData || {}).grossSalary || {}).to,
                        loanBudgetFrom: ((subState.strategyModalData.loanCheckboxData || {}).budget || {}).from,
                        loanBudgetTo: ((subState.strategyModalData.loanCheckboxData || {}).budget || {}).to,
                        transferFeeFrom: ((subState.strategyModalData.transferCheckboxData || {}).transferFee || {}).from,
                        transferFeeTo: ((subState.strategyModalData.transferCheckboxData || {}).transferFee || {}).to
                    }
                }
                try {

                    await AgencyHomePageService.setStrategy(request);

                    const currency = getAuth(getState()).currency.name;
                    const transferFee = subState.strategyModalData.transferCheckboxData?.transferFee;
                    const grossSalary = subState.strategyModalData.transferCheckboxData?.grossSalary;
                    const budget = subState.strategyModalData.loanCheckboxData?.budget;
                    let trackStr = ''
                    if (request.isAnyPlan && request.plan.isPlanningTransfer) {
                        trackStr += `Transfer ${currency} ${transferFee?.to}; ${grossSalary?.to}/yr`;
                        dispatch(StrategyModalActions.trackActivity(`Expected Transfer Fee: ${transferFee?.from}-${transferFee?.to} ${currency}`));
                        dispatch(StrategyModalActions.trackActivity(`Expected Annual Gross Salary: ${grossSalary?.from}-${grossSalary?.to} ${currency}`));
                    }

                    if (request.isAnyPlan && request.plan.isPlanningLoan) {
                        trackStr += `${trackStr ? ', ' : ''}Loan ${currency} ${budget?.to}/mo`;
                        dispatch(StrategyModalActions.trackActivity(`Monthly Loan Budget: ${budget?.from}-${budget?.to} ${currency}`));
                    }

                    if (!request.isAnyPlan) {
                        trackStr += `Stay`
                    }

                    if (trackStr) {
                        dispatch(StrategyModalActions.trackActivity(`Saved. ${trackStr}`))
                    }

                    dispatch(stateController.setState({
                        strategyModalData: null
                    }))
                    dispatch(Actions.load());

                    if (subState.pitchAccessCriteriaArgs) {
                        dispatch(PitchAccessCriteriaModalActions.load(subState.pitchAccessCriteriaArgs.adIds, subState.pitchAccessCriteriaArgs.playerIds));
                    }
                } catch (e) {
                    console.error(e)
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        strategyModalData: {
                            ...prevState.strategyModalData,
                            isLoading: false
                        }
                    })))
                    dispatch({ type: '--ERROR' })
                }
            }
        }
    }

    public static trackActivity(message: string) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            let player = state.players.find(x => x.playerId == state.strategyModalData.playerId);

            if (state.pitchAccessCriteriaArgs) {
                const pitchAccessCriterias = getState().agency.agent.pitchAccessCriteriaModal.pitchAccessCriterias;
                const pitchAccessCriteria = pitchAccessCriterias.find(x => x.playerId === state.strategyModalData.playerId);
                player = pitchAccessCriteria.player;
            }

            dispatch(userActivityInsert({
                PageName: 'Agency Players [Strategy]',
                Message: message,
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyPlayers
            }))
        }
    }

}

class Actions {

    // To add a test mode for credit swap, use dateTest
    // public static selectDate(value: string, isPlayerPage: boolean | undefined  = false) {
    //     return (dispatch, getState: () => AppState) => {
    //
    //         dispatch(stateController.setState((state) => ({ ...state, dateTest: value })))
    //
    //         if (!isPlayerPage) {
    //             dispatch(Actions.load());
    //         }
    //     }
    // }

    public static trackVideoActivity(message: string) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            const player = state.players.find(x => x.playerId == state.playerVideoModalData.playerId);

            dispatch(userActivityInsert({
                PageName: 'Agency Players [Promo Video]',
                Message: message,
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyPlayers
            }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static openVideoModal(data: VideoModalData) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ playerVideoModalData: data }))
            dispatch(Actions.trackVideoActivity('Opened'))
        }
    }
    public static closeVideoModal() {
        return async (dispatch) => {
            dispatch(stateController.setState({ playerVideoModalData: null }))
        }
    }

    public static cancelVideoModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.trackVideoActivity('Cancelled'))
            dispatch(Actions.closeVideoModal());
        }
    }

    public static saveChanges() {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            try {

                const subState = state.playerVideoModalData;

                dispatch(stateController.setState({ isLoading: true }))
                await AgencyHomePageService.saveVideos({ playerId: subState.playerId, urls: subState.videos });
                dispatch(Actions.trackVideoActivity('Saved Changes'))

                dispatch(stateController.setState({ isLoading: false, playerVideoModalData: null }))
                dispatch(Actions.load());
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState(prevState => ({ ...prevState, isLoading: false })))
                dispatch({ type: '--ERROR' })
            }
        }
    }

    public static onUrlChange(newUrl: string) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            const subState = state.playerVideoModalData;
            if (await this.isUrlValid(newUrl)) {
                if (subState.videos.indexOf(newUrl) < 0) {
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        playerVideoModalData: {
                            ...prevState.playerVideoModalData,
                            videos: [...prevState.playerVideoModalData.videos, newUrl],
                            url: ''
                        }
                    })))

                    dispatch(Actions.trackVideoActivity('Added Video'))
                }
            }
        }
    }

    public static removePlayer(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());

            try {
                dispatch(stateController.setState({ removedPlayersList: [...subState.removedPlayersList, id] }))

                // To add a test mode for credit swap, use dateTest
                // const dateTest = getState().agency.landingPage.homePagePlayers.dateTest;
                await AgencyHomePageService.removePlayer(id);
                const deletedPlayer = subState.players.find(i => i.playerId == id);
                const isRegularPlayer = deletedPlayer.isRegular

                dispatch(stateController.setState(
                    {
                        players: subState.players.filter(a => a.playerId !== id),
                        removedPlayersList: [...subState.removedPlayersList.filter(x => x != id)],
                        totalPrioritySlots: isRegularPlayer ? subState.totalPrioritySlots : (subState.totalPrioritySlots - 1),
                        playerCredits: (isRegularPlayer || (subState.isAnnualCreditSwapWindowActive && !isRegularPlayer)) ? subState.playerCredits + 1 :  subState.playerCredits,
                        priorityCreditsRemaining: subState.isAnnualCreditSwapWindowActive && !isRegularPlayer ? subState.priorityCreditsRemaining + 1 : subState.priorityCreditsRemaining,
                    }
                ))

                if (deletedPlayer) {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Players [Removal]',
                        Message: 'Removed a Player',
                        PlayerId: id,
                        ClubId: deletedPlayer.squadId,
                        PageType: PageType.AgencyPlayers
                    }))
                }
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState({
                    removedPlayersList: [...subState.removedPlayersList.filter(x => x != id)]
                }))
                dispatch({ type: '--ERROR' })
            }
        }
    }

    public static removeVideo(url: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((state) => ({ ...state, playerVideoModalData: { ...state.playerVideoModalData, videos: state.playerVideoModalData.videos.filter(v => v !== url) } })));
            dispatch(Actions.trackVideoActivity('Removed Video'))
        }
    }

    private static async isUrlValid(url: string) {
        if (!isDropboxLink(url) && isSupportedVideoUrl(url)) {
            if (url.includes('youtu') && url.includes('shorts')) {
                const pattern = /shorts\/([a-zA-Z0-9_-]+)/;
                const match = url.match(pattern);
                const videoId = match[1];
                return await isYoutubeVideoExists(videoId);
            }
            if (url.includes('youtu')) {
                const videoId = RegExp.$6;
                return await isYoutubeVideoExists(videoId);
            }
            return await isVimeoVideoExists(url);
        } else {
            return false
        }
    };

    public static load() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                // To add a test mode for credit swap, use dateTest
                // const dateTest = getState().agency.landingPage.homePagePlayers.dateTest;
                const players = await AgencyHomePageService.getPlayers();
                dispatch(stateController.setState({
                    players: players.players,
                    profileStrength: players.networkStrength,
                    priorityCreditsRemaining: players.priorityCreditsRemaining,
                    totalPrioritySlots: players.totalPrioritySlots,
                    playerCredits: players.playerCredits,
                    priorityPlayerCreditSwaps: players.priorityPlayerCreditSwaps,
                    annualCreditSwapWindowDaysLeft: players.annualCreditSwapWindowDaysLeft,
                    isAnnualCreditSwapWindowActive: players.isAnnualCreditSwapWindowActive,
                    nextSwapWindowMessage: players.nextSwapWindowMessage,
                    isUnlimitedCreditSwaps: players.isUnlimitedCreditSwaps,
                    isUnlimitedRegularSlots: players.isUnlimitedRegularSlots,
                    showAnnualCreditSwapBanner: players.showAnnualCreditSwapBanner,
                    priorityPlayersCount: players.priorityPlayersCount,
                    totalPlayersCount: players.totalPlayersCount,
                    playerInvitationSent: players.playerInvitationsSent
                }))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static init() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));
            dispatch(Actions.load());

            dispatch(userActivityInsert({
                PageName: 'Agency Players',
                Message: 'Opened',
                PageType: PageType.AgencyPlayers
            }))
        }
    }

    public static openPlayerProfile(playerId: number, squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            window.open(`/profile/${playerId}`, '_blank')
            dispatch(userActivityInsert({
                PageName: 'Agency Players',
                Message: 'Opened Player Profile',
                PlayerId: playerId,
                ClubId: squadId
            }));
        }
    }

    //____________ Credit Swaps Info Modal_____________________

    public static openCreditSwapsInfoModal() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                creditSwapsInfoModal: {
                    isCreditSwapsInfoModalOpen: true,
                },
            })));

            dispatch(userActivityInsert({
                Message: `Priority Player Credit Swaps Info Clicked`,
                PageName: Selectors.getPlayerSwapPageName(appState, 'Priority Player Credit Swaps'),
                PageType: PageType.AgencyPlayers
            }));

            dispatch(userActivityInsert({
                Message: `Viewed Priority Player Credit Swaps Info`,
                PageName: Selectors.getPlayerSwapPageName(appState, 'Priority Player Credit Swaps'),
                PageType: PageType.AgencyPlayers
            }));
        }
    }

    public static closeCreditSwapsInfoModal() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                creditSwapsInfoModal: {
                    isCreditSwapsInfoModalOpen: false,
                },
            })));

            dispatch(userActivityInsert({
                Message: `Clicked Close`,
                PageName: Selectors.getPlayerSwapPageName(appState, 'Priority Player Credit Swaps'),
                PageType: PageType.AgencyPlayers
            }));
        }
    }

    //____________ Request Credits Modal_____________________

    public static openRequestCreditsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(RequestCreditsModalActions.openModal('Agency Players [Request Credits]', PageType.AgencyPlayers))
        }
    }

    public static swapOutPlayer(playerId: number) {
        return (dispatch) => {
            historyAccessor.push(agencyPaths.toSwapPlayer(playerId));
        }
    }


    public static openDowngradePlayerModal(playerId: number, playerName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                downgradePlayerModal: {
                    playerId,
                    playerName,
                    isDowngradePlayerModalOpen: true,
                    isDowngraded: false,
                },
            })));

            dispatch(userActivityInsert({
                Message: `Viewed Downgrade Player Info`,
                PageName: `Agency Players [Downgrade Player]`,
                PlayerId: playerId,
            }));
        }
    }

    public static closeDowngradePlayerModal() {
        return (dispatch) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                downgradePlayerModal: {
                    playerId: null,
                    playerName: '',
                    isDowngradePlayerModalOpen: false,
                },
            })));
        }
    }

    public static onCancelDowngradePlayer() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const playerId = subState.downgradePlayerModal.playerId;
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                downgradePlayerModal: {
                    playerId: null,
                    playerName: '',
                    isDowngradePlayerModalOpen: false,
                },
            })));

            dispatch(userActivityInsert({
                Message: `Clicked Cancel`,
                PageName: `Agency Players [Downgrade Player]`,
                PlayerId: playerId,
            }));
        }
    }

    public static moveToPlayerSwap(playerId: number) {
        return (dispatch) => {
            dispatch(stateController.setState({
                downgradePlayerModal: defaultState.downgradePlayerModal
            }));

            dispatch(userActivityInsert({
                Message: `Clicked Swap Credits`,
                PageName: `Agency Players [Downgrade Player]`,
                PlayerId: playerId,
            }));

            historyAccessor.push(agencyPaths.toSwapPlayer(playerId));
        }
    }

    public static onDowngradePlayer(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    downgradePlayerModal: {
                        ...prevState.downgradePlayerModal,
                        processing: true
                    }
                })));

                dispatch(userActivityInsert({
                    Message: `Clicked Downgrade Player`,
                    PageName: `Agency Players [Downgrade Player]`,
                    PlayerId: playerId,
                }));

                // To add a test mode for credit swap, use dateTest
                // const dateTest = getState().agency.landingPage.homePagePlayers.dateTest;
                await AgencyHomePageService.downgradePlayer(playerId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    downgradePlayerModal: {
                        ...prevState.downgradePlayerModal,
                        isDowngraded: true,
                        isDowngradePlayerModalOpen: false,
                        // playerId: null,
                        playerName: ''
                    }
                })));

                dispatch(userActivityInsert({
                    Message: `Player Downgrade Successful`,
                    PageName: `Agency Players [Downgrade Player]`,
                    PlayerId: playerId,
                }));

                historyAccessor.push(agencyPaths.toSwapPlayer());
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    downgradePlayerModal: {
                        ...prevState.downgradePlayerModal,
                        processing: false
                    }
                })));
            }
        }
    }

    public static openPriorityPlayerCreditSwaps(url: string) {
        return async (dispatch, getState: () => AppState) => {
            window.open(url, '_blank')
            dispatch(userActivityInsert({
                PageName: 'Agency Players [Swap Banner]',
                Message: 'Clicked Find Out More',
                PageType: PageType.AgencyPlayers
            }));
        }
    }

    public static dismissAnnualCreditSwapBanner() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(userActivityInsert({
                    PageName: 'Agency Players [Swap Banner]',
                    Message: 'Clicked Dismiss',
                    PageType: PageType.AgencyPlayers
                }));
                dispatch(stateController.setState({ showAnnualCreditSwapBanner: false }));
                // const dateTest = getState().agency.landingPage.homePagePlayers.dateTest;
                await AgencyPlayerSwapService.dismissAnnualCreditSwapBanner();
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static decreaseRemainingPriorityCredits() {
        return async (dispatch) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                priorityCreditsRemaining: prevState.priorityCreditsRemaining - 1,
            })));
        }
    }
}

class UpgradePlayerActions {

    public static openModal(playerId: number, squadId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Agency Players [Upgrade]',
                Message: 'Opened Upgrade Confirmation',
                PlayerId: playerId,
                ClubId: squadId,
                PageType: PageType.AgencyPlayers
            }));
            const subState = Selectors.getRoot(getState());
            const player = subState.players.find(x => x.playerId == playerId);
            if (subState.priorityCreditsRemaining > 0) {
                dispatch(stateController.setState({
                    upgradePlayerModal: {
                        isModalOpen: true,
                        playerId: playerId,
                        playerName: player.playerName,
                        processing: false,
                        shareToggle: true
                    }
                }));
            }
            else {
                dispatch(RestrictedPremiumCreditsModalActions.openRestrictedPremiumCreditsModal(RestrictedPlayerCreditsModalType.UpgradePlayer, playerId, squadId));
            }
        }
    }

    public static toggleShare() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            dispatch(stateController.setState({
                ...subState,
                upgradePlayerModal:
                {
                    ...subState.upgradePlayerModal,
                    shareToggle: !subState.upgradePlayerModal.shareToggle
                }
            }));
        }
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ upgradePlayerModal: { ...defaultState.upgradePlayerModal } }));
        }
    }

    public static openSharedInsightsInfoModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(SharedModalActions.onOpen(ModalState.UpgradePlayerToPriority));
            dispatch(userActivityInsert({
                PageName: 'Agency Players [Upgrade]',
                Message: `Opened Info about Sharing`,
                PageType: PageType.AgencyPlayers
            }));
        }
    }

    public static upgradePlayer(id: number, shared: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const transparencyPermitted = isTransparencyPermitted(getState());
            if (!transparencyPermitted)
                shared = false;

            dispatch(stateController.setState({ upgradePlayerModal: { ...subState.upgradePlayerModal, processing: true } }))

            await AgencyHomePageService.upgradePlayer(id, shared);

            const playersCopy = [...subState.players];
            const upgradedPlayer = playersCopy.find(i => i.playerId == id);
            upgradedPlayer.isRegular = false;

            dispatch(stateController.setState({
                players: playersCopy,
                upgradePlayerModal: { ...defaultState.upgradePlayerModal },
                priorityCreditsRemaining: subState.priorityCreditsRemaining - 1
            }));

            const uaMessage = transparencyPermitted ?
                `Upgraded Player to Priority; ${shared ? 'Shared Insights with Player' : 'Unshared Insights with Player'}` :
                `Upgraded Player to Priority`
            dispatch(userActivityInsert({
                PageName: 'Agency Players [Upgrade]',
                Message: uaMessage,
                PlayerId: upgradedPlayer.playerId,
                ClubId: upgradedPlayer.squadId,
                PageType: PageType.AgencyPlayers
            }))
        }
    }
}

class PromoTextModalActions {
    public static trackActivity(message: string) {
        return async (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            const player = state.players.find(x => x.playerId == state.promoTextModel.playerId);

            dispatch(userActivityInsert({
                PageName: 'Agency Players [Promo Text]',
                Message: message,
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyPlayers
            }))
        }
    }

    public static openPromoTextModal(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const player = Selectors.getRoot(getState()).players.find(x => x.playerId == playerId);

            dispatch(stateController.setState({ promoTextModel: { playerId: playerId, promoText: player.promoText || "", processing: false } }));
            dispatch(PromoTextModalActions.trackActivity('Opened'))
        }
    }

    public static cancelPromoTextModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ promoTextModel: null }));
        }
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(PromoTextModalActions.trackActivity('Cancelled'))
            dispatch(PromoTextModalActions.cancelPromoTextModal());
        }
    }

    public static setPromoTextModal(promoText: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const modalState = { ...subState.promoTextModel, promoText: promoText };

            dispatch(stateController.setState({ promoTextModel: modalState }));
        }
    }

    public static savePromoTextModal() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const subState = Selectors.getRoot(getState());
                const modalState = { ...subState.promoTextModel, processing: true };
                dispatch(stateController.setState({ promoTextModel: modalState }))

                let promoText = modalState.promoText;
                if (promoText == "") promoText = null;

                await AgencyPlayerService.savePromoText(modalState.playerId, promoText);

                const player = subState.players.find(x => x.playerId == subState.promoTextModel.playerId);
                const players = [...subState.players.map((x) => {
                    if (x.playerId == subState.promoTextModel.playerId) return { ...player, promoText: promoText }
                    return x;
                })]

                dispatch(PromoTextModalActions.trackActivity('Saved'));
                dispatch(stateController.setState({ players: players }));
                dispatch(this.cancelPromoTextModal());
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState(prevState => ({ ...prevState, promoTextModel: { ...prevState.promoTextModel, processing: false } })))
                dispatch({ type: '--ERROR' })
            }
        }
    }
}

class ContractExpiryModalActions {
    public static openContractExpiryModal = (playerId: number) => {
        return (dispatch, getState: () => AppState) => {
            const player = Selectors.getRoot(getState()).players.find(x => x.playerId == playerId);

            dispatch(stateController.setState({
                contractExpiryModal: {
                    playerId: playerId,
                    contractExpiry: player.parentClubContractExpiry,
                    plusYearsCount: player.parentClubContractExpiryPlus,
                    processing: false
                }
            }));

            dispatch(userActivityInsert({
                PageName: 'Agency Players [Contract Expiry]',
                Message: 'Opened',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyPlayers
            }))
        }
    }

    public static cancelContractExpiryModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ contractExpiryModal: null }));
        }
    }

    public static close() {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());
            const player = state.players.find(x => x.playerId == state.contractExpiryModal.playerId);
            dispatch(ContractExpiryModalActions.cancelContractExpiryModal());

            dispatch(userActivityInsert({
                PageName: 'Agency Players [Contract Expiry]',
                Message: 'Cancelled',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyPlayers
            }))
        }
    }

    public static setContractExpiry(contractExpiry: Date, plusDays?: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const modalState: ContractExpiryModal = { ...subState.contractExpiryModal, plusYearsCount: plusDays, contractExpiry: contractExpiry };

            dispatch(stateController.setState({ contractExpiryModal: modalState }));
        }
    }

    public static saveContractExpiry() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const modalState = { ...subState.contractExpiryModal, processing: true };
            dispatch(stateController.setState({ contractExpiryModal: modalState }))

            if (modalState.contractExpiry == null) {
                dispatch(this.cancelContractExpiryModal());
                return;
            }

            try {
                const player = subState.players.find(x => x.playerId == subState.contractExpiryModal.playerId);

                await AgencyPlayerService.saveContractExpiration(modalState.playerId, modalState.contractExpiry, modalState.plusYearsCount);

                dispatch(userActivityInsert({
                    PageName: 'Agency Players [Contract Expiry]',
                    Message: `Saved. ${moment(modalState.contractExpiry).format('DD.MM.YYYY')}${modalState.plusYearsCount ? ' +1' : ''}`,
                    PlayerId: player.playerId,
                    ClubId: player.squadId,
                    PageType: PageType.AgencyPlayers
                }))

                if (player.parentClubContractExpiry == modalState.contractExpiry && player.parentClubContractExpiryPlus == modalState.plusYearsCount) {
                    dispatch(this.cancelContractExpiryModal());
                    return;
                }

                const players = [...subState.players.map((x) => {
                    if (x.playerId == subState.contractExpiryModal.playerId) return { ...player, parentClubContractExpiry: modalState.contractExpiry, parentClubContractExpiryPlus: modalState.plusYearsCount }
                    return x;
                })]

                dispatch(stateController.setState({ players: players }))
                dispatch(this.cancelContractExpiryModal());
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState(prevState => ({ ...prevState, contractExpiryModal: { ...prevState.contractExpiryModal, processing: false } })))
                dispatch({ type: '--ERROR' })
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): DashBoardState => state.agency.landingPage.homePagePlayers;
    public static getPlayerSwapPageName = (state: AppState, type: string) => {
        const { isAnnualCreditSwapWindowActive } = Selectors.getRoot(state);
        return `Agency Players [${isAnnualCreditSwapWindowActive ? "Swap Window " : ""}${type}]`;
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    DashBoardState as State,
    Actions as Actions,
    stateController as Controller,
    PromoTextModalActions,
    UpgradePlayerActions,
    StrategyModalActions as StrategyModalActions,
    ContractExpiryModalActions,
    Selectors as Selectors
};
