import './polyfils';
import { lazy, Suspense } from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Router, Switch, Route } from 'react-router-dom';
import ScrollMemory from 'hooks/scroll-memory';
import history from 'history-accessor';
import 'include/font-awesome';
import 'react-day-picker/dist/style.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import './media/styles/index.lite.scss';
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import Landing from 'app/landing/landing.component';
import configureStore from './configureStore'; // TBD
import Notifications from 'app/notifications/notifications';
import hubspotTrack from 'utils/hubspot-tracking';
import { LiveChatCoordinator } from 'services/live-chat-controller';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'appInsights';

import Spinner from 'components/Spinner/Spinner';
import PrintScreenEventHandler from 'components/print-screen-event-handler';
const App = lazy(() => import('./App'));

// Get the application-wide store instance,
// prepopulating with state from the server where available.
const initialState = window.initialReduxState;
export const store = configureStore(initialState);
export const { dispatch } = store;

const rootElement = ReactDOM.createRoot(document.getElementById('root'));

history.listen((location) => {
    reactPlugin.trackPageView({ uri: location.pathname });
    hubspotTrack();
});

rootElement.render(
    // <StrictMode>
    <AppInsightsErrorBoundary onError={(error) => console.error(error)} reactPlugin={reactPlugin}>
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route exact path={'/'} component={Landing} />
                    <Suspense fallback={<Spinner />}>
                        <ScrollMemory />
                        <Route component={(props) => <App {...props} />} />
                        <Notifications />
                        <LiveChatCoordinator />
                        <PrintScreenEventHandler />
                    </Suspense>
                </Switch>
            </Router>
        </Provider>
    </AppInsightsErrorBoundary>
    // </StrictMode>
);

// ReactDOM.render(
//     <Provider store={store}>
//         <Router history={history}>
//             <Switch>
//                 <Route exact path={'/'} component={Landing} />
//                 <Suspense fallback={<Spinner />}>
//                     <ScrollMemory />
//                     <Route component={props => <App {...props} />} />
//                     <Notifications />
//                     <LiveChatCoordinator />
//                     <PrintScreenEventHandler />
//                 </Suspense>
//             </Switch>
//         </Router>
//     </Provider>,
//     rootElement,
// );
