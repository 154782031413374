import React from 'react';
import { connect } from 'react-redux';
import { getAuth } from 'store/auth/authReducer';

import {
    Actions as MiscEventController,
    Selectors as MiscEventSelectors,
} from 'app/events/redux/misc.controller';
import Spinner from 'components/Spinner/Spinner';
import userActivityInsert, { UserActivity } from 'app/user-activity/actions/user-activity.actions';
import { userPaths } from 'routes/paths.js';

import WhyAttend from 'app/events/components/authorized-event-page/common/why-attend/why-attend';

import PastEvents from 'app/events/components/authorized-event-page/common/past-events/past-events';

import Attendees from 'app/events/components/authorized-event-page/common/attendees/attendees';
import BannerNotRegistered from 'app/events/components/authorized-event-page/common/banners/not-registered/banner-istanbul';

import { benefits } from '../../data/benefits';

import { UserSummitModel } from 'api/virtual-summit/models/summit-invitationl';
import {
    Attendee,
    ConfirmedAttendees,
} from 'app/events/redux/virtual-summit/confirmed-attendees.controller';
import AuthState from 'store/auth/auth-state';
import Speakers from '../../common/speakers/speakers';
import DividerLine from 'app/events/components/authorized-event-page/common/divider-line/divider-line';
import { PageType } from 'constants/enums';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';

import { testimonials } from '../../data/testimonials';
import { eventClubs as previousEventClubs } from '../../data/event-attendee';

import './styles.scss';
import { EventAgendaDayModel } from 'api/virtual-summit/models/event-agenda';
import ToBeAnnouncedSoon from 'app/events/components/authorized-event-page/common/to-be-announced-soon/to-be-announced-soon';
import WhatToExpect from '../../common/what-to-expect/what-to-expect';
import PrevEventVideo from '../../common/prev-event-video/prev-event-video';
import { AppState } from 'root.reducer';
import Agenda from '../../common/agenda/agenda';
import historyAccessor from 'history-accessor';
import Overview from '../../common/overview/overview';

interface StateProps {
    isLoading: boolean;
    upcommingEventData: UserSummitModel;
    confirmedAttendees: Attendee[];
    isRegistrationShown: boolean;
    auth: AuthState;
    agendaDays: EventAgendaDayModel[];
}
interface DispatchProps {
    onLoad: (squadId: number) => void;
    userActivityInsert: (obj: UserActivity) => void;
}

type Props = StateProps & DispatchProps;

const EventPageNotRegistered = (props: Props) => {
    const { isLoading, upcommingEventData, confirmedAttendees, agendaDays } = props;

    const {
        // isTransferIn,
        // isTransferOut,
        // isGeneralNetworking,
        // transferInStepCompleted,
        // transferOutStepCompleted,
        // generalNetworkingStepCompleted,
        // registerNowVisible,
        offlineEventTimeZone,
        user,
        isUserRegistered,
        extraAttendees,
    } = upcommingEventData;

    // const shouldRegister = registerNowVisible

    // const isObjectivesDefined = isTransferIn || isTransferOut || isGeneralNetworking
    // const isUserCompletedAnyStep = transferInStepCompleted || transferOutStepCompleted || generalNetworkingStepCompleted

    const registeredAttendees = [];
    if (extraAttendees && extraAttendees.length > 0) {
        if (isUserRegistered) {
            registeredAttendees.push(user.name);
        }
        extraAttendees
            .filter((i) => i.isRegistered && i.name !== null)
            .forEach((i) => {
                registeredAttendees.push(i.name);
            });
    }

    // // Boolean describes whether to show or hide attendees component,
    // // while user see 'not registered' event page view
    // const canShowAttendeesComponent = true;

    // // Boolean describes whether to show current event confirmed attendees or previous attendees (mocked data),
    // // Attendees is hidden while there are small amount of registered clubs
    // const shouldShowConfirmedAttendees = false;

    const { eventId } = props.auth;
    const eventInfo = getEventInfo(eventId);
    const { title, eventDates, location } = eventInfo;
    const { canShowAttendeesComponent, shouldShowConfirmedAttendees } = eventInfo.visibility;

    return (
        <div className='authorized-event-page'>
            {isLoading && <Spinner />}
            <BannerNotRegistered
                isRegistrationClosed={false}
                isLoading={isLoading}
                isRegistered={isUserRegistered}
                title='TransferRoom Summit İstanbul'
                date='11+12 November'
                eventLocation='İstanbul, Turkey'
                showLimitedButton={false}
                className='event-banner-layout'
                registerBtnText='REGISTER NOW'
                darkBackgroundMode
                // infoText='TransferRoom Summit'
                // upperTitle='THE HEAT IS ON:'
                // className="madrid-event-layout"
                onRegister={() => {
                    props.userActivityInsert({
                        Message: 'Opened Registration',
                        PageName: 'Event [Registration]',
                        PageType: PageType.Events,
                        EventId: props.auth.eventId,
                    });
                    historyAccessor.push(userPaths.eventRegistration);
                }}
            />
            <div style={{ height: 70 }} />
            <div className='events-page-wrapper'>
                <div className='row'>
                    {/* ---> Add or remove className "big-width" to change basic latout*/}
                    <div
                        className={`col-left content ${shouldShowConfirmedAttendees ? '' : 'big-width'} ${canShowAttendeesComponent ? '' : 'single-col-view'}`}
                    >
                        {' '}
                        {/* ---> Add or remove className "big-width" to change basic latout*/}
                        {/* <ToBeAnnouncedSoon eventName='4th TransferRoom Deal Day' /> */}
                        {/* { <Speakers /> } */}
                        {/* <WhyAttend benefits={benefits}  testimonials={testimonials} hideBottomLine={true} /> */}
                        {/* <Overview hideBottomLine={true} /> */}
                        {/* <WhatToExpect
                            hideBottomLine={true} 
                        /> */}
                        {/* <Agenda agendaDays={agendaDays} /> */}
                        {/*<PrevEventVideo />*/}
                        {/*
                        <Replay
                            trackUserActivity={() => this.props.userActivityInsert({
                                PageName: 'Event',
                                Message: 'Opened video (6th TransferRoom Summit)',
                                EventId: eventId
                            })} 
                            content={{
                                videoUrl: 'https://player.vimeo.com/video/696040095',
                                backgroundThumb: Thumb,
                                heading1: '6th TransferRoom Summit',
                                heading2: 'Madrid 21st-22nd March 2022',
                                highlightsText: 'Highlights 01:43',
                                speakers: [
                                    { imgSrc: Troels, name: 'Troels Bech', title: 'Host and', organization: 'Moderator' },
                                    { imgSrc: Paul, name: 'Paul Mitchell', title: 'Sporting Director', organization: 'AS Monaco' },
                                    { imgSrc: Rasmus, name: 'Rasmus Ankersen', title: 'CEO and Co-Founder', organization: 'Sport Republic' },
                                ]
                            }}
                        /> */}
                        <Agenda agendaDays={agendaDays} />
                        {/* UPCOMING EVENTS */}
                        {/* { <div className="row small-banner-section">
                            <SmallRegularBanner
                                style={{width: '100%'}}
                                isRegistrationClosed={false}
                                isRegistered={isUserRegistered}
                                eventName="Virtual Summit"
                                location="Online"
                                dayDate="8th"
                                monthDate="December 2020"
                                ctaText={'Register Now!'}
                                ctaTextCompleted={'Registered'}
                                isGreenButton
                                // backgroundImageSrc={dealDayBackground}
                                onCTAClick={() => {
                                    this.props.userActivityInsert({ 
                                        Message: 'Register Now', 
                                        PageName: 'Event [Banner]',
                                        PageType: PageType.Events,
                                        EventId: this.props.auth.eventId,
                                    })
                                    historyAccessor.push(userPaths.eventAddExtraAttendees)
                                }}
                                // additionalLocation="Online"
                                // showMapLink="https://www.google.com/maps/place/Stamford+Bridge/@51.4816663,-0.1931452,17z/data=!3m1!4b1!4m5!3m4!1s0x48760f864b976f3d:0x48aa38781ea565f8!8m2!3d51.481663!4d-0.1909565"
                            />
                        </div> } */}
                        <DividerLine title='Past Events' />
                        <PastEvents />
                    </div>

                    {canShowAttendeesComponent && (
                        <div
                            className={`col-right ${shouldShowConfirmedAttendees ? '' : 'small-width'}`}
                        >
                            {' '}
                            {/* ---> Add or remove className "small-width" to change basic layout*/}
                            <Attendees
                                title={
                                    shouldShowConfirmedAttendees
                                        ? 'Confirmed Attendees'
                                        : 'Previous Attendees'
                                }
                                titleWrap={shouldShowConfirmedAttendees}
                                notRegisteredState // configure sticky position for mobile devices
                                attendees={
                                    shouldShowConfirmedAttendees
                                        ? confirmedAttendees
                                        : previousEventClubs
                                }
                                showDetails={shouldShowConfirmedAttendees}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div style={{ height: 160 }} />
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const { upcommingSummitData, agendaDays, isRegistrationShown } = state.events.misc;
    return {
        isLoading: MiscEventSelectors.selectIsLoading(state),
        upcommingEventData: upcommingSummitData,
        confirmedAttendees: state.events.virtualSummit.confirmedAttendees.attendees,
        isRegistrationShown: isRegistrationShown,
        auth: getAuth(state),
        agendaDays: agendaDays,
    };
};

const mapDispatchToProps: DispatchProps = {
    onLoad: MiscEventController.getEventData,
    userActivityInsert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPageNotRegistered);
