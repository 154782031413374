import {
    GridToggleState,
    PaginationClickState,
    SearchMode,
} from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import { CancelTokenSource } from 'axios';
import { SearchResult } from 'api/search-v3/model/search-result';
import { SearchStaffProfileItemForClub } from 'api/search-v3/model/search-staff-profile-item-for-club';
import { getPageSize } from 'ts-components/pagination/helpers/paging-store';
import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { SearchStaffProfileRequest, SortByKeyEnum } from 'api/search-v3/model/search-request';
import { Actions as FilterActions } from 'pages/PlayerSearch-v3/personal-account/redux/filter.controller';
import { Selectors as FilterSelectors } from 'pages/PlayerSearch-v3/personal-account/redux/filter.controller';
import { getCancelTokenSource } from 'axios-config';
import ClubSideSearchService from 'api/search-v3/search.clubside.service';
import { Actions as AutoSuggestActions } from 'pages/PlayerSearch-v3/personal-account/redux/autosuggest.controller';
import historyAccessor from 'history-accessor';

type SearchStaffResultClub = SearchResult<SearchStaffProfileItemForClub>;

class GridState {
    result: SearchStaffResultClub;
    resultLoading: boolean;
    gridState: GridToggleState;
    pageSize: number;
    paginationState: number;
}

const defaultState: GridState = {
    result: null,
    gridState: GridToggleState.StaffProfilesSearch,
    resultLoading: false,
    pageSize: getPageSize('search', 10),
    paginationState: PaginationClickState.Number,
};

const stateController = new StateController<GridState>(
    'PERSONAL_ACCOUNT_SEARCH/GRID',
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static toggleGridState(
        gridState: GridToggleState,
        definedSorting: SortByKeyEnum = null
    ) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    gridState: gridState,
                })
            );
            dispatch(FilterActions.initSorting(definedSorting));
        };
    }

    public static refresh(isUserActivityTracked?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const gridState = Selectors.getGridState(getState());
            dispatch(Actions.searchStaffProfiles(isUserActivityTracked));
        };
    }

    public static searchStaffProfiles(isUserActivityTracked?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let tabKey = Selectors.getGridState(getState());
                let searchMode = FilterSelectors.getRoot(getState()).searchMode;
                if (tabKey == GridToggleState.Search && searchMode == SearchMode.Default) {
                    return;
                }

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();

                const state = getState();
                dispatch(stateController.setState({ resultLoading: true, result: null }));

                let req: SearchStaffProfileRequest = Selectors.getStaffProfilesRequest(state);

                let result: SearchStaffResultClub = null;
                result = await ClubSideSearchService.searchStaffProfiles(req, Actions.token.token);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        result: result,
                    }))
                );

                if (isUserActivityTracked) {
                    dispatch(AutoSuggestActions.gridSendUserActivity());
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ resultLoading: false }));
            }
        };
    }

    public static moveToSearchResultsByGridToggleState(
        grid: GridToggleState,
        redirectUrl?: string
    ) {
        return (dispatch, getState: () => AppState) => {
            dispatch(AutoSuggestActions.saveLatestSearchKeyword());
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(Actions.toggleGridState(grid));
            const location = historyAccessor.location;

            if (redirectUrl) {
                historyAccessor.push(redirectUrl);
            }

            if (location.pathname.includes(redirectUrl)) {
                dispatch(Actions.refresh());
            }
        };
    }

    public static setPage = (page: number) => {
        return (dispatch, getState: () => AppState) => {
            const paginationState = Selectors.getRoot(getState()).paginationState;

            dispatch(
                stateController.setState((draftState) => {
                    return {
                        ...draftState,
                        result: { ...draftState.result, currentPage: page },
                    };
                })
            );

            dispatch(Actions.refresh());

            let message = '';
            switch (paginationState) {
                case PaginationClickState.Number:
                    message = `Moved to Page: ${page}`;
                    break;
                case PaginationClickState.Left:
                    message = `Moved to Previous Page: ${page}`;
                    break;
                case PaginationClickState.Right:
                    message = `Moved to Next Page: ${page}`;
                    break;
            }

            // dispatch(
            //     userActivityInsert({
            //         PageName: 'Search [Pagination]',
            //         Message: message,
            //         PageType: PageType.Search,
            //     })
            // );
        };
    };

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((draftState) => {
                    return {
                        ...draftState,
                        result: { ...draftState.result, currentPage: page },
                        pageSize,
                    };
                })
            );
            dispatch(Actions.refresh());

            // dispatch(
            //     userActivityInsert({
            //         PageName: 'Search',
            //         Message: `Selected ${pageSize} rows`,
            //         PageType: PageType.Search,
            //     })
            // );
        };
    };

    public static paginationSetState(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ paginationState: value }));
        };
    }

    public static resetPage = () => {
        return (dispatch) => {
            dispatch(
                stateController.setState((draftState) => {
                    return {
                        ...draftState,
                        result: { ...draftState.result, currentPage: 1 },
                    };
                })
            );
        };
    };

    private static getStaff = (state: AppState, id: number) => {
        return state.personalAccountSearch.grid.result.items
            .filter((x) => x != null)
            .map((x) => x as SearchStaffProfileItemForClub)
            .find((x) => x.staffId == id);
    };

    public static onOpenStaffProfile(staffId: number) {
        return (dispatch, getState: () => AppState) => {
            const staff = Actions.getStaff(getState(), staffId);
            const { squad } = staff as SearchStaffProfileItemForClub;

            // dispatch(
            //     userActivityInsert({
            //         PageName: 'Search',
            //         Message: 'Opened Coach Profile',
            //         PageType: PageType.Search,
            //         CoachId: staffId,
            //         ClubId: squad ? squad.id : null,
            //     })
            // );

            window.open('/staff-profile/' + staffId, '_blank');
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.personalAccountSearch.grid;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).resultLoading;
    public static getGridState = (state: AppState) => Selectors.getRoot(state).gridState;
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static getCurrentPage = (state: AppState) =>
        (Selectors.getRoot(state).result || {}).currentPage || 1;
    public static getTotalPageCount = (state: AppState) =>
        (Selectors.getRoot(state).result || {}).totalPageCount;
    public static getTotalRowCount = (state: AppState) =>
        (Selectors.getRoot(state).result || {}).totalResultCount;

    public static getStaffProfilesRequest = (state: AppState) => {
        const subState = state.personalAccountSearch;
        const pageNumber = Selectors.getCurrentPage(state);

        let req: SearchStaffProfileRequest = {
            filter: null,
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
        };

        return req;
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    GridState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors,
};
