import React from 'react';
import styled from 'styled-components';
import Button from 'components/Buttons/Button/Button';
import { ReactComponent as CheckCircleIcon } from 'media/images/icons/check-circle-v2.svg';
import { maxDevice } from 'media/styles/mixins/breakpoints';
import Vector from 'media/images/events/2024-november-istanbul/Vector5.png';

interface OwnProps {
    isCompleted: boolean;
    isLoading: boolean;
    anonymous?: boolean;
    eventAttendeess: Array<string>;
    onCompleteAttendanceClick: () => void;
    onAddExtraAttendeeClick: () => void;
    title: string;
    date: string;
    description?: string;
    altDate?: string;
    eventLocation?: string;
    className?: string;
    showAddExtraAttendeeBtn?: boolean;
    altTitle?: string;
    altClassName?: string;
    darkBackgroundMode?: boolean;
}

const BannerRegistered = ({
    isCompleted = false,
    isLoading = false,
    anonymous = false,
    eventAttendeess = [],
    onAddExtraAttendeeClick,
    onCompleteAttendanceClick,
    date,
    description,
    className,
    showAddExtraAttendeeBtn = false,
    darkBackgroundMode = false,
}: OwnProps) => {
    const renderAttendeessList = () => {
        return eventAttendeess.map((item, index) => (
            <span key={index + item} className='attendees-list__item'>
                <span>{`${item}${eventAttendeess.length - 1 === index ? '' : ', '}`}</span>
            </span>
        ));
    };

    return (
        <Section className={className}>
            <div className={'inner ' + (anonymous ? 'full-width' : '')}>

                <div className='title-block'>
                    <p className='title'>
                        The bridge to <strong>world football's</strong>
                    </p>
                    <p className='highlight'>DECISION-MAKERS</p>
                </div>
                <div className='event-label'>
                    <div className='location-summit'>
                        <span className='location-name'>İstanbul</span>
                        <span className='separator' />
                        <span>TransferRoom Summit</span>
                    </div>
                    <label>
                        <div className='label-item'>
                            <div>
                                <span>İ</span>stanbul
                            </div>
                        </div>
                        <div className='label-item'>
                            <div>Nov 11 + 12</div>
                        </div>
                    </label>
                    <label>
                        <div className='label-item'>
                            <div>Online</div>
                        </div>
                        <div className='label-item'>
                            <div>Nov 14</div>
                        </div>
                    </label>
                </div>
                <div className='confirmed-label'>
                    <div className='confirmed-label__icon'>
                        <CheckCircleIcon />
                    </div>
                    <div className='confirmed-label__text'>
                        <span>Registration Confirmed</span>
                    </div>
                </div>
                {!isLoading && (
                    <>
                        {isCompleted ? (
                            <>
                                <div className='attendees'>
                                    <div className='attendees-list'>
                                        <span className='attendees-list__title'>
                                            Attendees:{'  '}
                                        </span>
                                        {renderAttendeessList()}
                                    </div>
                                </div>

                                {showAddExtraAttendeeBtn && eventAttendeess.length < 4 && (
                                    <Button
                                        type={darkBackgroundMode ? 'primary' : 'primaryinverse'}
                                        effect='no'
                                        onClick={onAddExtraAttendeeClick}
                                    >
                                        Add Extra Attendee
                                    </Button>
                                )}
                            </>
                        ) : (
                            <>
                                <div></div>
                                <Button
                                    type='primaryinverse'
                                    effect='no'
                                    onClick={() => onCompleteAttendanceClick()}
                                >
                                    <div>Complete Your Attendance Details Now</div>
                                    <div
                                        style={{
                                            fontWeight: 500,
                                            color: '#e55843',
                                            fontSize: 11,
                                            marginTop: 5,
                                            textTransform: 'none',
                                        }}
                                    >
                                        Registration is confirmed but still subject to approval. We
                                        will get in touch.
                                    </div>
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
        </Section>
    );
};

const Section = styled.section`
    height: auto;
    text-align: center;
    color: #fff;
    .inner {
        padding: 51px 20px 60px;
        background-image: none;
        background-color: #0e854c;
        background-position: center 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media ${maxDevice.mobileS} {
            padding: 100px 30px 63px;
        }

        &.full-width {
            position: absolute;
            left: 50%;
            width: 100vw;
            margin-left: -50vw;
        }

        .title-block {
            margin-bottom: 48px;
            font-size: 54px;
            .title {
                margin-bottom: 28px;
                color: #fff;
                text-align: center;
                font-size: 50px;
                font-weight: 400;
                line-height: normal;
                strong {
                    font-weight: 800;
                }

                @media ${maxDevice.tabletM} {
                    font-size: 35px;
                }

                @media ${maxDevice.mobileM} {
                    text-shadow: 0px 4px 16px rgba(19, 26, 54, 0.5);
                    font-size: 28px;
                }

                & > b {
                    font-family: Inter;
                    font-weight: 600;
                }
            }
        }

        .highlight {
            margin: 0 auto;
            width: fit-content;
            background-color: #0e9655;
            color: white;
            padding: 8px 16px;
            font-weight: 700;
            font-size: 34.174px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 3.417px;
            @media ${maxDevice.tabletM} {
                font-size: 24px;
            }
        }

        .event-label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-bottom: 30px;

            @media ${maxDevice.tabletM} {
                flex-direction: column;
                align-items: center;
                margin-bottom: 23px;
                gap: 16px;
            }

            & > label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0;
                border-radius: 69.067px;
                border: 1.233px solid #fff;
                background: rgba(255, 255, 255, 0.1);

                @media ${maxDevice.mobileM} {
                    height: 30px;
                    border-radius: 56px;
                }

                .label-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 37px;

                    @media ${maxDevice.mobileM} {
                        height: 30px;
                    }

                    & div {
                        color: #fff;
                        font-size: 18.5px;
                        font-weight: 700;
                        line-height: normal;
                        padding: 0 12px;

                        @media ${maxDevice.mobileM} {
                            font-size: 15px;
                        }
                        & span {
                            font-family: Inter;
                            font-weight: 600;
                        }
                    }

                    &:first-child {
                        border-right: 1.233px solid #fff;
                        border-radius: 69.067px 0px 0px 69.067px;
                        background: rgba(255, 255, 255, 0.25);
                    }
                }

                @media ${maxDevice.mobileM} {
                    font-size: 17px;
                    max-width: fit-content;
                }
            }
        }

        .location-summit {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 19.55px;
            font-weight: 0;
            color: #ffffff;
            text-align: center;
            gap: 10px;
            @media ${maxDevice.desktop} {
                font-size: 15px;
            }

            @media ${maxDevice.tabletS} {
                margin: 0;
            }
            .location-name {
                font-weight: 900;
            }
        }
        .separator {
            background: transparent url(${Vector}) no-repeat;
            width: 1px;
            height: 29.513px;
            stroke-width: 1.115px;
            stroke: #fff;
            flex-shrink: 0;
            margin: 0 12px;
        }

        button {
            max-width: 100%;
            padding: 14px 20px;
            text-overflow: ellipsis;
            white-space: normal;
            min-height: 50px;
            color: #131a36;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.14px;
            border-radius: 5px;
            border: 1px solid #fff;

            &:hover {
                opacity: 0.9;
            }

            @media ${maxDevice.mobileS} {
                width: calc(100% - 20px);
                min-height: 48px;
            }
        }

        .confirmed-label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 12px;
            border: 0px solid #fff;
            background: #fff;
            color: #0e9655;
            font-size: 12px;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            padding: 5px 11px 5px 8px;
            gap: 6px;
            margin-bottom: 12px;
            &__icon {
                height: 20px;
                width: 20px;
            }

            &__text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
        .attendees {
            margin-bottom: 18px;

            &-list {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
`;

export default BannerRegistered;
