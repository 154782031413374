import _ from "lodash";
import { AppState } from "root.reducer";
import { StateController } from "utils/action-declaration";
import { Country, League, Permissions, PermissionTypes, SubscriptionPlans, UserModel as Supervisor, ClubPermissionsFilterOptions, SquadModel } from "api/admin/club-permissions/models";
import { ClubPermissionsService } from 'api/admin/club-permissions/club-permissions.service'
import { PlayerSearchService } from "api/player/player-search";
import { getPlayerSearchFilterCriteria } from "store/playerSearch/reducers/playerSearchFilterReducer";
import { promotionPlans } from 'constants/promotionPlans';
import { getAuthSquadId, getAuthClubId } from 'store/auth/authReducer';

export class ClubPermissionState {
    items: Array<SquadModel>;
    totalRecords: number;
    currentPage: number;
    recordsPerPage: number;
    countries: Array<Country>;
    leagues: Array<League>;
    allLeagues: Array<League>;
    competitionName: string;

    permissionTypes: Array<PermissionTypes>;
    subscriptionPlans: SubscriptionPlans;
    competitionLists: Array<{ name: string; competitionIds: Array<number> }>;
    subscriptions: Array<{ id: number, name: string, permission?: Permissions }>;
    supervisors: Array<Supervisor>;
    autosuggestionsOptions: Array<any>;
    filterOptions: ClubPermissionsFilterOptions;
    previousFilterSnapshot: ClubPermissionsFilterOptions;

    editingClubId: number;
    editingCode: string;
    editingPermissionCode: string;
    isEditLeaguesModalOpen: boolean;
    isFriendlyConfirmationModalOpen: boolean;

    isLoading: boolean;
    autosuggestProcessing: boolean;
    processing: ProcessingState;
}
export class ProcessingState {
    sendMessagesProcessingIds: Array<number>;
    subscriptionProcessingIds: Array<number>;
    promotionPlansProcessingIds: Array<number>;
    expireProcessingIds: Array<number>;
    listPlayersProcessingIds: Array<number>;
    pitchPlayersProcessingIds: Array<number>;
    showPitchPageProcessingIds: Array<number>;
    pitchInsightsProcessingId: Array<number>;
    clubAdsLeaguesProcessingId: Array<number>;
    pitchInsightsProcessingIds: Array<number>;
    allowedPlusPitchesCountProcessingId: Array<number>;
    allowedSuperPitchesCountProcessingId: Array<number>;
    playerAvailabilityProcessingIds: Array<number>;
    financialInsightsProcessingIds: Array<number>;
    virtualLoanAssistantProcessingIds: Array<number>;
    gbeCalculatorProcessingIds: Array<number>;
    agentsProcessingIds: Array<number>;
    friendliesProcessingIds: Array<number>;
    headCoachProcessingIds: Array<number>;
    salesSupervisorProcessingIds: Array<number>;
    allLeaguesProcessingIds: Array<number>;
    processingLeaguesIds: Array<number>;
    staffProductSubscriptionProcessingIds: Array<number>;
}

const defaultFilterOptions = {
    squadId: null,
    areaId: null,
    competitionId: null,
    subscriptionId: 0,
    campaignId: null,
    supervisorId: null,
    withUsersOnly: false,
    keyword: '',
    country: '',
    league: '',
    subscription: '',
    campaign: '',
    supervisor: '',
}

const defaultState: ClubPermissionState = {
    items: [],
    totalRecords: 0,
    currentPage: 1,
    recordsPerPage: 10,
    countries: [],
    leagues: [],
    allLeagues: [],
    competitionName: '',

    permissionTypes: [],
    subscriptionPlans: { competitionLists: [], subscriptions: [] },
    competitionLists: [],
    subscriptions: [],
    supervisors: [],
    autosuggestionsOptions: [],
    filterOptions: defaultFilterOptions,
    previousFilterSnapshot: defaultFilterOptions,

    editingClubId: null,
    editingCode: '',
    editingPermissionCode: null,
    isEditLeaguesModalOpen: false,
    isFriendlyConfirmationModalOpen: false,

    isLoading: true,
    autosuggestProcessing: false,
    processing: {
        sendMessagesProcessingIds: [],
        subscriptionProcessingIds: [],
        promotionPlansProcessingIds: [],
        expireProcessingIds: [],
        listPlayersProcessingIds: [],
        pitchPlayersProcessingIds: [],
        showPitchPageProcessingIds: [],
        pitchInsightsProcessingIds: [],
        clubAdsLeaguesProcessingId: [],
        pitchInsightsProcessingId: [],
        allowedPlusPitchesCountProcessingId: [],
        allowedSuperPitchesCountProcessingId: [],
        playerAvailabilityProcessingIds: [],
        financialInsightsProcessingIds: [],
        virtualLoanAssistantProcessingIds: [],
        gbeCalculatorProcessingIds: [],
        agentsProcessingIds: [],
        friendliesProcessingIds: [],
        headCoachProcessingIds: [],
        salesSupervisorProcessingIds: [],
        allLeaguesProcessingIds: [],
        processingLeaguesIds: [],
        staffProductSubscriptionProcessingIds: [],
    }
};

const stateController = new StateController<ClubPermissionState>(
    "ADMIN/CLUB-PERMISSIONS",
    defaultState
);

class Actions {

    public static getSquadsPermissions() {
        const wasFiltersChanged = (filterOptions: ClubPermissionsFilterOptions, previousFilterSnapshot: ClubPermissionsFilterOptions) => {
            return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
        }

        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const {
                    recordsPerPage,
                    currentPage,
                    filterOptions,
                    previousFilterSnapshot
                } = Selectors.selectState(getState());

                const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    previousFilterSnapshot: {
                        ...prevState.filterOptions,
                    },
                    currentPage: shouldDisposePagination ? 1 : currentPage
                })));

                const data = await ClubPermissionsService.getSquadPermissionsByFilterOptionsWithPaging({
                    ...filterOptions,
                    currentPage: currentPage,
                    countItemsPerPage: recordsPerPage,
                });

                dispatch(stateController.setState({
                    items: data.items.map((item) => ({
                        ...item,
                        editPlusPitchesCountMode: false,
                        editSuperPitchesCountMode: false,
                    })),
                    totalRecords: data.countItems
                }));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getCountries = (userId) => {
        return async (dispatch, getState: () => AppState) => {
            const countries = await ClubPermissionsService.getCountries(userId);
            dispatch(stateController.setState({ countries: countries }));
        };
    };

    public static getCompetitions = () => {
        return async (dispatch, getState: () => AppState) => {
            const leagues = await ClubPermissionsService.competitionsFetch();
            dispatch(stateController.setState({ allLeagues: leagues }));
        };
    };

    public static getPermissionTypes = () => {
        return async (dispatch, getState: () => AppState) => {
            const permissionTypes = await ClubPermissionsService.getPermissionTypes();
            dispatch(stateController.setState({ permissionTypes: permissionTypes }));
        };
    };

    public static loadSubscriptionPlans = () => {
        return async (dispatch, getState: () => AppState) => {
            const subscriptionPlans = await ClubPermissionsService.loadSubscriptionPlans();
            dispatch(stateController.setState({ subscriptionPlans: subscriptionPlans }));

            dispatch(stateController.setState({ competitionLists: subscriptionPlans.competitionLists }));
            dispatch(stateController.setState({
                subscriptions: [
                    { id: 0, name: 'All' },
                    { id: null, name: 'Custom' },
                    ...subscriptionPlans.subscriptions,
                ]
            }));
        };
    };

    public static supervisorsFetch = () => {
        return async (dispatch, getState: () => AppState) => {
            const supervisorsFetch = await ClubPermissionsService.supervisorsFetch();
            dispatch(stateController.setState({ supervisors: supervisorsFetch.output }));
        };
    };

    public static loadInitialData = (userId: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                await Promise.allSettled([
                    dispatch(Actions.getSquadsPermissions()),
                    dispatch(Actions.getCountries(userId)),
                    dispatch(Actions.loadSubscriptionPlans()),
                    dispatch(Actions.supervisorsFetch()),
                    dispatch(Actions.getCompetitions()),
                ])
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    };

    public static onChangeKeyword(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    keyword: value,
                }
            })));

            if (value) {
                Actions.loadFirstClubAutosuggestionsDebounceFunc(dispatch, value)
            } else {
                dispatch(stateController.setState({ autosuggestionsOptions: [] }))
            }
        }
    }

    private static loadFirstClubAutosuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getClubAutosuggestions(keyword)), 1000)

    public static getClubAutosuggestions(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const squadId = getAuthSquadId(getState());
                const clubId = getAuthClubId(getState());
                const clubOnly = true;
                const filter = getPlayerSearchFilterCriteria(getState());

                dispatch(stateController.setState({ autosuggestProcessing: true }))

                const autosuggestionsOptions = await PlayerSearchService.getPlayerAutosuggestions(squadId, clubId, { ...filter, keyword }, clubOnly, true, true)
                dispatch(stateController.setState({ autosuggestionsOptions: autosuggestionsOptions }))
            } finally {
                dispatch(stateController.setState({ autosuggestProcessing: false }))
            }
        }
    }

    public static onClearAutosuggestions() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsOptions: [],
                filterOptions: {
                    ...prevState.filterOptions,
                    keyword: '',
                    squadId: null,
                }
            })));

            dispatch(Actions.getSquadsPermissions());
        }
    }

    public static selectClub(clubName: string, squadId: number, countryId: string, competitionId: number) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    squadId: squadId,
                }
            })));

            dispatch(Actions.onChangeKeyword(clubName));
            dispatch(Actions.onChangeCountry(Number(countryId), squadId, false));
            dispatch(Actions.onChangeLeague(competitionId, squadId));
        };
    };

    public static onChangeCountry(id: number, squadId?: number, isStartSearch: boolean = true) {
        return (dispatch, getState: () => AppState) => {
            const country = getState().admin.clubPermissions.countries.find(item => item.id === id)

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                items: [],
                autosuggestionsOptions: squadId ? prevState.autosuggestionsOptions : [],
                leagues: country?.competitions,
                filterOptions: {
                    ...defaultFilterOptions,
                    areaId: id,
                    country: country?.name,
                    keyword: squadId ? prevState.filterOptions.keyword : '',
                    withUsersOnly: prevState.filterOptions.withUsersOnly,
                }
            })));

            if(isStartSearch){
                dispatch(Actions.getSquadsPermissions());
            }            
        };
    };

    public static onChangeLeague(id: number, squadId?: number) {
        return (dispatch, getState: () => AppState) => {
            const leagueName = getState().admin.clubPermissions.leagues?.find(item => item.id === id)?.name

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autosuggestionsOptions: squadId ? prevState.autosuggestionsOptions : [],
                filterOptions: {
                    ...prevState.filterOptions,
                    competitionId: id,
                    league: leagueName,
                    squadId: squadId ? squadId : null,
                    keyword: squadId ? prevState.filterOptions.keyword : '',
                }
            })));

            dispatch(Actions.getSquadsPermissions());
        };
    };

    public static onChangeSubscription(subscriptionId: number) {
        return (dispatch, getState: () => AppState) => {
            const subscriptionName = getState().admin.clubPermissions.subscriptions.find(item => item.id === subscriptionId)?.name

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    subscriptionId: subscriptionId,
                    subscription: subscriptionName,
                }
            })));

            dispatch(Actions.getSquadsPermissions());
        };
    };

    public static onChangeCampaign(campaignId: number) {
        return (dispatch, getState: () => AppState) => {
            const campaignName = promotionPlans.find(item => item.id === campaignId).name

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    campaignId: campaignId === 0 ? null : campaignId,
                    campaign: campaignId === 0 ? "All" : campaignName,
                }
            })));

            dispatch(Actions.getSquadsPermissions());
        };
    };

    public static onChangeSupervisor(supervisorId: number) {
        return (dispatch, getState: () => AppState) => {
            let supervisorName = ''
            if (supervisorId === -1) {
                supervisorName = 'Any Supervisor'
            } else if (supervisorId === 0) {
                supervisorName = 'No Supervisor'
            } else {
                supervisorName = getState().admin.clubPermissions.supervisors.find(item => item.id === supervisorId).name
            }

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    supervisorId: supervisorId === -1 ? null : supervisorId,
                    supervisor: supervisorName,
                }
            })));

            dispatch(Actions.getSquadsPermissions());
        };
    };

    public static onChangeSquadsWithUsers(value: boolean) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    withUsersOnly: value
                }
            })));

            dispatch(Actions.getSquadsPermissions());
        };
    };

    public static setSubscription(squadId: number, subscriptionPlanId: number | null, currentSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = Selectors.getItems(getState());

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        subscriptionProcessingIds: [...prevState.processing.subscriptionProcessingIds, squadId]
                    }
                })))

                const newItem = await ClubPermissionsService.setSquadSubscription(squadId, subscriptionPlanId, currentSquadId)

                const updatedItems = [...items];
                const updatedItem = updatedItems.find(item => item.id === squadId);
                const updatedIndex = updatedItems.findIndex(item => item.id === squadId);

                if (updatedItem.subscription) {                    
                    updatedItem.subscription.subscriptionPlanId = subscriptionPlanId;   
                    newItem.subscription = {subscriptionPlanId: subscriptionPlanId};                 
                    updatedItems[updatedIndex] = newItem;                    
                } else {
                    updatedItems[updatedIndex] = {
                        ...newItem,
                        subscription: {
                            promotionAssignedAt: null,
                            promotionExpiration: null,
                            subscriptionPromotionPlanId: null,
                            squadId: squadId,
                            subscriptionPlanId: subscriptionPlanId,
                        }
                    };
                }
                
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        subscriptionProcessingIds: prevState.processing.subscriptionProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    }

    public static setSquadPromotionPlan(squadId: number, promotionPlanId: number, expiration: Date | string | null, currentSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = Selectors.getItems(getState());

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        promotionPlansProcessingIds: [...prevState.processing.promotionPlansProcessingIds, squadId]
                    }
                })));

                await ClubPermissionsService.setSquadPromotionPlan(squadId, promotionPlanId, expiration, currentSquadId);

                const updatedItems = [...items];
                const updatedItem = updatedItems.find(item => item.id === squadId);

                if (updatedItem.subscription) {
                    updatedItem.subscription.subscriptionPromotionPlanId = promotionPlanId;
                } else {
                    updatedItem.subscription = {
                        promotionAssignedAt: null,
                        promotionExpiration: null,
                        subscriptionPlanId: null,
                        squadId: squadId,
                        subscriptionPromotionPlanId: promotionPlanId,
                    }
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        promotionPlansProcessingIds: prevState.processing.promotionPlansProcessingIds.filter(item => item !== squadId)
                    }
                })));
            }
        }
    }

    public static setPromotionExpiration(squadId: number, promotionPlanId: number, expiration: Date | string | null, currentSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        expireProcessingIds: [...prevState.processing.expireProcessingIds, squadId]
                    }
                })))

                await ClubPermissionsService.setSquadPromotionPlan(squadId, promotionPlanId, expiration, currentSquadId)

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).subscription.promotionExpiration = expiration
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        expireProcessingIds: prevState.processing.expireProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    }

    public static editPermissionCostValue = (squadId: number, dataKey: string, value: string | number) => {
        return async (dispatch, getState) => {
            const prevItems = [...Selectors.getItems(getState())];

            try {
                const currentItems = Selectors.getItems(getState());
                const updatedSquad = currentItems.find(item => item.id === squadId);
                const objectKey = Object.keys(updatedSquad).find(key => key.toLowerCase() === dataKey.toLowerCase());
                if (updatedSquad[objectKey] === +value) {
                    return;
                }
                updatedSquad[objectKey] = +value;
                dispatch(stateController.setState({ items: [...currentItems] }));

                const permissionCode = dataKey === 'allowedPlusPitchesCount' ? 'PlusPitchesCount' : 'SuperPitchesCount';

                await ClubPermissionsService.editPermissionCostValue(squadId, permissionCode, +value);
            } catch (error) {
                dispatch(stateController.setState({ items: [...prevItems] }));
                throw error;
            }
        };
    };

    public static setPitchInsights(squadId: number, permissionCode: string, value: string | number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        pitchInsightsProcessingIds: [...prevState.processing.pitchInsightsProcessingIds, squadId]
                    }
                })))

                await ClubPermissionsService.editPermissionCostValue(squadId, permissionCode, +value);

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).pitchInsightsSubscription = +value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))


            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        pitchInsightsProcessingIds: prevState.processing.pitchInsightsProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setLoanInsightsSubscription(squadId: number, permissionCode: string, value: string | number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        virtualLoanAssistantProcessingIds: [...prevState.processing.virtualLoanAssistantProcessingIds, squadId]
                    }
                })))

                await ClubPermissionsService.editPermissionCostValue(squadId, permissionCode, +value);

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).loanInsightsSubscription = +value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))


            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        virtualLoanAssistantProcessingIds: prevState.processing.virtualLoanAssistantProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setStaffProductSubscription(squadId: number, value: string | number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;
                const permissionCode = 'StaffProduct';

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        staffProductSubscriptionProcessingIds: [...prevState.processing.staffProductSubscriptionProcessingIds, squadId]
                    }
                })))

                await ClubPermissionsService.editPermissionCostValue(squadId, permissionCode, +value);

                const updatedItems = [...items]
                updatedItems.find(x => x.id === squadId).staffProductSubscription = +value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        staffProductSubscriptionProcessingIds: prevState.processing.staffProductSubscriptionProcessingIds.filter(x => x !== squadId)
                    }
                })))
            }
        }
    };

    public static setPermission(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            if (value) {
                await ClubPermissionsService.allowAction(squadId, permissionCode.toString());
                if (permissionCode === 'AdsPitchPlayers') {
                    await ClubPermissionsService.allowAction(squadId, 'UseMatchMaker');
                    await ClubPermissionsService.allowAction(squadId, 'AdsSeeClubNames');
                }
            }
            else {
                await ClubPermissionsService.disallowAction(squadId, permissionCode.toString());
                if (permissionCode === 'AdsPitchPlayers') {
                    await ClubPermissionsService.disallowAction(squadId, 'UseMatchMaker');
                    await ClubPermissionsService.disallowAction(squadId, 'AdsSeeClubNames');
                }
            }

        }
    };

    public static setPermissionReverse(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            if (value) {
                await ClubPermissionsService.disallowAction(squadId, permissionCode.toString());
                if (permissionCode === 'AdsPitchPlayers') {
                    await ClubPermissionsService.disallowAction(squadId, 'UseMatchMaker');
                    await ClubPermissionsService.disallowAction(squadId, 'AdsSeeClubNames');
                }
            }
            else {
                await ClubPermissionsService.allowAction(squadId, permissionCode.toString());
                if (permissionCode === 'AdsPitchPlayers') {
                    await ClubPermissionsService.allowAction(squadId, 'UseMatchMaker');
                    await ClubPermissionsService.allowAction(squadId, 'AdsSeeClubNames');
                }
            }
        }
    };

    public static setFinancialInsights(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        financialInsightsProcessingIds: [...prevState.processing.financialInsightsProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value))

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).financialInsights = value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        financialInsightsProcessingIds: prevState.processing.financialInsightsProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    }

    public static setCanSendMessages(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        sendMessagesProcessingIds: [...prevState.processing.sendMessagesProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value));

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).canSendMessages = value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        sendMessagesProcessingIds: prevState.processing.sendMessagesProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setCanSaleLoanPlayers(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        listPlayersProcessingIds: [...prevState.processing.listPlayersProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value));

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).canSaleLoanPlayers = value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        listPlayersProcessingIds: prevState.processing.listPlayersProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setCanPitchPlayers(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        pitchPlayersProcessingIds: [...prevState.processing.pitchPlayersProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value));

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).adsCanPitchPlayers = value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        pitchPlayersProcessingIds: prevState.processing.pitchPlayersProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setShowPitchPage(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions.items
                const newItems = _.cloneDeep(substate);
                const permission = substate.find(item => item.id === squadId).permissions.find(permission => permission.permissionCode === permissionCode)
                const permissionId = (permission || {}).permissionId
                const permissionName = (permission || {}).permissionName

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        showPitchPageProcessingIds: [...prevState.processing.showPitchPageProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermissionReverse(squadId, permissionCode, value));

                if (!value) {
                    newItems.find(item => item.id === squadId).permissions.push({
                        permissionCode: permissionCode,
                        permissionId: permissionId,
                        permissionName: permissionName,
                    })
                } else {
                    const newPermissions = newItems.find(item => item.id === squadId).permissions.filter(permission => permission.permissionCode !== permissionCode)
                    newItems.find(item => item.id === squadId).permissions = newPermissions
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems,
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        showPitchPageProcessingIds: prevState.processing.showPitchPageProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setPlayerAvailability(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        playerAvailabilityProcessingIds: [...prevState.processing.playerAvailabilityProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermissionReverse(squadId, permissionCode, value));

                const updatedItems = [...items]
                updatedItems.find(item => item.id === squadId).availabilityNo = !value
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        playerAvailabilityProcessingIds: prevState.processing.playerAvailabilityProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setGbeCalculator(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions.items
                const newItems = _.cloneDeep(substate);
                const permission = substate.find(item => item.id === squadId).permissions.find(permission => permission.permissionCode === permissionCode)
                const permissionId = (permission || {}).permissionId
                const permissionName = (permission || {}).permissionName

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        gbeCalculatorProcessingIds: [...prevState.processing.gbeCalculatorProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value));

                if (value) {
                    newItems.find(item => item.id === squadId).permissions.push({
                        permissionCode: permissionCode,
                        permissionId: permissionId,
                        permissionName: permissionName,
                    })
                } else {
                    const newPermissions = newItems.find(item => item.id === squadId).permissions.filter(permission => permission.permissionCode !== permissionCode)
                    newItems.find(item => item.id === squadId).permissions = newPermissions
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems,
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        gbeCalculatorProcessingIds: prevState.processing.gbeCalculatorProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setAgents(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = Selectors.getItems(getState());
                const newItems = [...items];
                const permission = items
                    .find(item => item.id === squadId).permissions
                    .find(permission => permission.permissionCode === permissionCode);
                const permissionId = permission?.permissionId;
                const permissionName = permission?.permissionName;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        agentsProcessingIds: [...prevState.processing.agentsProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value));

                if (value) {
                    newItems.find(item => item.id === squadId).permissions.push({
                        permissionCode: permissionCode,
                        permissionId: permissionId,
                        permissionName: permissionName,
                    })
                } else {
                    const newPermissions = newItems
                        .find(item => item.id === squadId).permissions
                        .filter(permission => permission.permissionCode !== permissionCode);

                    newItems.find(item => item.id === squadId).permissions = newPermissions
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems,
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        agentsProcessingIds: prevState.processing.agentsProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setFriendlies(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions.items
                const newItems = _.cloneDeep(substate);
                const permission = substate.find(item => item.id === squadId).permissions.find(permission => permission.permissionCode === permissionCode)
                const permissionId = (permission || {}).permissionId
                const permissionName = (permission || {}).permissionName

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        friendliesProcessingIds: [...prevState.processing.friendliesProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value));

                if (value) {
                    newItems.find(item => item.id === squadId).permissions.push({
                        permissionCode: permissionCode,
                        permissionId: permissionId,
                        permissionName: permissionName,
                    })
                } else {
                    const newPermissions = newItems.find(item => item.id === squadId).permissions.filter(permission => permission.permissionCode !== permissionCode)
                    newItems.find(item => item.id === squadId).permissions = newPermissions
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems,
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        friendliesProcessingIds: prevState.processing.friendliesProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };

    public static setHeadCoach(squadId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions.items
                const newItems = _.cloneDeep(substate);
                const permission = substate.find(item => item.id === squadId).permissions.find(permission => permission.permissionCode === permissionCode)
                const permissionId = (permission || {}).permissionId
                const permissionName = (permission || {}).permissionName

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        headCoachProcessingIds: [...prevState.processing.headCoachProcessingIds, squadId]
                    }
                })))

                await dispatch(Actions.setPermission(squadId, permissionCode, value));

                if (value) {
                    newItems.find(item => item.id === squadId).permissions.push({
                        permissionCode: permissionCode,
                        permissionId: permissionId,
                        permissionName: permissionName,
                    })
                } else {
                    const newPermissions = newItems.find(item => item.id === squadId).permissions.filter(permission => permission.permissionCode !== permissionCode)
                    newItems.find(item => item.id === squadId).permissions = newPermissions
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems,
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        headCoachProcessingIds: prevState.processing.headCoachProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    };


    public static setAssignSupervisor(squadId: number, supervisorUserId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions;
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        salesSupervisorProcessingIds: [...prevState.processing.salesSupervisorProcessingIds, squadId]
                    }
                })))

                const result = await ClubPermissionsService.assignSupervisor(squadId, supervisorUserId)
                if (result) {
                    const updatedItems = [...substate.items]
                    updatedItems.find(item => item.id === squadId).salesSupervisorUserId = supervisorUserId
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: updatedItems
                    })))
                }
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        salesSupervisorProcessingIds: prevState.processing.salesSupervisorProcessingIds.filter(item => item !== squadId)
                    }
                })))
            }
        }
    }

    public static toggleEditMode(squadId: number, editMode: boolean, dataKey: string) {
        return (dispatch, getState) => {
            const substate = getState().admin.clubPermissions;
            const updatedItems = [...substate.items];

            if (dataKey === 'allowedPlusPitchesCount') {
                updatedItems.find(item => item.id === squadId).editPlusPitchesCountMode = !editMode;
            } else {
                updatedItems.find(item => item.id === squadId).editSuperPitchesCountMode = !editMode;
            }
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                items: updatedItems
            })))
        };
    };

    public static toggleEditClub = (id: number | null) => (dispatch) => dispatch(stateController.setState({ editingClubId: id }));

    public static editAvailableLeagues = (id: number | null, editingCode: string) => (dispatch) => dispatch(stateController.setState({ editingClubId: id, editingCode: editingCode }));

    public static toggleEditPermissionCode = (code: string | null) => (dispatch) => dispatch(stateController.setState({ editingPermissionCode: code }));

    public static leaguesModalToggle() {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().admin.clubPermissions;

            if (substate.isEditLeaguesModalOpen) {
                dispatch(stateController.setState({
                    editingClubId: null,
                    isEditLeaguesModalOpen: !substate.isEditLeaguesModalOpen
                }));
            } else {
                dispatch(stateController.setState({ isEditLeaguesModalOpen: !substate.isEditLeaguesModalOpen }));
            }

        }
    }

    public static friendlyConfirmationModalToggle() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().admin.clubPermissions;
            dispatch(stateController.setState({ isFriendlyConfirmationModalOpen: !substate.isFriendlyConfirmationModalOpen }));
        }
    }

    public static disposeFilters() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                leagues: [],
                filterOptions: defaultState.filterOptions
            })));
            dispatch(Actions.getSquadsPermissions());
        };
    };

    public static setAllLeaguesAvailable(editingClubId: number, permissionCode: string, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        allLeaguesProcessingIds: [...prevState.processing.allLeaguesProcessingIds, editingClubId]
                    }
                })))

                if (value) {
                    await ClubPermissionsService.allowAction(editingClubId, permissionCode)
                } else {
                    await ClubPermissionsService.disallowAction(editingClubId, permissionCode)
                }

                const updatedItems = [...items];
                const foundItem = updatedItems.find(item => item?.id === editingClubId);

                if (permissionCode === 'AllAdsLeaguesAvailable') {
                    foundItem.isAllAdsLeaguesAvailable = value;
                    foundItem.allowedAdsCompetitions = [];
                } else {
                    foundItem.isAllLeaguesAvailable = value;
                    foundItem.allowedCompetitions = [];
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        allLeaguesProcessingIds: prevState.processing.allLeaguesProcessingIds.filter(item => item !== editingClubId)
                    }
                })))
            }
        }
    };

    public static allowAllLeagues(editingClubId: number, permissionCode: string, currentList: Array<League>) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions.items
                const newItems = _.cloneDeep(substate);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        allLeaguesProcessingIds: [...prevState.processing.allLeaguesProcessingIds, editingClubId]
                    }
                })))

                await ClubPermissionsService.allowAllLeagues(editingClubId, permissionCode)

                if (permissionCode === 'AllAdsLeaguesAvailable') {
                    newItems.find(item => item?.id === editingClubId).allowedAdsCompetitions = currentList

                } else {
                    newItems.find(item => item?.id === editingClubId).allowedCompetitions = currentList
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        allLeaguesProcessingIds: prevState.processing.allLeaguesProcessingIds.filter(item => item !== editingClubId)
                    }
                })))
            }
        }
    };

    public static disallowAllLeagues(editingClubId: number, permissionCode: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const items = getState().admin.clubPermissions.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        allLeaguesProcessingIds: [...prevState.processing.allLeaguesProcessingIds, editingClubId]
                    }
                })))

                await ClubPermissionsService.disallowAllLeagues(editingClubId, permissionCode)

                const updatedItems = [...items]

                if (permissionCode === 'AllAdsLeaguesAvailable') {
                    updatedItems.find(item => item.id === editingClubId).allowedAdsCompetitions = []
                } else {
                    updatedItems.find(item => item.id === editingClubId).allowedCompetitions = []
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: updatedItems
                })))

            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        allLeaguesProcessingIds: prevState.processing.allLeaguesProcessingIds.filter(item => item !== editingClubId)
                    }
                })))
            }
        }
    };

    public static disallowLeague(squadId: number, permissionCode: string, leagueId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions;
                const newItems = [...substate.items];

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        processingLeaguesIds: [...prevState.processing.processingLeaguesIds, leagueId]
                    }
                })))

                await ClubPermissionsService.disallowLeague(squadId, permissionCode, leagueId);
                if (permissionCode === 'AllAdsLeaguesAvailable') {
                    const newLeagues = newItems.find(item => item.id === squadId).allowedAdsCompetitions.filter(leagues => leagues.id !== leagueId);
                    newItems.find(item => item.id === squadId).allowedAdsCompetitions = newLeagues;
                } else {
                    const newLeagues = newItems.find(item => item.id === squadId).allowedCompetitions.filter(leagues => leagues.id !== leagueId);
                    newItems.find(item => item.id === squadId).allowedCompetitions = newLeagues;
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems,
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        processingLeaguesIds: prevState.processing.processingLeaguesIds.filter(item => item !== leagueId)
                    }
                })))
            }
        }
    };

    public static allowLeague(squadId: number, permissionCode: string, leagueId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.clubPermissions;
                const newItems = [...substate.items];
                const selectedLeague = substate.allLeagues.find(item => item.id === leagueId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        processingLeaguesIds: [...prevState.processing.processingLeaguesIds, leagueId]
                    }
                })))

                await ClubPermissionsService.allowLeague(squadId, permissionCode, leagueId);

                if (permissionCode === 'AllAdsLeaguesAvailable') {
                    newItems.find(item => item.id === squadId).allowedAdsCompetitions.push(selectedLeague)
                } else {
                    newItems.find(item => item.id === squadId).allowedCompetitions.push(selectedLeague)
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: newItems,
                })))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        processingLeaguesIds: prevState.processing.processingLeaguesIds.filter(item => item !== leagueId)
                    }
                })))
            }
        }
    };

    public static onPaginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                currentPage: pageNumber,
                recordsPerPage: pageSize
            })))

            dispatch(Actions.getSquadsPermissions());
        }
    }
}

class Selectors {
    public static selectState = (state: AppState) => state.admin.clubPermissions
    public static getItems = (state: AppState) => state.admin.clubPermissions.items;
    public static getCountries = (state: AppState) => state.admin.clubPermissions.countries;
    public static getCompetitions = (state: AppState) => state.admin.clubPermissions.leagues;
    public static getSubscriptionPlans = (state: AppState) => state.admin.clubPermissions.subscriptionPlans;
    public static getCompetitionLists = (state: AppState) => state.admin.clubPermissions.subscriptionPlans.competitionLists;
    public static getSubscriptions = (state: AppState) => state.admin.clubPermissions.subscriptions;
    public static getSupervisors = (state: AppState) => state.admin.clubPermissions.supervisors;
    public static isFiltersSetToDefault(state: AppState) {
        return JSON.stringify(defaultState.filterOptions) === JSON.stringify(state.admin.clubPermissions.filterOptions)
    }
    public static getTotalReports = (state: AppState) => Selectors.selectState(state).totalRecords;
    public static getRecordsPerPage = (state: AppState) => Selectors.selectState(state).recordsPerPage;
    public static getCurrentPage = (state: AppState) => Selectors.selectState(state).currentPage;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ClubPermissionState as State,
    Actions,
    Selectors,
    stateController as Controller,
};