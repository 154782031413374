import axiosLib from 'axios';
import { debounce } from 'lodash/function';
import { notificationError } from 'app/notifications/notifications.actions';
import config from './config';
import { store } from './';
import { appInsights } from 'appInsights';

const debounceMessage = debounce(
    msg => store.dispatch(notificationError(msg)),
    1500,
);

const handleServerCrashError = error => {
    if (axiosLib.isCancel(error)) {
        return false;
    } else if (error.response && error.response.status === 401) {
        localStorage.removeItem('state');
        window.location.replace('/login');
    } else {
        console.error(error.toString());
        debounceMessage();
        return Promise.reject(error);
    }
};

const instance = axiosLib.create({
    baseURL: config.baseUrl,
    withCredentials: true,
});

let registerInterceptors = () => {
    instance.interceptors.response.use(
        response => response,
        handleServerCrashError,
    );

    instance.interceptors.request.use(config => {
        config.headers['page-id'] = window.location.pathname;
        appInsights.trackEvent({ name: config.url });
        return config;
    });
};

registerInterceptors();

const CancelToken = axiosLib.CancelToken;

export const getCancelTokenSource = () => CancelToken.source();

export default instance;
