import { AgentClubStaffInvitationStatusEnum, AgentPlayerInvitationStatusEnum } from "api/agency/player/shared/shared-models"
import { SquadShortInfo } from "api/core/squad-short-info"
import {
    USER_PREFERENCE_FETCH,
    AGENCY_LANDING_MAKE_AS_VIEWED,
    AGENCY_FORCE_NOTIFICATION_LAYOUT_FALSE,
    AGENCY_FORCE_TRANSPARENCY_LAYOUT_TRUE,
    AGENCY_FORCE_TRANSPARENCY_LAYOUT_FALSE,
    AGENCY_FORCE_REONBOARDING_POPUP_FALSE,
    PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_FALSE,
    PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_TRUE,
    PLAYER_FORCE_PREFERENCES_SCREEN_LAYOUT_FALSE,
    PLAYER_SHOW_EXPLORE_PERFORMANCE_BANNER_FALSE,
    PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS,
    PLAYER_FORCE_PLAYER_REMOVED_SCREEN_LAYOUT_FALSE,
    PLAYER_UPDATE_IS_AGENT_FINDER_AVAILABLE,
    CLUB_FORCE_REONBOARDING_POPUP_FALSE,
    CLUB_FORCE_REONBOARDING_POPUP_TRUE,
    CLUB_FORCE_MANUAL_REONBOARDING_POPUP_FALSE,
    CLUB_FORCE_MANUAL_REONBOARDING_POPUP_TRUE,
    PERMISSIONS_PERSONAL_ACCOUNT_UPDATE,
    COACH_IS_AGENCY_CONFIRMATION_NEEDED_FALSE,
    USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE
} from "store/actionTypes"
import { AppState } from "root.reducer"

export interface UserPreference {
    financialInsightSeasons: SeasonTitle[];
    languageId: number;
    isShowAgencyNewIcon: boolean;
    isShowExclusiveBanner: boolean;
    playerUserPermissions: PlayerUserPermission;
    clubUserPermissions: ClubUserPermission;
    agencyUserPermissions: AgencyUserPermission;
    coachUserPermissions: CoachUserPermission;
    personalAccountUserPermissions: PersonalAccountUserPermission;
}

interface SeasonTitle {
    year: number
    title: string
}

export interface PersonalAccountInfo {
    userId: number | null;
    photo: string;
    firstName: string;
    lastName: string;
    isEmailConfirmed: boolean;
}

interface PlayerUserPermission {
    forcePlayerRepresentationScreen: boolean;
    showPlayerRepresentationScreen: boolean;
    showExplorePerformanceBanner: boolean;
    forcePlayerRemoveScreen: boolean;
    forcePlayerPreferencesScreen: boolean;
    isOpenToAgencyRequests: boolean;
    isAgentFinderAvailable: boolean;
    removedByAgencyName: string | null;
    invitationStatus: AgentPlayerInvitationStatusEnum | null;

    isMessagesAvailable: boolean;
    isPlayerReferralsAvailable: boolean;
    isPlayerSettingsAvailable: boolean;
}

interface AgencyUserPermission {
    forceAgencyReonboardingFlow: boolean
    forceAgencyTransparencyFlow: boolean
    forceSuggestedDealsFlow: boolean
}

interface ClubUserPermission {
    playerAdCounter: number
    friendlyMatchCount: number
    messagesCount: number
    allowedPlusPitchesCount: number
    allowedSuperPitchesCount: number
    plusPitchClubSelectionLimit: number
    forceClubReonboardingFlow: boolean
    forceManualClubReonboardingFlow: boolean
    multipleAccessSquads: SquadShortInfo[]
    squadInfo: SquadShortInfo
}

interface CoachUserPermission {
    isAgencyConfirmationNeeded: boolean
    invitationStatus: AgentClubStaffInvitationStatusEnum | null
}
interface PersonalAccountUserPermission {
    personalAccount: PersonalAccountInfo
}

export class UserPreferenceState {
    entity: UserPreference
}

const initialState: UserPreferenceState = {
    entity: null
};

export default (userPreference = initialState, action): UserPreferenceState => {
    switch (action.type) {
        case USER_PREFERENCE_FETCH.SUCCESS: {
            return {
                ...userPreference,
                entity: action.payload.data,
            };
        }
        case AGENCY_LANDING_MAKE_AS_VIEWED: {
            let clone = {...userPreference.entity};
            clone.isShowAgencyNewIcon = false;

            return {
                ...userPreference,
               entity: clone,
            };
        }
        case AGENCY_FORCE_NOTIFICATION_LAYOUT_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                agencyUserPermissions: {
                    ...userPreference.entity.agencyUserPermissions,
                    forceSuggestedDealsFlow: false
                }
            };
            return {
                ...userPreference,
               entity: newUserPreference,
            };
        }
        case AGENCY_FORCE_TRANSPARENCY_LAYOUT_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                agencyUserPermissions: {
                    ...userPreference.entity.agencyUserPermissions,
                    forceAgencyTransparencyFlow: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }
        case AGENCY_FORCE_TRANSPARENCY_LAYOUT_TRUE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                agencyUserPermissions: {
                    ...userPreference.entity.agencyUserPermissions,
                    forceAgencyTransparencyFlow: true
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }
        case AGENCY_FORCE_REONBOARDING_POPUP_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                agencyUserPermissions: {
                    ...userPreference.entity.agencyUserPermissions,
                    forceAgencyReonboardingFlow: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }
        case PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                playerUserPermissions: {
                    ...userPreference.entity.playerUserPermissions,
                    forcePlayerRepresentationScreen: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }
        case PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_TRUE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                playerUserPermissions: {
                    ...userPreference.entity.playerUserPermissions,
                    forcePlayerRepresentationScreen: true
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }
        case PLAYER_FORCE_PREFERENCES_SCREEN_LAYOUT_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                playerUserPermissions: {
                    ...userPreference.entity.playerUserPermissions,
                    forcePlayerPreferencesScreen: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case PLAYER_SHOW_EXPLORE_PERFORMANCE_BANNER_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                playerUserPermissions: {
                    ...userPreference.entity.playerUserPermissions,
                    showExplorePerformanceBanner: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case PLAYER_FORCE_PLAYER_REMOVED_SCREEN_LAYOUT_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                playerUserPermissions: {
                    ...userPreference.entity.playerUserPermissions,
                    forcePlayerRemoveScreen: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                playerUserPermissions: {
                    ...userPreference.entity.playerUserPermissions,
                    isOpenToAgencyRequests: action.payload.data
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case PLAYER_UPDATE_IS_AGENT_FINDER_AVAILABLE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                playerUserPermissions: {
                    ...userPreference.entity.playerUserPermissions,
                    isAgentFinderAvailable: action.payload.data
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }
        case CLUB_FORCE_REONBOARDING_POPUP_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                clubUserPermissions: {
                    ...userPreference.entity.clubUserPermissions,
                    forceClubReonboardingFlow: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case CLUB_FORCE_REONBOARDING_POPUP_TRUE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                clubUserPermissions: {
                    ...userPreference.entity.clubUserPermissions,
                    forceClubReonboardingFlow: true
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case CLUB_FORCE_MANUAL_REONBOARDING_POPUP_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                clubUserPermissions: {
                    ...userPreference.entity.clubUserPermissions,
                    forceManualClubReonboardingFlow: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case PERMISSIONS_PERSONAL_ACCOUNT_UPDATE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                personalAccountUserPermissions: {
                    ...userPreference.entity.personalAccountUserPermissions,
                    personalAccount: action.payload.data
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case CLUB_FORCE_MANUAL_REONBOARDING_POPUP_TRUE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                clubUserPermissions: {
                    ...userPreference.entity.clubUserPermissions,
                    forceManualClubReonboardingFlow: true
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case COACH_IS_AGENCY_CONFIRMATION_NEEDED_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                coachUserPermissions: {
                    ...userPreference.entity.coachUserPermissions,
                    isAgencyConfirmationNeeded: false
                }
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        case USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE: {
            const newUserPreference: UserPreference = {
                ...userPreference.entity,
                isShowExclusiveBanner: false
            };

            return {
                ...userPreference,
                entity: newUserPreference,
            };
        }

        default:
            return userPreference;
    }
};

export const getUserPreference = (state: AppState) => state.userPreference.entity;
export const getClubUserPermissions = (state: AppState) => getUserPreference(state)?.clubUserPermissions;
export const getAgencyUserPermissions = (state: AppState) => getUserPreference(state)?.agencyUserPermissions;
export const getPlayerUserPermissions = (state: AppState) => getUserPreference(state)?.playerUserPermissions;
export const getCoachUserPermissions = (state: AppState) => getUserPreference(state)?.coachUserPermissions;
export const getPersonalAccountUserPermissions = (state: AppState) => getUserPreference(state)?.personalAccountUserPermissions;