import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import appConfig from 'config';
import historyAccessor from 'history-accessor';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: appConfig.appInsights.connectionString,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { 
                // history: window.history 
                history: historyAccessor.history
            }
        }
    }
});
appInsights.loadAppInsights();

export { appInsights, reactPlugin };