import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import './tabs.scss'

class Props {
    isRegistered: boolean;
    isShowPracticalInfo: boolean;
    headerHeight: number;
}

class Tabs extends Component<Props, {}> {
    render() {
        return (
            <div className="authorized-event-tabs" style={{ top: this.props.headerHeight }}>

                <NavLink
                    className={`authorized-event-tabs__item`}
                    activeClassName="active"
                    exact
                    to="/events/attendance"
                >
                    <span style={{ verticalAlign: "middle" }}>Attendance</span>
                </NavLink>

                {this.props.isRegistered && (
                    <NavLink
                        className={`authorized-event-tabs__item`}
                        activeClassName="active"
                        to="/events/agenda-builder"
                    >
                        <span style={{ verticalAlign: "middle" }}>Agenda Builder</span> 
                    </NavLink>
                )}

                {this.props.isRegistered && this.props.isShowPracticalInfo && (
                    <NavLink
                        className={`authorized-event-tabs__item`}
                        activeClassName="active"
                        to="/events/info"
                    >
                        <span style={{ verticalAlign: "middle" }}>Practical Information</span>
                    </NavLink>
                )}

            </div>
        )
    }
}

export default Tabs