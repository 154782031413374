export type FilterOptions = {
    agencyId?: number;
    areaId?: number;
    subscriptionId?: number;
    subscription?: string;
    supervisorId?: number;
    supervisor?: string;
    withUsersOnly?: boolean;
    country?: string;
    keyword?: string;
}

export class Country {
    id: number;
    name: string;
    code?: string;
}

export class AgencyPermissionResponse {
    agencyId: number;
    plusPitchesCount: number;
    agencyCustomPitchesCount: number;
}

export class EditCostValueRequest {
    agencyId: number;
    permissionCode: string;
    value: number;
}

export class AdminAgencyEditCreditsRequest {
    agencyId: number;
    isPriority: boolean;
    value: number;
}

export class SalesSupervisor {
    public supervisorId: number;
    public userId: number;
    public fullName: string;
    public email: string;
    public photo: string;
    public phone: string;
    public position: string;
}

export class Permissions {
    additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum;
    representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum;
    id: number;
    agencyId?: number;
    name: string;
    salesSupervisorId: number;
    successExpertUserName: string;
    plusPitchesCount: number;
    agencyCustomPitchesCount: number;
    permissionCodes?: string[];
    checked?: boolean;
    transparencyChecked: boolean;
    playerMarketChecked: boolean;
    playerMarkets: PlayerMarketModel[];
    selectedMarketNames: string;
    marketExpertNames: string;
    isLta: boolean;
    subscriptionPlanId: number;
    subscriptionPlanName: string;
    priorityCredits: number;
    regularCredits: number;
    usersNumber: number;
    coachAccessId: number;
    inPersonId: number;
    onlineId: number;
    slotsInUse: number;
    regularPlayersSlotsInUse: number;
    maxUserAmount: number;
    maxUserAmountText: string;
}

export class PlayerMarketModel {
    id: number;
    areaId: number;
    areaName: string;
    isExpert: boolean;
}

export class PlayerMarketRequestModel {
    id: number;
    areaId: number;
    isExpert: boolean;
}

export class PlayerMarketRequest {
    agencyId: number;
    markets: PlayerMarketRequestModel[];
}

export enum AdditionalPlayerMarketSubscriptionEnum {
    None = 0,
    Full = 1,
    LimitedTo150k = 2,
    LimitedTo300k = 3,
    LimitedTo500k = 4
}

export enum RepresentationRequestSettingOptionEnum {
    MaskAll = 1,
    Over300k = 2,
    ShowAll = 3,
}

export enum CoachAccessOptionEnum {
    None = 0,
    Pitch = 1,
    Full = 2,
    Database = 3,
}

export enum EventAccessOptionEnum {
    None = 0,
    Purchase = 1,
    OneEvent = 2,
    TwoEvent = 3,
    OneOffOffer = 4,
    OneEventTwoAttendees = 5,
}

export interface PlayerMarketsSubscriptionModel {
    id: AdditionalPlayerMarketSubscriptionEnum;
    name: string;
}

export interface AgenciesSubscriptionModel {
    id: AgenciesSubscriptionEnum;
    name: string;
    priorityCreditAmount: number;
    regularCreditAmount: number;
    maxUserAmount: number;
}

export interface AgenciesSubscriptionExtendedModel extends AgenciesSubscriptionModel {
    coachAccessId?: CoachAccessOptionEnum;
    plusPitchesCount?: number;
    customPitch?: boolean;
    agencyCustomPitchesCount?: number;
    transparencyChecked?: boolean;
    representationRequestSettingOptionId?: RepresentationRequestSettingOptionEnum;
    additionalPlayerMarketSubscriptionId?: AdditionalPlayerMarketSubscriptionEnum;
    playerMarkets?: PlayerMarketModel[];
    inPersonId?: EventAccessOptionEnum;
    onlineId?: EventAccessOptionEnum;
}

export enum AgenciesSubscriptionEnum {
    None = 0,
    Custom = 1,
    Starter = 2,
    Basic = 3,
    BasicPlus = 4,
    Premium = 5,
    PremiumPlus = 6,
    Vip = 7,
    VipPlus = 8,
    Temporary = 9,
}

export interface CoachAccessOptionsModel {
    id: number;
    name: string;
}

export interface EventAccessOptionsModel {
    id: number;
    name: string;
}

export interface RepresentationRequestSettingOption {
    id: RepresentationRequestSettingOptionEnum;
    name: string;
}
