import axios from 'axios-config';
import {
    AllUsersCriteria,
    AllUsersResponse,
    FilterOptions,
    UserProfileAdminResponse,
    SuggestionItemModel,
    SetStaffProductPermissionRequest,
} from './models';
import { Profile } from 'api/user-profile/models/profile';

export class AdminAllUsersService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAllUser(dataOptions: FilterOptions): Promise<AllUsersResponse> {
        const { data } = await axios.post(`api/Users/GetAllUsers`, dataOptions, this.axiosConfig);
        return data;
    }

    public static async getSupervisors(): Promise<AllUsersResponse> {
        const { data } = await axios.get(
            `api/AdminSquads/GetSalesSupervisorUsers`,
            this.axiosConfig
        );
        return data;
    }

    public static async setIsMasterUser(userId: number, isMaster: boolean): Promise<boolean> {
        const { data } = await axios.post(
            `api/Users/SetMasterUser/${userId}/${isMaster}`,
            { userId: userId, isMaster: isMaster },
            this.axiosConfig
        );
        return data;
    }

    public static async setIsEvent(userId: number, isEvent: boolean): Promise<boolean> {
        const { data } = await axios.post(
            `api/Users/SetEventUser/${userId}/${isEvent}`,
            { userId: userId, isEvent: isEvent },
            this.axiosConfig
        );
        return data;
    }

    public static async setIsDM(userId: number, isDM: boolean): Promise<boolean> {
        const { data } = await axios.post(
            `api/Users/SetIsDMUser/${userId}/${isDM}`,
            { userId: userId, isDM: isDM },
            this.axiosConfig
        );
        return data;
    }

    public static async setIsFriendlyRelatedUser(
        userId: number,
        isFriendlyRelated: boolean
    ): Promise<boolean> {
        const { data } = await axios.post(
            isFriendlyRelated
                ? `api/Users/EnableIsFriendlyRelatedUser/${userId}`
                : `api/Users/DisableIsFriendlyRelatedUser/${userId}`,
            {},
            this.axiosConfig
        );
        return data;
    }

    public static async setIsActive(userId: number, isActive: boolean): Promise<boolean> {
        const { data } = await axios.post(
            isActive ? `api/Users/ActivateUser/${userId}` : `api/Users/DeactivateUser/${userId}`,
            {},
            this.axiosConfig
        );
        return data;
    }
    public static async getResetPasswordLink(userId: number): Promise<string> {
        const { data } = await axios.get(
            `api/Users/GetResetPasswordLinkByUserId/${userId}`,
            this.axiosConfig
        );
        return data;
    }
    public static async unlockUser(
        userId: number,
        shouldSendResetPasswordLink: boolean
    ): Promise<boolean> {
        const { data } = await axios.post(
            `api/Users/UnlockUser/${userId}/${shouldSendResetPasswordLink}`,
            {},
            this.axiosConfig
        );
        return data;
    }
    public static async deleteUser(userId: number): Promise<boolean> {
        const { data } = await axios.delete(`api/Users/${userId}`, this.axiosConfig);
        return data;
    }

    public static async resetAgentInvitation(agentId: number): Promise<boolean> {
        const { data } = await axios.post(
            `api/v2/AgencyAgentInvitation/ResetInvitation/${agentId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getCriteria(): Promise<AllUsersCriteria> {
        const { data } = await axios.get('api/Users/GetAllUsersCriteria', this.axiosConfig);
        return data;
    }

    public static async loadUserProfile(
        userId: number,
        clubId: number
    ): Promise<UserProfileAdminResponse> {
        const { data } = await axios.get(
            `api/Users/GetUserProfileAdmin/${userId}/${clubId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async loadAgencyUserProfile(agentId: number): Promise<Profile> {
        const { data } = await axios.get(
            `api/Users/GetAgentUserProfileAdmin/${agentId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getMultiAccessAutosuggestionSquads(
        squadId: number,
        clubId: number,
        filterCriteria: any,
        clubOnly: boolean = false,
        clubWithoutPlayers: boolean = false,
        clubWithoutCompetitions: boolean = false
    ): Promise<Array<SuggestionItemModel>> {
        const { data } = await axios.post(
            `api/Suggestion/GetPlayerSearchFilterSuggestion/${squadId}/${clubId}?clubOnly=${clubOnly}&clubWithoutPlayers=${clubWithoutPlayers}&clubWithoutCompetitions=${clubWithoutCompetitions}`,
            { ...filterCriteria },
            { ...this.axiosConfig }
        );
        return data;
    }

    public static async setStaffProductPermission(
        userId: number,
        isEnabled: boolean
    ): Promise<boolean> {
        const payload: SetStaffProductPermissionRequest = {
            userId,
            isEnabled,
        };
        const { data } = await axios.post(
            'api/Users/SetStaffProductPermission',
            payload,
            this.axiosConfig
        );
        return data;
    }
}
