import { CancelTokenSource } from 'axios';
import { AppState } from 'root.reducer';
import { Actions as GridActions } from 'pages/PlayerSearch-v3/squad/redux/grid.controller';
import {
    Selectors as FilterCommonSelectors,
    Controller as FilterCommonController,
} from 'pages/PlayerSearch-v3/squad/redux/filter.controller';
import { CustomLeagueList } from 'api/search-v3/model/suggestion-list';
import {
    CoachingLicenseTypeEnum,
    HeadCoachExperienceEnum,
} from 'api/search-v3/model/search-request';
import { IdName } from 'api/core/id-name';
import { AvailableNationalityCriteria } from 'api/search-v3/model/search-filter-criteria';
import { StaffRolesSelectItem } from 'api/staff/staff-account/model';
import { LanguagesSelectItem } from 'api/staff/staff-profile/model';
import { NationalitySelectItem } from 'api/core/area-short-info';
import { StaffAttributesFilterData } from 'pages/PlayerSearch-v3/staff-product/redux/filter.controller';

export const getStaffAttributesStructure = (): StaffAttributesFilterData => {
    return {
        isOverTwoYears: false,
        isOverFiveYears: false,
        isOverEightYears: false,
        isOverTenYears: false,
        hasPlayingCareer: false,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        nationalityList: [],
        licenseTypeList: [],
        staffExperienceList: [],
        leagueExperienceList: [],
        currentStaffType: null,
        languages: null,
        nationalities: null,
    };
};

export const getStaffAttributesFilterCriteria = (): StaffAttributesFilterData => {
    return {
        isOverTwoYears: false,
        isOverFiveYears: false,
        isOverEightYears: false,
        isOverTenYears: false,
        hasPlayingCareer: false,
        isCurrentLeague: false,
        isCurrentAllLeagues: false,
        leaguesList: [],
        nationalityList: [],
        licenseTypeList: [],
        staffExperienceList: [],
        leagueExperienceList: [],
        currentStaffType: null,
        languages: null,
        nationalities: null,
    };
};

export interface StaffAttributesFilter {
    filterData: StaffAttributesFilterData;
    licenseTypeListAll: IdName[];
    staffExperienceListAll: IdName[];
    availableNationalities: AvailableNationalityCriteria[];
}

class Actions {
    public static token: CancelTokenSource = null;

    public static setShowOnlyFreeAgentStaff(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterCommonController.setState({ showOnlyFreeAgentStaff: value }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        };
    }

    public static toggleStaffAttributesFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            const filter = Selectors.getStaffAttributesFilter(getState());
            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: isOpen ? { ...filter } : null,
                }))
            );
        };
    }

    public static clearStaffAttributesFilter() {
        return (dispatch) => {
            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilter: {
                        ...prevState.staffAttributesFilter,
                        filterData: {
                            ...prevState.staffAttributesFilter.filterData,
                            ...prevState.staffAttributesStructure,
                        },
                    },
                }))
            );
        };
    }

    public static clearStaffAttributesFilterAsync() {
        return (dispatch) => {
            dispatch(Actions.clearStaffAttributesFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        };
    }

    public static clearStaffFreeAgentFilter() {
        return (dispatch) => {
            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    showOnlyFreeAgentStaff: false,
                }))
            );
        };
    }

    public static resetStaffFilter() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearStaffFreeAgentFilter());
            dispatch(Actions.clearStaffAttributesFilter());
        };
    }

    public static resetStaffFilterAsync() {
        return (dispatch) => {
            dispatch(Actions.resetStaffFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
            // dispatch(Actions.clearAllUserActivityData());
        };
    }

    public static setStaffAttributesValue(e: any) {
        return (dispatch, getState: () => AppState) => {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            [e.target.name]: value,
                        },
                    },
                }))
            );
        };
    }

    public static setStaffAttributesSelectValue(selectName: string, selected: any) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            [selectName]: selected,
                        },
                    },
                }))
            );
        };
    }

    public static clearStaffAttributesFilterRowParams(params: string[]) {
        return (dispatch, getState: () => AppState) => {
            const structure = FilterCommonSelectors.getStructure(getState());
            // const filterDataState = Selectors.getPlayerAttributesModal(getState()).filterData;
            const clearParams = [...params];

            clearParams.forEach((param) => {
                dispatch(
                    FilterCommonController.setState((prevState) => ({
                        ...prevState,
                        staffAttributesFilterModal: {
                            ...prevState.staffAttributesFilterModal,
                            filterData: {
                                ...prevState.staffAttributesFilterModal.filterData,
                                [`${param}`]: structure[`${param}`],
                            },
                        },
                    }))
                );
            });
        };
    }

    public static setStaffExperience(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getStaffAttributesModal(getState()).filterData;
            const staffExperienceList = filterDataState.staffExperienceList;
            const { OverTwoYears, OverFiveYears, OverEightYears, OverTenYears, HasPlayingCareer } =
                HeadCoachExperienceEnum;

            let staffExperienceListUpdated;
            if (staffExperienceList.includes(id)) {
                staffExperienceListUpdated = staffExperienceList.filter((x) => x != id);
            } else {
                if (id === OverTwoYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverFiveYears && x != OverEightYears && x != OverTenYears
                        ),
                        id,
                    ];
                }
                if (id === OverFiveYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverTwoYears && x != OverEightYears && x != OverTenYears
                        ),
                        id,
                    ];
                }
                if (id === OverEightYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverTwoYears && x != OverFiveYears && x != OverTenYears
                        ),
                        id,
                    ];
                }
                if (id === OverTenYears) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter(
                            (x) => x != OverTwoYears && x != OverFiveYears && x != OverEightYears
                        ),
                        id,
                    ];
                }
                if (id === HasPlayingCareer) {
                    staffExperienceListUpdated = [
                        ...staffExperienceList.filter((x) => x != HasPlayingCareer),
                        id,
                    ];
                }
            }

            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            staffExperienceList: [...staffExperienceListUpdated],
                        },
                    },
                }))
            );
        };
    }

    public static updateLeagueExperienceList(leagueId: number, action: string) {
        return (dispatch, getState: () => AppState) => {
            const leagueList = Selectors.getStaffAttributesModal(getState()).filterData.leaguesList;

            let updatedList = [];
            if (action === 'remove') {
                updatedList = leagueList.filter((id) => id !== leagueId);
            }
            if (action === 'add') {
                updatedList = [...leagueList, leagueId];
            }

            dispatch(
                FilterCommonController.setState((prevState) => {
                    return {
                        ...prevState,
                        staffAttributesFilterModal: {
                            ...prevState.staffAttributesFilterModal,
                            filterData: {
                                ...prevState.staffAttributesFilterModal.filterData,
                                leaguesList: updatedList,
                            },
                        },
                    };
                })
            );
        };
    }

    public static checkLeagueExperienceList(leagueId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                FilterCommonController.setState((prevState) => {
                    return {
                        ...prevState,
                        staffAttributesFilterModal: {
                            ...prevState.staffAttributesFilterModal,
                            filterData: {
                                ...prevState.staffAttributesFilterModal.filterData,
                                leagueExperienceList: [
                                    ...prevState.staffAttributesFilterModal.filterData.leagueExperienceList.map(
                                        (item) => {
                                            if (item.id === leagueId) {
                                                return {
                                                    ...item,
                                                    checked: !item.checked,
                                                };
                                            }
                                            return item;
                                        }
                                    ),
                                ],
                            },
                        },
                    };
                })
            );
        };
    }

    public static setStaffLicence(id: number) {
        return (dispatch, getState: () => AppState) => {
            const filterDataState = Selectors.getStaffAttributesModal(getState()).filterData;
            const licenseTypeList = filterDataState.licenseTypeList;

            let licenseTypeListUpdated;
            if (licenseTypeList.includes(id)) {
                licenseTypeListUpdated = licenseTypeList.filter((x) => x != id);
            } else {
                licenseTypeListUpdated = [...licenseTypeList, id];
            }

            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilterModal: {
                        ...prevState.staffAttributesFilterModal,
                        filterData: {
                            ...prevState.staffAttributesFilterModal.filterData,
                            licenseTypeList: [...licenseTypeListUpdated],
                        },
                    },
                }))
            );
        };
    }

    public static applyStaffAttributesFilter() {
        return (dispatch, getState: () => AppState) => {
            // TODO: uncomment when User Activity for staff will be provided
            // dispatch(Actions.getCoachLeaguesUA());
            const filterData = Selectors.getStaffAttributesModal(getState()).filterData;
            const currentUserActivityData =
                FilterCommonSelectors.getRoot(getState()).userActivityData;

            dispatch(
                FilterCommonController.setState((prevState) => ({
                    ...prevState,
                    staffAttributesFilter: {
                        ...prevState.staffAttributesFilter,
                        filterData: {
                            ...prevState.staffAttributesFilter.filterData,
                            ...filterData,
                        },
                    },
                }))
            );

            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());

            dispatch(Actions.toggleStaffAttributesFilterModal(false));

            const staffExperienceItems = currentUserActivityData.filter(
                (item) => item.shouldToJoin
            );

            if (staffExperienceItems.length > 1) {
                // dispatch(Actions.headCoachExperienceUA(currentUserActivityData));
            } else {
                currentUserActivityData.map((item) => {
                    // dispatch(Actions.sendUserActivity(item.message, item.pageType));
                });
            }
        };
    }
}

class Selectors {
    public static getStaffTypesList = (state: AppState) =>
        FilterCommonSelectors.getRoot(state).staffTypesListAll;
    public static getStaffAttributesFilter = (state: AppState) =>
        FilterCommonSelectors.getRoot(state).staffAttributesFilter;

    public static getStaffAttributesModal = (state: AppState) =>
        FilterCommonSelectors.getRoot(state).staffAttributesFilterModal;

    public static getStaffAttributesFilterStructure = (state: AppState) =>
        FilterCommonSelectors.getRoot(state).staffAttributesStructure;

    public static isActiveStaffAttributesFilter = (state: AppState) => {
        return (
            JSON.stringify(Selectors.getStaffAttributesFilter(state).filterData) !==
            JSON.stringify(Selectors.getStaffAttributesFilterStructure(state))
        );
    };

    public static isChangedStaffAttributesFilter = (state: AppState) => {
        if (!Selectors.getStaffAttributesModal(state)) {
            return false;
        }
        return (
            JSON.stringify(Selectors.getStaffAttributesFilter(state).filterData) !==
            JSON.stringify(Selectors.getStaffAttributesModal(state).filterData)
        );
    };

    public static isAppliedStaffTypeFilter = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.currentStaffType !== null;
    };

    public static isAppliedStaffLeagueExperienceFilters = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        const structure = FilterCommonSelectors.getStructure(state);
        if (!filterData) {
            return false;
        }
        const isCustomLeaguesSelected = filterData.leaguesList.length > 0;
        return (
            filterData.isCurrentLeague !== structure.isCurrentLeague ||
            filterData.isCurrentAllLeagues !== structure.isCurrentAllLeagues ||
            isCustomLeaguesSelected
        );
    };

    public static isAppliedLeagueExperience = (state: AppState) => {
        const filterData =
            Selectors.getStaffAttributesFilter(state)?.filterData.leagueExperienceList;
        if (!filterData) {
            return false;
        }
        return filterData.some((item) => item.checked);
    };

    public static isAppliedStaffLicenseType = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.licenseTypeList.length > 0;
    };

    public static isAppliedStaffExperienceFilters = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;

        if (!filterData) {
            return false;
        }
        return filterData.staffExperienceList.length != 0;
    };

    public static isAppliedStaffNationalitiesFilter = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.nationalities?.length > 0;
    };

    public static isAppliedStaffLanguagesFilter = (state: AppState) => {
        const filterData = Selectors.getStaffAttributesFilter(state)?.filterData;
        if (!filterData) {
            return false;
        }

        return filterData.languages?.length > 0;
    };

    public static getStaffAttributesSelectedFormRowCount = (state: AppState) => {
        const isAppliedStaffTypeFilter = Selectors.isAppliedStaffTypeFilter(state);
        const isAppliedStaffLeagueExperienceFilters =
            Selectors.isAppliedStaffLeagueExperienceFilters(state);
        const isAppliedStaffExperienceFilters = Selectors.isAppliedStaffExperienceFilters(state);
        const isAppliedStaffLicenseType = Selectors.isAppliedStaffLicenseType(state);
        const isAppliedLeagueExperience = Selectors.isAppliedLeagueExperience(state);
        const isAppliedStaffNationalitiesFilter =
            Selectors.isAppliedStaffNationalitiesFilter(state);
        const isAppliedStaffLanguagesFilter = Selectors.isAppliedStaffLanguagesFilter(state);

        return [
            isAppliedStaffTypeFilter,
            isAppliedStaffExperienceFilters,
            isAppliedStaffLeagueExperienceFilters,
            isAppliedStaffLicenseType,
            isAppliedLeagueExperience,
            isAppliedStaffNationalitiesFilter,
            isAppliedStaffLanguagesFilter,
        ].filter(Boolean).length;
    };
}

export { Actions, Selectors };
