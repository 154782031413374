export enum AgreeDocumentsUserTypeEnum {
    Club = 1,
    Agency = 2,
    Player = 3,
    HeadCoach = 4,
    PersonalAccount = 5,
}

export interface CurrencyModel {
    id: number;
    name: string;
    sign: string;
}

export enum UserRegistrationSource {
    RegistrationForm = 1,
    Event = 2,
    DemoRequest = 3,
    AdminPage = 4,
    AgentTab = 5,
    UserProfile = 6,
    CustomPitch = 7,
    AgencyPlayerVerificationSms = 8,
    AdminPlayerRegistration = 9,
    AdminAgencyOnboarding = 10,
    AgentOnboarding = 11,
    Script = 12,
    AgencyRegistration = 13,
}

export interface NationalitiesRequest {
    id: string;
    name: string;
    shortName: string;
}